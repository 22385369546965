import './style.scss'
import { useEffect, useState, useRef } from 'react';
import MainContainer from '../MainContainer';
import Spinner from '../Spinner';
import Session from '../Session';
import Button from '../Button';
import { DbHelper } from '../../../global/dbHelper';


function Sessions({
    sessionData
}) {
    const [isLoggedin, setLoggedin] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [displayData, setDisplayData] = useState();
    const [lastUpdated, setLastUpdated] = useState('');
    const [errStats, setErrStats] = useState({});
    const [additionalSessions, setAdditionalSessions] = useState([]);
    const [user, setUser] = useState('');
    const separatlyLoadedData = {};


    // provider.setCustomParameters({
    //     'login_hint': 'user1@goexample.com'
    // });

    function getErrorsSortedByCount(errors) {
        let errorsList = Object.keys(errors);
        errorsList.sort((a, b) => errors[b] - errors[a]);
        //console.log("errorsList ", errorsList);

        return errorsList;
    }

    //merge new and existing error stats
    function mergeErrSats(errorSats) {
        let newErrStats = {};

        Object.keys(errorSats).forEach(function (key) {
            let count = errorSats[key];
            errStats[key] = (errStats[key] ? errStats[key] : 0) + count;
        })

        //console.log("newErrStats ", errorSats);
        //setErrStats(errStats);

        return errStats;
    }

    function mergeSessions(newSessions) {
        //let mergedSessions = sessions;
        let mergedSessions = [];

        var keys = Object.keys(newSessions);

        for (let i = keys.length - 1; i >= 0; i--) {
            let id = keys[i];
            let session = newSessions[id];

            mergedSessions.push(session);
        }

        //console.log("mergedSessions ", mergedSessions);
        //setSessions(mergedSessions);

        return mergedSessions;
    }

    function getSessionRows(sessions) {

        console.log("updateResult ", sessions);

        if (sessions && sessions.data) {
            let items = [];

            var keys = Object.keys(sessions.data);
            //console.log(`keys:`, keys);

            //let errList = getErrorsSortedByCount(mergeErrSats(sessions.stats.errorStatsByType));
            let errList = getErrorsSortedByCount(sessions.stats.errorStatsByType);

            let currentSessions = mergeSessions(sessions.data);
            //let currentSessions = sessions.data;

            let lastUpdated = '';

            Object.keys(currentSessions).forEach(function (key) {
                let session = currentSessions[key];

                lastUpdated = session.lastUpdated;

                items.push(<Session
                    key={key}
                    session={session}
                    errList={errList}
                />
                )
            })

            //console.log("lastUpdated ", lastUpdated);

            //setLastUpdated(lastUpdated);

            return items;
        }
        else {
            return "no sessions found"
        }
    }

    function updateError(err) {
        setLoading(false);
        setError(true);
        console.log("updateError ", err);
    }

    // function loadData() {
    //     DbHelper.fetchSessionsData(updateResult, updateError);
    // }

    function getMoreSessions() {
        DbHelper.fetchSessionsBefore(lastUpdated, getSessionRows);

    }


    return (
        <div className='sessions' >
            <div className='sessions-body'>
                <div className='sessions-body-title'>
                    Session Data
                </div>
                <div className='sessions-body-data'>
                    {getSessionRows(sessionData)}
                </div>
                <div>
                    <Button
                        title='Show more'
                        onClick={() => {
                            getMoreSessions()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Sessions;
