import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Basic/Button';
import Logo from '../Basic/Logo';
import appleIcon from '../../assets/icons/apple-pay.svg';
import wrapperIcon from '../../assets/icons/wrapper.svg';
import buttonHider from '../../assets/icons/button-hider.svg';
import closeImg from '../../assets/icons/close.svg'
import './style.scss';
import {
    doc_type
} from '../../global/config.js'
import { DocTypes } from "../../global/docTypes.js"
import { setDocType } from '../../redux/actions/docTypeAction';


function Header() {
    const navigate = useNavigate();
    const [isMenuShow, setMenuShow] = useState(false);
    const [isDocTypeVisible, setDocTypeVisibility] = useState(true);
    const [route, setRoute] = useState('');
    const dispatch = useDispatch();
    const defValue = (window.location.pathname == '/greencard' ? DocTypes.usGreenCard.type : "Select");

    useEffect(() => {
        setDocTypeVisibility(window.location.pathname == '/home' || window.location.pathname =='/greencard');
    });



    function createListItems()
    {
        //check url and select default value
        if (window.location.pathname == '/greencard')
        {
            updateDocument(DocTypes.usGreenCard.type);
        }

        let items = [];
        let list = DocTypes.getDocList();

        for(var doc of list)
        {
            items.push(<option key={doc.type} value={doc.type}>{doc.textDescription}</option>);
        }

        return items;
    }

    function updateDocument(docType)
    {
        localStorage.setItem(doc_type, docType);
        let doc = DocTypes.findByType(docType);
        dispatch(setDocType(doc));
    }

    return (
        <div className='header'>
            <div className='header-left'>
                <Logo
                    onClick={() => {
                        navigate("/")
                    }}
                />
            </div>
            <div className='header-mid'>
                <div className={`header-mid-btn-group ${!isDocTypeVisible && 'disabled'}`}>
                    <label></label>
                    <select 
                        onChange={ (e) => { updateDocument(e.target.value); }} 
                        name="Type" 
                        defaultValue={defValue || 'Select'}
                        >
                        {createListItems()}
                    </select>

                    {/* <a
                        className={`${route === '/popdocs' && 'selected'}`}
                        onClick={() => {
                            navigate("/popdocs")
                        }}
                    >Popular documents</a>
                    <a
                        className={`${route === '/reference' && 'selected'}`}
                        onClick={() => {
                            navigate("/reference")
                        }}>
                        Reference
                    </a>
                    <a
                        className={`${route === '/blog' && 'selected'}`}
                        onClick={() => {
                            navigate("/blog")
                        }}>
                        Blog
                    </a> */}
                </div>
            </div>
            <div className='header-right'>
                <img
                    src={buttonHider}
                    className='header-right-btn-hider'
                    onClick={() => {
                        setMenuShow(!isMenuShow);
                    }}
                />
                {/* <img src={wrapperIcon} className='header-right-wrapper' /> */}
                {/* <div className='header-right-btn-group'> */}
                    {/* <Button
                        title='Upload Photo'
                        backgroundColor='minor'
                        onClick={() => {
                            navigate("/step")
                        }}
                    /> */}
                   {/*  <Button
                        icon={appleIcon}
                        title='Download'
                        backgroundColor={"header_white"}
                    />
                </div> */}
            </div>
            {
                isMenuShow && <>
                    <div
                        className='header-disable-background'
                        onClick={() => {
                            setMenuShow(!isMenuShow);
                        }}
                    >
                    </div>
                </>
            }
            <div className={`mobile-menu ${isMenuShow && 'active'}`}>
                <img
                    className="close-btn"
                    src={closeImg}
                    onClick={() => {
                        setMenuShow(!isMenuShow);
                    }}
                    alt="Close"
                />
                <div className='mobile-menu-content'>
                    {/* <div className={`mobile-menu-content-item ${route === '/' && 'selected'}`}
                        onClick={() => {
                            navigate("/")
                            setMenuShow(!isMenuShow)
                        }}>
                        Home
                    </div> */}
                    <div className={`mobile-menu-content-item ${route === '/popdocs' && 'selected'}`}
                        onClick={() => {
                            navigate("/popdocs")
                            setMenuShow(!isMenuShow)
                        }}>
                        Popular documents
                    </div>
                    <div
                        className={`mobile-menu-content-item ${route === '/reference' && 'selected'}`}
                        onClick={() => {
                            navigate("/reference")
                            setMenuShow(!isMenuShow)
                        }}>
                        Reference
                    </div>
                    <div className={`mobile-menu-content-item ${route === '/blog' && 'selected'}`}
                        onClick={() => {
                            navigate("/blog")
                            setMenuShow(!isMenuShow)
                        }}>
                        Blog
                    </div>
                    {/* <div className='mobile-menu-content-item'>
                        language
                    </div> */}
                </div>
                <div className='mobile-menu-control'>
                    {/* <Button
                        classNames={'button-gray-white'}
                        title='Upload Photo'
                        onClick={() => {
                            navigate("/step")
                            setMenuShow(!isMenuShow)
                        }}
                    /> */}
                    <img src={wrapperIcon} className='mobile-menu-control-wrapper' />
                    <Button
                        icon={appleIcon}
                        title='Download'
                        backgroundColor={"header_white"}
                    />
                </div>
            </div>
        </div>
    )
}

export default Header;