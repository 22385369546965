import {
    SET_STEP_DELETEFLAG,
    SET_STEP_PROCESSEDPHOTO,
    SET_STEP_PROCESSEDDATA,
    EDIT_STEP_PHOTO,
    SET_STEP_COUNTRY
} from '../actions/stepAction';

const INITIAL_STATE = {
    deleteFlag: false,
    processedPhoto: null,
    processedData: null,
    country: ""
};

export default function step(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_STEP_DELETEFLAG:
            return {
                ...state,
                deleteFlag: action.payload.value
            }
        case SET_STEP_PROCESSEDPHOTO:
            return {
                ...state,
                processedPhoto: action.payload.value
            }
        case SET_STEP_PROCESSEDDATA:
            return {
                ...state,
                processedData: action.payload.value
            }
        case EDIT_STEP_PHOTO:
            return {
                ...state,
                processedPhoto: action.payload.value
            }
        case SET_STEP_COUNTRY:
            return {
                ...state,
                country: action.payload.value
            }
        default:
            return state;
    }
}