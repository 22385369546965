import "./style.scss"
import MainContainer from '../../components/Basic/MainContainer';
import imgMainCvs from '../../assets/homepage/instructions/main_cvs.webp'
import imgUpload from '../../assets/homepage/instructions/upload.webp'
import imgDelivery from '../../assets/homepage/instructions/delivery.webp'
import imgPickupLocation from '../../assets/homepage/instructions/pickup_location.webp'
import imgSize from '../../assets/homepage/instructions/size.webp'
import imgOrderInfo from '../../assets/homepage/instructions/order_info.webp'
import { useEffect, useState, useRef } from 'react';


const Instructions = () => {
    const [biggerImg, setbiggerImg] = useState(imgSize);

    return (
        <MainContainer>
            <div className="instructions">
                <div className="instructions-title">
                    How to print the photo
                </div>
                <div className="instructions-step-text-subTitle">
                    This photo hack also works in store at any CVS or Wallgreens location!!!
                </div>
                <div className="instructions-step-text-body">
                    <ul>
                        <li>We send digital photo in attached files of the confirmation email</li>
                        <li>Or it can be downloaded from payment confirmation page</li>
                        <li>Save file "print 4x6 at cvs or walgreens.jpeg" to a USB drive</li>
                        <li>Upload the file from your USB drive</li>
                        <li>Follow steps below and get printing!</li>
                    </ul>
                </div>
                <div className="instructions-step">
                    <div className="instructions-step-text">
                        <div className="instructions-step-text-subTitle">
                            Step 1.
                        </div>
                        <div className="instructions-step-text-body">
                            Navigate to this website <a href="https://www.photoprintsnow.com" target="blank">photoprintsnow.com</a>
                        </div>
                        <div className="instructions-step-text-body">
                            Select “Get Started”
                        </div>
                    </div>
                    <div className="instructions-step-img" >
                        <img src={imgMainCvs} alt='Main CVS page' loading="lazy" onClick={() => { setbiggerImg(imgMainCvs) }} />
                    </div>
                </div>
                <div className="instructions-step">
                    <div className="instructions-step-text">
                        <div className="instructions-step-text-subTitle">
                            Step 2.
                        </div>
                        <div className="instructions-step-text-body">
                            Drag and drop or select import a file to the Upload Your Photos section
                        </div>
                    </div>
                    <div className="instructions-step-img">
                        <img src={imgUpload} alt='Upload your file' loading="lazy" onClick={() => { setbiggerImg(imgUpload) }} />
                    </div>
                </div>
                <div className="instructions-step">
                    <div className="instructions-step-text">
                        <div className="instructions-step-text-subTitle">
                            Step 3.
                        </div>
                        <div className="instructions-step-text-body">
                            Verify you have uploaded the correct downloaded photo from quickpassportphotos.com, it should be "print&nbsp;4x6&nbsp;at&nbsp;cvs&nbsp;or&nbsp;walgreens.jpeg"
                        </div>
                    </div>
                    <div className="instructions-step-img">
                        <img src={imgDelivery} alt='Delivery options' loading="lazy" onClick={() => { setbiggerImg(imgDelivery) }} />
                    </div>
                </div>

                <div className="instructions-step">
                    <div className="instructions-step-text">
                        <div className="instructions-step-text-subTitle">
                            Step 4.
                        </div>
                        <div className="instructions-step-text-body">
                            Select location
                        </div>
                    </div>
                    <div className="instructions-step-img">
                        <img src={imgPickupLocation} alt='Pickup location' loading="lazy" onClick={() => { setbiggerImg(imgPickupLocation) }} />
                    </div>
                </div>

                <div className="instructions-step">
                    <div className="instructions-step-text">
                        <div className="instructions-step-text-subTitle">
                            Step 5.
                        </div>
                        <div className="instructions-step-text-body">
                            Select 4x6 and select next
                        </div>
                    </div>
                    <div className="instructions-step-img">
                        <img src={imgSize} alt='4x6 size' loading="lazy" onClick={() => { setbiggerImg(imgSize) }} />
                    </div>
                </div>

                <div className="instructions-step">
                    <div className="instructions-step-text">
                        <div className="instructions-step-text-subTitle">
                            Step 6.
                        </div>
                        <div className="instructions-step-text-body">
                            Input order information
                        </div>
                    </div>
                    <div className="instructions-step-img">
                        <img src={imgOrderInfo} alt='4x6 size' loading="lazy" onClick={() => { setbiggerImg(imgOrderInfo) }} />
                    </div>
                </div>
                <div className="instructions-step-img-zoom">
                    <img src={biggerImg} alt='Bigger Image' loading="lazy" />
                </div>
            </div>
        </MainContainer>

    )
}

export default Instructions;