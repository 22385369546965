import './style.scss';

function StepLetter({
    classNames
}) {
    let stepLetters = [
        ['Step 1', 'Select Country and Size'],
        ['Step 2', 'Upload Photo'],
        ['Step 3', 'Adjust'],
        ['Step 4', 'Finish']
    ];
    return (
        <div className={`step-letter ${classNames}`}>
            {
                stepLetters.map((item, i) =>
                    <div className='step-letter-item' key={i}>
                        <div className='step-letter-item-title'>
                            {item[0]}
                        </div>
                        <div className='step-letter-item-content'>
                            {item[1]}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default StepLetter;