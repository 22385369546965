import _errorImg from '../../src/assets/homepage/errormedia/error.gif'
import _badLightImg from '../../src/assets/homepage/errormedia/badLight.gif'
import _lowResolutionImg from '../../src/assets/homepage/errormedia/lowResolution.gif'
import _tooCloseImg from '../../src/assets/homepage/errormedia/tooClose.gif'
import _tooFarImg from '../../src/assets/homepage/errormedia/tooFar.gif'


export class Error
{
    static text;
    static media;

  constructor(shortName, text, quickFix, media, link) {
    this.shortName = shortName;
    this.text = text;
    this.quickFix = quickFix;
    this.media = media;
    this.link = link;

  }
}

export class Errors
{
    static TooFar = new Error("TooFar", "Camera Too Far Away", "Quick fix: Stand about an arm's length away, or roughly 20 inches from the camera, to ensure your face and shoulders fit nicely in the frame.",_tooFarImg,"tooFar");
    static TooClose = new Error("TooClose", "Camera Too Close","Quick fix: Make sure you're not too close—about 20 inches away is just right. Your head and shoulders should be fully visible in the picture, not just your face.", _tooCloseImg,"tooClose");
    static BadLight = new Error("BadLight", "Lighting Issue","Quick fix: Find a spot where the lighting is even and bright, like facing a window, and retake your photo there.", _badLightImg,"lightIssue");
    static NoFaceDetected = new Error("NoFaceDetected", "Unable to detect face", "Quick fix: Try taking a new picture.", _errorImg, "");
    static UnsupportedFileFormat = new Error("UnsupportedFileFormat", "Unsupported File format", "We accept 'JPEG' and 'PNG' file formats, if you're using iphone with 'HEIC' - switch the camera to save pictures as 'JPEG'", _errorImg, "");
    static LowResolution = new Error("LowResolution", "Low resolution","Quick fix: Take a fresh photo using your phone's camera to ensure it has the high resolution needed. Remember, photos from social media may not be clear enough.", _lowResolutionImg,"lowRes");

    static Error = new Error("Error", "Error", "", _errorImg, "");

    static findByName(name)
    {
        if (name)
        {
            switch(name.toLowerCase())
            {
                case 'toofarerror' : return(this.TooFar);
                case 'toocloseerror' : return(this.TooClose);
                case 'badlighterror' : return(this.BadLight);
            }
        }

        return(this.Error);
    }
}
