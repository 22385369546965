import { useState } from 'react';
import vector1Img from '../../../assets/homepage/step/vector1.svg';
import vector2Img from '../../../assets/homepage/step/vector2.svg';
import './style.scss';

function Popup({
    classNames,
    title,
    content,
    isOpend = false
}) {
    const [isOpen, setIsOpen] = useState(isOpend);
    return (
        <div className={`popup ${classNames}`} onClick={() => {
            setIsOpen(!isOpen)
        }}>
            <div className='popup-body'>
                <div className='popup-body-text'>
                    {title}
                </div>
                <img src={!isOpen ? vector1Img : vector2Img} className='popupo-body-img' />
            </div>
            <div className={`popup-content ${!isOpen ? "hide" : ""}`}>
                <div className='popup-content-text'>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Popup;