import ReactSlider from "react-slider";
import './style.scss';

function Slider({
    className,
    thumbClassName,
    trackClassName,
    value,
    name,
    onChange
}) {
    return (
        <ReactSlider
            className={className}
            thumbClassName={thumbClassName}
            trackClassName={trackClassName}
            value={value}
            name={name}
            onChange={(value) => {
                onChange(value)
            }}
        />
    )
}

export default Slider;