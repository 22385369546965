import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {
    order_success_url,
} from '../../global/config.js';
import './style.scss';

const CheckoutForm = ({
    count,
    clientSecret,
    handleAlert,
    updatePaymentId
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    //const [payIntent, setPayIntent] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const cSecret = clientSecret;
    //console.log('cleenSecret received : ', clientSecret);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        stripe.retrievePaymentIntent(cSecret).then(({ paymentIntent }) => {
            //console.log('retrievePaymentIntent : ', paymentIntent);
            //setPayIntent(paymentIntent);

            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    //setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        //localStorage.setItem('email', email);

        await stripe.confirmPayment({
            elements,
            redirect: "if_required"
            //  confirmParams: {
            //      return_url: order_success_url,
            //  },
        })
            .then((result) => {
                if (result.error) {
                    const error = result.error;
                    if (error.type === "card_error" || error.type === "validation_error") {
                        setMessage(error.message);
                    } else {
                        setMessage("An unexpected error occurred.");
                    }
                } else {
                    //localStorage.setItem('email', email);
                    // console.log(`result onSubmit`, result);
                    // console.log(`payment_method onSubmit`, result.paymentIntent.payment_method);
                    // console.log(`saving Email onSubmit`, email);

                    let req = {
                        paymentId: result.paymentIntent.id,
                        operationType: 'addStripePaymentId'
                    }
                    
                    updatePaymentId(req);
                    //update payment method result.paymentIntent.payment_method

                    navigate("/order-success");
                }
                setIsLoading(false);
            });
    };

    const paymentElementOptions = {
        layout: "tabs"
    }


    // function updateEmail(e) 
    // {
    //     if (e && e.value && e.value.email)
    //     {
    //         //console.log(`saving Email onchange `, e.value.email);
    //         return setEmail(e.value.email);
    //     }
    // }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <LinkAuthenticationElement
                id="link-authentication-element"
            />
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

export default CheckoutForm;