// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDTiZQB37nvUdH4Q0fceL-gZOfZwkMRXLY",
    authDomain: "passportplus-web-58cd5.firebaseapp.com",
    databaseURL: "https://passportplus-web-58cd5-default-rtdb.firebaseio.com",
    projectId: "passportplus-web-58cd5",
    storageBucket: "passportplus-web-58cd5.appspot.com",
    messagingSenderId: "973188266950",
    appId: "1:973188266950:web:82b1c1a6cdc21a65a490c7",
    measurementId: "G-N2PXHEJ227"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
// export const funcs = functions();

// Get a list of cities from your database
// async function getBlogs(db) {
//     const blogsCol = collection(db, 'blogs');
//     const blogSnapshot = await getDocs(blogsCol);
//     const blogList = blogSnapshot.docs.map(doc => doc.data());
//     return blogList;
//   }