import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import domToImage from 'dom-to-image';
// import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import Button from '../Basic/Button';
import Slider from '../Basic/Slider';
import moreImg from '../../assets/homepage/step/more.svg';
import {
    firebase_functions_url_test,
    firebase_functions_url,
    countries_size,
    size,
    size_pixel,
    watermark_text
} from '../../global/config';
import {
    setStepProcessedPhoto,
    setStepProcessedData,
} from '../../redux/actions/stepAction';
import './style.scss';

function StepContent3({
    goNext
}) {
    const { processedPhoto, processedData, country } = useSelector((state) => state.step)

    const [exposure, setExposure] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [shadows, setShadows] = useState(0);
    const [smooth, setSmooth] = useState(0);
    const canvasRef = useRef(null);
    const canvas1Ref = useRef(null);
    const cloud_name = "dagclcls7";

    const dispatch = useDispatch();

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        const img = new Image();
        img.src = processedPhoto;
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const newWidth = canvasRef.current.width;
            const newHeight = canvasRef.current.width / aspectRatio;
            canvasRef.current.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            ctx.font = '30px Arial';
            ctx.fillStyle = 'white';
            ctx.globalAlpha = 0.5;
            ctx.rotate(Math.PI / 10);
            ctx.fillText(watermark_text, parseInt(newWidth / 2) - 100, parseInt(newHeight / 4));
            ctx.fillText(watermark_text, parseInt(newWidth / 2) - 100, parseInt(newHeight / 4) * 2);
            ctx.fillText(watermark_text, parseInt(newWidth / 2) - 100, parseInt(newHeight / 4) * 3);
        };
    }, [])

    const handleChange = (value, name) => {
        switch (name) {
            case 'exposure':
                setExposure(value);
                break;

            case 'contrast':
                setContrast(value);
                break;

            case 'shadows':
                setShadows(value);
                break;

            case 'smooth':
                setSmooth(value);
                break;

            default: return;
        }
    };

    const handleSave = () => {
        const ctx = canvas1Ref.current.getContext('2d');

        const img = new Image();
        img.src = processedData;
        img.onload = () => {
            // canvas1Ref.current.style.position = "absolute";
            // canvas1Ref.current.style.bottom = "10px";
            // canvas1Ref.current.style.zIndex = "-1";
            canvas1Ref.current.style.filter = `brightness(${(exposure / 500 + 0.8) * 100}%) contrast(${contrast + 100}%) drop-shadow(${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px #000000)`;
            canvas1Ref.current.style.imageRendering = smooth < 50 ? "pixelated" : "auto";

            canvas1Ref.current.width = img.width;
            canvas1Ref.current.height = img.height;

            ctx.drawImage(img, 0, 0, img.width, img.height);

            domToImage.toPng(canvas1Ref.current)
                .then(data1Url => {
                    canvas1Ref.current.style.display = "none";
                    dispatch(setStepProcessedData(data1Url));
                    domToImage.toPng(canvasRef.current)
                        .then(dataUrl => {
                            dispatch(setStepProcessedPhoto(dataUrl));
                            goNext();
                        });
                });
        };
    }

    return (
        <>
            <div className="step-content3">
                <div className="step-content3-left">
                    <div className="step-content3-left-title">
                        Document info
                    </div>
                    <div className="step-content3-left-content">
                        <div className="step-content3-left-content-item">
                            <div className="step-content3-left-content-item-title">
                                Size
                            </div>
                            <div className="step-content3-left-content-item-content">
                                {size[countries_size[country]]}
                            </div>
                        </div>
                        <div className="step-content3-left-content-item">
                            <div className="step-content3-left-content-item-title">
                                Country
                            </div>
                            <div className="step-content3-left-content-item-content">
                                {country}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step-content3-mid">
                    <div className="step-content3-mid-img">
                        <canvas
                            ref={canvasRef}
                            className='step-content3-mid-img-photo'
                            style={{
                                filter: `
                            brightness(${(exposure / 500 + 0.8) * 100}%) 
                            contrast(${contrast + 100}%) 
                            drop-shadow(${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px #000000)
                            `,
                                imageRendering: smooth < 50 ? 'pixelated' : 'auto'
                            }}
                        />
                        {/* <CloudinaryContext > */}
                        {/* <Image
                        publicId={processedPhoto}
                        className='step-content3-mid-img-photo'
                        cloudName={cloud_name}
                    >
                        <Transformation
                            effect={`brightness:${parseInt(exposure / 10)}`}
                        />
                        <Transformation
                            effect={`contrast:${parseInt(contrast)}`}
                        />
                        <Transformation
                            effect={`shadow:${parseInt(shadows)}`}
                        />
                        <Transformation
                            effect={`sharpen:${parseInt(smooth)}`}
                        />
                    </Image> */}
                        {/* </CloudinaryContext> */}
                        {/* <img src={moreImg} className='step-content3-mid-img-more' /> */}
                    </div>

                    {/* <div className='step-content3-mid-button-group'>
                    <Button
                        title="White background"
                        classNames=''
                        onClick={() => {
                        }}
                        backgroundColor="tone2"
                    />
                    <Button
                        title="Crop"
                        classNames=''
                        onClick={() => {
                        }}
                        backgroundColor="tone2"
                    />
                </div> */}
                </div>
                <div className="step-content3-right">
                    <div className="step-content3-right-title">
                        Photo editing
                    </div>
                    <div className="step-content3-right-content">
                        <div className="step-content3-right-content-item">
                            <div className="step-content3-right-content-item-text">
                                Exposure
                            </div>
                            <Slider
                                className="horizontal-slider"
                                thumbClassName="horizontal-thumb"
                                trackClassName="horizontal-track"
                                value={exposure}
                                onChange={(value) => {
                                    handleChange(value, "exposure")
                                }}
                            />
                        </div>
                        <div className="step-content3-right-content-item">
                            <div className="step-content3-right-content-item-text">
                                Contrast
                            </div>
                            <Slider
                                className="horizontal-slider"
                                thumbClassName="horizontal-thumb"
                                trackClassName="horizontal-track"
                                value={contrast}
                                onChange={(value) => {
                                    handleChange(value, "contrast")
                                }}
                            />
                        </div>
                        <div className="step-content3-right-content-item">
                            <div className="step-content3-right-content-item-text">
                                Shadows
                            </div>
                            <Slider
                                className="horizontal-slider"
                                thumbClassName="horizontal-thumb"
                                trackClassName="horizontal-track"
                                value={shadows}
                                onChange={(value) => {
                                    handleChange(value, "shadows")
                                }}
                            />
                        </div>
                        <div className="step-content3-right-content-item">
                            <div className="step-content3-right-content-item-text">
                                Smoothing effect
                            </div>
                            <Slider
                                className="horizontal-slider"
                                thumbClassName="horizontal-thumb"
                                trackClassName="horizontal-track"
                                value={smooth}
                                onChange={(value) => {
                                    handleChange(value, "smooth")
                                }}
                            />
                        </div>
                    </div>
                    <Button
                        title="Save"
                        classNames='step-content3-right-button'
                        onClick={handleSave}
                    />
                </div>
            </div>
            <canvas ref={canvas1Ref} />
        </>
    )
}

export default StepContent3;