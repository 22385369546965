export const GET_BLOGS_REQUEST = 'GET_BLOGS_REQUEST';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const SET_CURRENT_BLOG = 'SET_CURRENT_BLOG';
export const UPDATE_BLOGS = 'UPDATE_BLOGS';
export const UPDATE_SINGLE_BLOG = 'UPDATE_SINGLE_BLOG';
export const UPDATE_ERROR = 'UPDATE_ERROR';

export const getBlogsRequest = () => (dispatch) => (
    dispatch({
        type: GET_BLOGS_REQUEST
    })
)

export const getBlogsSuccess = (data) => (dispatch) => (
    dispatch({
        type: GET_BLOGS_SUCCESS,
        payload: {
            blogs: data
        }
    })
)

export const updateBlogs = (data) => (dispatch) => (
    dispatch({
        type: UPDATE_BLOGS,
        payload: {
            blogs: data
        }
    })
)

export const updateSingleBlog = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_SINGLE_BLOG,
        payload: {
            current: data
        }
    })
}

export const updateError = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_ERROR,
        payload: {
            current: data
        }
    })
}

export const setCurrentBlog = (data) => (dispatch) => (
    dispatch({
        type: SET_CURRENT_BLOG,
        payload: {
            current: data
        }
    })
)