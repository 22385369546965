import React from 'react';
import Button from '../Button'
import './style.scss';

function Blog({
    flag,
    img,
    date,
    title,
    content,
    classNames,
    onClick
}) {
    return (
        <div className={flag === "1" ? `blog ${classNames}` : `blog blog1 ${classNames}`} onClick={onClick}>
            <div className='blog-img'>
                <img src={img} />
            </div>
            <div className='blog-body'>
                <div className='blog-body-btn-title'>
                    <div className='blog-body-btn-title-btn-date'>
                        {/* <Button
                            title='Design tools'
                            backgroundColor='tone_first_color'
                            classNames="padding-m"
                        /> */}
                        <div className='blog-body-btn-title-btn-date-date'>
                            
                            {date}
                        </div>
                    </div>
                    <div className='blog-body-btn-title-title'>
                        {title}
                    </div>
                </div>
                <div className='blog-body-content'>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Blog;