import { SET_DOC_TYPE } from '../actions/docTypeAction';
import { documentList } from '../../global/config.js'
import { DocTypes } from "../../global/docTypes.js"


const INITIAL_STATE = {docType: DocTypes.getDefault()};

export default function step(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_DOC_TYPE:
            return {
                ...state,
                docType: action.payload.value
            }
        default:
            return state;
    }
}