import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PricePanel from '../../components/Basic/PricePanel';
import Button from '../../components/Basic/Button';
import Input from '../../components/Basic/Input';
import closeImg from '../../assets/icons/close.svg';
import flowersImg from '../../assets/homepage/flowers.png';
import { setPrices } from '../../redux/actions/priceAction';
import {
    price,
    disprice,
    disrate,
} from '../../global/config';
import './style.scss';

function Price({

}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const { count } = useSelector((state) => state.price);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [isDialogShow, setDialogShow] = useState(false);
    const [dateFlag, setDateFlag] = useState(true);
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");
    const [inputType1, setInputType1] = useState("");
    const [inputType2, setInputType2] = useState("");
    const [isNext1, setIsNext1] = useState(false);
    const [isNext2, setIsNext2] = useState(false);
    const dlgContentRef = useRef(null);
    const dlgResultRef = useRef(null);
    const flowersRef = useRef(null);
    const emailRef = useRef(null);

    const handleDlg = () => {
        dispatch(setPrices(disprice))
        setDialogShow(!isDialogShow);
        setInputType1("");
        setInputType2("");
        setEmail("");
        setAge("");
        setTimeout(() => {
            emailRef.current.focus();
            dlgContentRef.current.style.display = "flex";
            dlgResultRef.current.style.display = "none";
            flowersRef.current.style.display = "none"
        }, 300);
    }

    const handleSubmit = () => {
        dlgContentRef.current.style.display = "none";
        dlgResultRef.current.style.display = "flex";
        flowersRef.current.style.display = "block";
    }

    const onHandleEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value) {
            setIsNext1(true);
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
                setInputType1("success")
            else setInputType1("error")
        }
        else {
            setIsNext1(false);
            setInputType1("error")
        }
    }

    const onHandleAge = (e) => {
        setAge(e.target.value);
        if (e.target.value) {
            setIsNext2(true);
            setInputType2("success")
        }
        else {
            setIsNext2(false);
            setInputType2("error")
        }
    }

    const handleBlur1 = () => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
            setInputType1("success")
        else setInputType1("error")
    }

    const handleBlur2 = () => {
        if (age)
            setInputType2("success")
        else setInputType2("error")
    }

    return (
        <>
            {/* <Header /> */}
            <div className='price'>
                <div className='price-up'>
                    <div className='price-up-title'>
                        Take a small survey and get a {disrate} discount
                    </div>
                    <div className='price-up-content'>
                        Our pricing is simple with no hidden fees
                    </div>
                </div>
                <div className='price-down'>
                    <PricePanel
                        value={`$${price}`}
                        type="white"
                        onClick={() => {
                            dispatch(setPrices(price))
                            navigate('/order')
                        }}
                        badge="Price without discount"
                    />

                    <PricePanel
                        value={`$${disprice}`}
                        preValue={`$${price}`}
                        type="dark"
                        onClick={handleDlg}
                        badge="Price with discount"
                    />
                </div>
                {
                    isDialogShow && <>
                        <div
                            className='price-disable-background'
                            onClick={handleDlg}
                        >
                            <img src={flowersImg} className='price-disable-background-img' ref={flowersRef} />
                        </div>
                    </>
                }
                <div className={`price-dialog ${isDialogShow && 'active'}`}>

                    <div className='price-dialog-content' ref={dlgContentRef}>
                        <img
                            className="close-btn"
                            src={closeImg}
                            onClick={handleDlg}
                            alt="Close"
                        />
                        <div className='price-dialog-content-title'>
                            <div>Take a small survey and get a</div>
                            <div className='price-dialog-content-title-discount'>{disrate} discount</div>
                        </div>

                        <div className='price-dialog-content-content'>
                            <div className='price-dialog-content-content-item'>
                                <div className='price-dialog-content-content-item-label'>
                                    Email*
                                </div>
                                <div className='price-dialog-content-content-item-control'>
                                    <Input
                                        placeholder={"Your email"}
                                        value={email}
                                        ref={emailRef}
                                        onChange={onHandleEmail}
                                        onBlur={handleBlur1}
                                        background={"gray"}
                                        type={inputType1}
                                    />
                                </div>
                            </div>
                            <div className='price-dialog-content-content-item'>
                                <div className='price-dialog-content-content-item-label'>
                                    Age
                                </div>
                                <div className='price-dialog-content-content-item-control'>
                                    <Input
                                        placeholder={"Your age"}
                                        value={age}
                                        onChange={onHandleAge}
                                        onBlur={handleBlur2}
                                        background={"gray"}
                                        type={inputType2}
                                    />
                                </div>
                            </div>
                            <div className='price-dialog-content-content-item'>
                                <div className='price-dialog-content-content-item-label'>
                                    Passport renewal if so the date*
                                </div>
                                <div className='price-dialog-content-content-item-button-group'>
                                    <Button
                                        title='Yes'
                                        backgroundColor={dateFlag ? "default" : "disable"}
                                        onClick={() => {
                                            setDateFlag(true)
                                        }}
                                    />
                                    <Button
                                        title='No'
                                        backgroundColor={dateFlag ? "disable" : "default"}
                                        onClick={() => {
                                            setDateFlag(false)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='price-dialog-content-button'>
                            <Button
                                title='Submit'
                                backgroundColor='brand'
                                onClick={handleSubmit}
                                isEnable={isNext1 && isNext2 && inputType1 === "success" && inputType2 === "success"}
                            />
                        </div>
                    </div>

                    <div className='price-dialog-result' ref={dlgResultRef}>
                        <img
                            className="close-btn"
                            src={closeImg}
                            onClick={handleDlg}
                            alt="Close"
                        />
                        <div className='price-dialog-content-title'>
                            <div>Thank you for your time!</div>
                            <div>It is very important for us. You got a {disrate} discount</div>
                        </div>

                        <div className='price-dialog-content-button'>
                            <Button
                                title={`Got a ${disrate} discount`}
                                backgroundColor='brand'
                                onClick={() => {
                                    navigate('/order')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Price;