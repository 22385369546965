import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PricePanel from '../../components/Basic/PricePanel';
import passportImg from '../../assets/homepage/passport/3.jpg';
import arrowImg from '../../assets/homepage/step/arrow3.png';
import {
    price,
    disprice,
    disrate,
    stripe_publish_key,
    firebase_functions_url,
    firebase_functions_url_test
} from '../../global/config';
import './style.scss'
import CheckoutForm from '../../components/CheckoutForm';

const stripePromise = loadStripe(stripe_publish_key);

function Order({

}) {
    const { processedPhoto } = useSelector((state) => state.step);
    const { count } = useSelector((state) => state.price);

    const [isAlertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [flag, setFlag] = useState(true);
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        if (flag) {
            window.scrollTo(0, 0)
            setFlag(false)
        }

        fetch(`${firebase_functions_url}/stripe`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                amount: count * 100,
                type: "create-payment-intent"
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.statusCode) {
                    handleAlert(data.param + " is invalid.")
                } else {
                    setClientSecret(data.clientSecret)
                }
            });
    }, []);

    const appearance = {
        theme: 'flat',
        rules: {
            '.Input': {
                borderRadius: '8px',
                // backgroundColor: 'FFFFFF',
            },
            '#Field-emailInput': {
                backgroundColor: '#ffffff',
            }
        }
    };

    const options = {
        clientSecret,
        appearance,
    }

    const handleAlert = (msg) => {
        setAlertMsg(msg);
        setAlertShow(true);
        setTimeout(() => {
            setAlertShow(false);
        }, 5000)
    }

    return (
        <>
            {/* <Header /> */}
            <div className='order'>
                <div className={`order-alert ${isAlertShow && 'active'}`}>
                    {alertMsg}
                </div>
                <div className='order-left'>
                    {
                        count === disprice
                            ?
                            <PricePanel
                                value={`$${disprice}`}
                                preValue={price}
                                type="dark"
                                flag={2}
                                badge={`${disrate} discount`}
                            />
                            : <PricePanel
                                value={`$${price}`}
                                type="white"
                                flag={2}
                            />
                    }
                    <div className='order-left-down'>
                        <img src={processedPhoto} className='order-left-down-img' />
                        <div className="order-left-down-photos">
                            <div className="order-left-down-photos-item">
                                <img src={processedPhoto} />
                                <img src={processedPhoto} />
                            </div>
                            <div className="order-left-down-photos-item">
                                <img src={processedPhoto} />
                                <img src={processedPhoto} />
                            </div>
                        </div>
                    </div>
                    <div className='order-left-addImgs'>
                        <img src={passportImg} className='order-left-addImgs-passport' />
                        <img src={arrowImg} className='order-left-addImgs-arrow' />
                    </div>
                </div>
                <div className='order-right'>
                    <div className='order-right-title'>
                        Subscription payment
                    </div>
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    )}
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Order;
