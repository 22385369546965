import { useState, useEffect } from 'react'
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Button from '../../components/Basic/Button';
import Popup from '../../components/Basic/Popup';
import Doc from '../../components/Basic/Doc';
import documentImg from '../../assets/homepage/document.jpg'
import passportImg from '../../assets/homepage/passport/1.jpg';
import faqImg from '../../assets/homepage/faq.png';
import arrowLeftImg from '../../assets/icons/arrow-left.svg'
import arrowRightImg from '../../assets/icons/arrow-right.svg'
import {
    popdoc_requirements,
    popdoc_requirements1_title,
    popdoc_requirements1_content,
    popdoc_requirements2_title,
    popdoc_requirements2_content,
    popdoc_requirements3_title,
    popdoc_requirements3_content,
    popdoc_requirements4_title,
    popdoc_requirements4_content,
    popdoc_requirements5_title,
    popdoc_requirements5_content,
    popdoc_faq,
    popdoc_faq1_title,
    popdoc_faq1_content,
    popdoc_faq2_title,
    popdoc_faq2_content,
    popdoc_faq3_title,
    popdoc_faq3_content,
    popdoc_faq4_title,
    popdoc_faq4_content,
} from '../../global/config';
import './style.scss';

function PopularDoc() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            {/* <Header /> */}
            <div className='popular-doc'>
                <div className='popular-doc-link'>
                    <div>Popular documents</div>
                    <div>/</div>
                    <div className='selected'>Australian passport</div>
                </div>
                <div className='popular-doc-hero'>
                    <div className='popular-doc-hero-left'>
                        <div className='popular-doc-hero-left-body'>
                            <div className='popular-doc-hero-left-body-title'>
                                Australian Passport
                            </div>
                            <div className='popular-doc-hero-left-body-content'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                            </div>
                        </div>
                        <Button
                            title="Upload photo"
                            classNames='popular-doc-hero-left-button'
                        />
                    </div>
                    <div className='popular-doc-hero-right'>
                        <img src={documentImg} />
                    </div>
                </div>
                <div className='popular-doc-requirements'>
                    <div className='popular-doc-requirements-left'>
                        <img src={passportImg} className="popular-doc-requirements-left-img" />
                    </div>
                    <div className='popular-doc-requirements-right'>
                        <div className='popular-doc-requirements-right-up'>
                            <div className='popular-doc-requirements-right-up-title'>
                                <span>Australian Passport</span> photos requirements
                            </div>
                            <div className='popular-doc-requirements-right-up-content'>
                                {popdoc_requirements}
                            </div>
                        </div>
                        <div className='popular-doc-requirements-right-down'>
                            <Popup
                                title={popdoc_requirements1_title}
                                content={popdoc_requirements1_content}
                            />
                            <Popup
                                title={popdoc_requirements2_title}
                                content={popdoc_requirements2_content}
                            />
                            <Popup
                                title={popdoc_requirements3_title}
                                content={popdoc_requirements3_content}
                            />
                            <Popup
                                title={popdoc_requirements4_title}
                                content={popdoc_requirements4_content}
                                isOpend={true}
                            />
                            <Popup
                                title={popdoc_requirements5_title}
                                content={popdoc_requirements5_content}
                            />
                        </div>
                    </div>
                </div>
                <div className='popular-doc-faq'>
                    <div className='popular-doc-faq-left'>
                        <div className='popular-doc-faq-left-up'>
                            <div className='popular-doc-faq-left-up-title'>
                                Frequently Asked Questions
                            </div>
                            <div className='popular-doc-faq-left-up-content'>
                                {popdoc_faq}
                            </div>
                        </div>
                        <div className='popular-doc-faq-left-down'>
                            <Popup
                                title={popdoc_faq1_title}
                                content={popdoc_faq1_content}
                            />
                            <Popup
                                title={popdoc_faq2_title}
                                content={popdoc_faq2_content}
                            />
                            <Popup
                                title={popdoc_faq3_title}
                                content={popdoc_faq3_content}
                            />
                            <Popup
                                title={popdoc_faq4_title}
                                content={popdoc_faq4_content}
                                isOpend={true}
                            />
                        </div>
                    </div>
                    <div className='popular-doc-faq-right'>
                        <img src={faqImg} className="popular-doc-faq-right-img" />
                    </div>
                </div>
                <div className='popular-doc-popular'>
                    <div className='popular-doc-popular-header'>
                        <div className='popular-doc-popular-header-title'>
                            Other popular documents
                        </div>
                        <div className='popular-doc-popular-header-control'>
                            <img src={arrowLeftImg} />
                            <img src={arrowRightImg} />
                        </div>
                    </div>
                    <div className='popular-doc-popular-content'>
                        <Doc
                            img={documentImg}
                            title={"Australian Passport"}
                        />
                        <Doc
                            img={documentImg}
                            title={"Australian Passport"}
                        />
                        <Doc
                            img={documentImg}
                            title={"Australian Passport"}
                        />
                        <Doc
                            img={documentImg}
                            title={"Australian Passport"}
                        />
                    </div>
                    <div className='popular-doc-popular-footer'>
                        <img src={arrowLeftImg} />
                        <img src={arrowRightImg} />
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default PopularDoc;