import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Doc from '../../components/Basic/Doc';
import documentImg from '../../assets/homepage/document.jpg'
import searchImg from '../../assets/icons/search-icon.svg';
import closeImg from '../../assets/icons/close-icon.svg';
import arrowLeftImg from '../../assets/icons/arrow-left.svg';
import arrowRightImg from '../../assets/icons/arrow-right.svg';
import './style.scss';

const documents = [
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
    {
        id: 0,
        img: documentImg,
        title: 'Australian Passport'
    },
]

function PopularDocuments() {
    const navigate = useNavigate();
    const [pages, setPages] = useState([
        1, 2, 3, 4
    ])
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        let result = documents.filter(i => {
            if (i.title.includes(searchKey)) {
                return true;
            }
            return false;
        });
        setFilteredData(result);
    }, [searchKey])

    return (
        <>
            {/* <Header /> */}
            <div className='popularDocuments'>
                <div className='popularDocuments-header'>
                    <div className='popularDocuments-header-title'>
                        Popular documents
                    </div>
                    <div className={`popularDocuments-header-control ${searchKey.length !== 0 && 'active'}`}>
                        <input
                            type='text'
                            placeholder='Search for a document'
                            onChange={(e) => {
                                setSearchKey(e.target.value);
                            }}
                            value={searchKey}
                        />
                        {
                            searchKey
                                ? <img src={closeImg} />
                                : <img src={searchImg} />
                        }
                    </div>
                </div>
                {
                    filteredData.length === 0 ?
                        <div className='popularDocuments-empty'>
                            Nothing was found for your request...
                        </div>
                        :
                        <div className='popularDocuments-content'>
                            {
                                filteredData.map(item => (
                                    <Doc
                                        img={documentImg}
                                        title={"Australian Passport"}
                                        onClick={() => {
                                            navigate('/popdoc');
                                        }}
                                    />
                                ))
                            }
                        </div>
                }
                {
                    filteredData.length !== 0 &&
                    <div className='popularDocuments-footer'>
                        <img src={arrowLeftImg} className='popularDocuments-footer-previous disabled' />
                        {
                            pages.map(item => (
                                <div className={`popularDocuments-footer-item ${selectedPage === item && 'disabled'}`}>
                                    {item}
                                </div>
                            ))
                        }
                        <img src={arrowRightImg} className='popularDocuments-footer-next' />
                    </div>
                }
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default PopularDocuments;