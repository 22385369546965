import { useEffect } from "react"
import MainContainer from '../../components/Basic/MainContainer';
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import "./style.scss"

function Cookies({}) {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <MainContainer>
      {/* <Header /> */}
      <div className="cookies">
        <div className="cookies-title">Cookies Policy</div>
        <div className="cookies-title">Last Updated: 10-4-2023</div>
        <div className="cookies-title">Introduction</div>

        <div className="cookies-text">
          Welcome to{" "}
          <a href="https://quickpassportphotos.com">quickpassportphotos.com</a>.
          We use cookies to improve the user's experience while visiting our
          website. This Cookie Policy explains how we use cookies and your
          related choices.
        </div>
        <div className="cookies-title">What Are Cookies</div>
        <div className="cookies-text">
          Cookies are small data files stored on your browser or device. They
          may be served by the entity that operates the website you are visiting
          ("first-party") or by other companies ("third-party").
        </div>
        <div className="cookies-group">
          <div className="cookies-title">Types of Cookies We Use</div>
          <br></br>
          <br></br>
          <div className="cookies-group-title">Essential Cookies: </div>
          <div className="cookies-group-text">
            These cookies are necessary for the functionality of our Services.
            They cannot be switched off.
          </div>
          <div className="cookies-group-title">Performance Cookies:</div>
          <div className="cookies-group-text">
            These cookies collect information about how visitors use our
            website, for instance, which pages visitors go to most often.
          </div>
          <div className="cookies-group-title">Functional Cookies:</div>
          <div className="cookies-group-text">
            These cookies allow us to remember choices you make and provide
            enhanced, personalized features.
          </div>
        </div>
        <div className="cookies-title">How We Use Cookies</div>
        <div className="cookies-text">
          We use cookies to understand, secure, operate, and provide our
          Services. For example, we use cookies:
          <br></br>
          to recognize you when you visit our Services, to track your
          preferences and behavior, and to confirm whether you have opened an
          email from us.
        </div>
        <div className="cookies-title">Your Choices</div>
        <div className="cookies-text">
          You have the right to choose whether or not to accept cookies.
          However, they are an important part of how our Services work, so you
          should be aware that if you choose to refuse or remove cookies, this
          could affect the availability and functionality of the Services.
        </div>
        <div className="cookies-title">How to Manage Cookies</div>
        <div className="cookies-text">
          You can set or amend your web browser controls to accept or refuse
          cookies. If you choose to reject cookies, you may still use our
          website, though your access to some functionality and areas of our
          website may be restricted.
        </div>
        <div className="cookies-title">Changes to This Cookie Policy</div>
        <div className="cookies-text">
          We may update this Cookie Policy from time to time to reflect, for
          example, changes to the cookies we use or for other operational,
          legal, or regulatory reasons.
        </div>
        <div className="cookies-title">Contact Us</div>
        <div className="cookies-text">
          For more information about our use of cookies, please contact us at
          Info@quickpassportphotos.com .
        </div>
      </div>
      {/* <Footer /> */}
      </MainContainer>
  )
}

export default Cookies
