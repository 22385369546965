import './style.scss';
import { useEffect, useState, useRef } from 'react';

function TabButtons({
    buttonList,
    updateActiveButton
}) {
    const [activeButton, setActiveButton] = useState(buttonList[0]);

    return (
        <div className="tabButtons">
            {buttonList.map((buttonName) => (
                <div key={buttonName} onClick={() => {setActiveButton(buttonName); updateActiveButton(buttonName);}} className={`tabButtons-button ${activeButton == buttonName && 'activeButton'}`}>
                    {buttonName}
                </div>
            ))}
        </div>
    )
}

export default TabButtons;