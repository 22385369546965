import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import domToImage from "dom-to-image";
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
// import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import Button from '../../components/Basic/Button';
import Slider from '../../components/Basic/Slider';
import HowToMake from '../../components/HowToMake';
import moreImg from '../../assets/homepage/step/more.svg';
import cropImg from '../../assets/homepage/step/crop.svg';
import redoImg from '../../assets/homepage/step/redo.svg';
import closeImg from '../../assets/icons/close.svg'
import {
    firebase_functions_url_test,
    firebase_functions_url,
    countries_size,
    size,
    size_pixel,
    watermark_text
} from '../../global/config';
import {
    setStepProcessedPhoto,
    setStepProcessedData,
} from '../../redux/actions/stepAction';
import './style.scss';

function Edit() {
    const processedPhoto = localStorage.getItem('photo');
    const processedData = localStorage.getItem('data');

    const [exposure, setExposure] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [shadows, setShadows] = useState(0);
    const [smooth, setSmooth] = useState(0);
    const [isDialogShow, setDialogShow] = useState(false);

    const photoRef = useRef(null);
    const realPhotoRef = useRef(null);
    const dlgContentRef = useRef(null);
    const canvasWrapperRef = useRef(null);
    const cloud_name = "dagclcls7";

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDlg = () => {
        setDialogShow(!isDialogShow);
    }

    const reset = () => {
        setExposure(0);
        setContrast(0);
        setShadows(0);
        setSmooth(0);
    }

    const handleChange = (value, name) => {
        switch (name) {
            case 'exposure':
                setExposure(value);
                break;

            case 'contrast':
                setContrast(value);
                break;

            case 'shadows':
                setShadows(value);
                break;

            case 'smooth':
                setSmooth(value);
                break;

            default: return;
        }
    };

    const handleSave = () => {
        const canvas1 = document.createElement('canvas');
        const canvas2 = document.createElement('canvas');

        const img = new Image();
        img.src = processedData;
        img.onload = () => {
            canvas1.width = img.width;
            canvas1.height = img.height;
            const ctx1 = canvas1.getContext('2d');
            ctx1.filter = `brightness(${(exposure / 500 + 0.8) * 100}%) contrast(${contrast + 100}%) drop-shadow(${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px #000000)`;
            ctx1.imageRendering = smooth < 50 ? "pixelated" : "auto";
            ctx1.drawImage(img, 0, 0, img.width, img.height);
            const dataURL1 = canvas1.toDataURL('image/png');

            canvas2.width = photoRef.current.width;
            canvas2.height = photoRef.current.height;
            const ctx2 = canvas2.getContext('2d');
            ctx2.filter = `brightness(${(exposure / 500 + 0.8) * 100}%) contrast(${contrast + 100}%) drop-shadow(${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px #000000)`;
            ctx2.imageRendering = smooth < 50 ? "pixelated" : "auto";
            ctx2.drawImage(photoRef.current, 0, 0, photoRef.current.width, photoRef.current.height);
            const dataURL2 = canvas2.toDataURL('image/png');

            dispatch(setStepProcessedData(dataURL1));
            localStorage.setItem('data', dataURL1);
            dispatch(setStepProcessedPhoto(dataURL2));
            localStorage.setItem('photo', dataURL2);

            navigate("/payment");
        }
    }

    return (
        <>
            <div className="edit">
                <div className='edit-up'>
                    <div className='edit-up-title'>
                        Adjust your photo
                    </div>
                    <div className='edit-up-subtitle'>
                        How to take good photos? <span onClick={handleDlg}>Learn more</span>
                    </div>
                </div>
                <div className='edit-down'>
                    <div className="edit-down-left">
                        <div className="edit-down-left-img">
                            <img
                                src={processedPhoto}
                                crossOrigin="anonymous"
                                ref={photoRef}
                                className='edit-down-left-img-photo'
                                style={{
                                    filter: `
                                        brightness(${(exposure / 500 + 0.8) * 100}%) 
                                        contrast(${contrast + 100}%) 
                                        drop-shadow(${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px ${parseInt(shadows / 20)}px #000000)
                                        `,
                                    imageRendering: smooth < 50 ? 'pixelated' : 'auto'
                                }}
                            />
                            <img src={cropImg} className='edit-down-left-img-crop' />
                        </div>
                    </div>
                    <div className="edit-down-right">
                        <div className='edit-down-right-header'>
                            <div className="edit-down-right-title">
                                Photo editing
                            </div>
                            <div className='edit-down-right-redo' onClick={reset}>
                                <img src={redoImg} />
                                <span>Reset</span>
                            </div>
                        </div>
                        <div className="edit-down-right-content">
                            <div className="edit-down-right-content-item">
                                <div className="edit-down-right-content-item-text">
                                    Exposure
                                </div>
                                <div className="edit-down-right-content-item-group">
                                    <Slider
                                        className="horizontal-slider"
                                        thumbClassName="horizontal-thumb"
                                        trackClassName="horizontal-track"
                                        value={exposure}
                                        onChange={(value) => {
                                            handleChange(value, "exposure")
                                        }}
                                    />
                                    <div className="edit-down-right-content-item-text">
                                        {exposure}
                                    </div>
                                </div>
                            </div>
                            <div className="edit-down-right-content-item">
                                <div className="edit-down-right-content-item-text">
                                    Contrast
                                </div>
                                <div className="edit-down-right-content-item-group">
                                    <Slider
                                        className="horizontal-slider"
                                        thumbClassName="horizontal-thumb"
                                        trackClassName="horizontal-track"
                                        value={contrast}
                                        onChange={(value) => {
                                            handleChange(value, "contrast")
                                        }}
                                    />
                                    <div className="edit-down-right-content-item-text">
                                        {contrast}
                                    </div>
                                </div>
                            </div>
                            <div className="edit-down-right-content-item">
                                <div className="edit-down-right-content-item-text">
                                    Shadows
                                </div>
                                <div className="edit-down-right-content-item-group">
                                    <Slider
                                        className="horizontal-slider"
                                        thumbClassName="horizontal-thumb"
                                        trackClassName="horizontal-track"
                                        value={shadows}
                                        onChange={(value) => {
                                            handleChange(value, "shadows")
                                        }}
                                    />
                                    <div className="edit-down-right-content-item-text">
                                        {shadows}
                                    </div>
                                </div>
                            </div>
                            <div className="edit-down-right-content-item">
                                <div className="edit-down-right-content-item-text">
                                    Smoothing effect
                                </div>
                                <div className="edit-down-right-content-item-group">
                                    <Slider
                                        className="horizontal-slider"
                                        thumbClassName="horizontal-thumb"
                                        trackClassName="horizontal-track"
                                        value={smooth}
                                        onChange={(value) => {
                                            handleChange(value, "smooth")
                                        }}
                                    />
                                    <div className="edit-down-right-content-item-text">
                                        {smooth}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            title="Save"
                            classNames='edit-down-right-button'
                            onClick={handleSave}
                        />
                    </div>
                </div>

                {
                    isDialogShow && <>
                        <div
                            className='edit-disable-background'
                            onClick={handleDlg}
                        >
                        </div>
                    </>
                }
                <div className={`edit-dialog ${isDialogShow && 'activedlg'}`}>
                    <div className='edit-dialog-content' ref={dlgContentRef}>
                        <img
                            className="close-btn"
                            src={closeImg}
                            onClick={handleDlg}
                            alt="Close"
                        />
                        <div className='edit-dialog-content-content'>
                            <HowToMake />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Edit;