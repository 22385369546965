import axios from "axios"
import {
    firebase_functions_url_test,
    firebase_functions_url,
    countries_size,
    size_pixel,
    session_id,
    parent_session_id
} from "../global/config.js"
import { Errors } from "../global/errors.js"

export class backendUtils {

    static removeBackground(
        resImgUri,
        detections,
        cutLines,
        box,
        frontEndLog,
        drawTheResponce,
        showTypeError,
        topCandL,
        parentSessionId
    ) {
        // return getResultAndDrawTest(resImgUri, detections, cutLines, frontEndLog);

        //console.log('front end log', frontEndLog);
        //console.log('img uri', resImgUri);

        const formData = new FormData()
        formData.append("top", cutLines.top)
        formData.append("left", cutLines.left)
        formData.append("bottom", cutLines.bottom)
        formData.append("right", cutLines.right)
        formData.append("width", cutLines.width)
        formData.append("height", cutLines.height)
        formData.append(
            "country_size_width",
            size_pixel[countries_size["USA"]].width
        )
        formData.append(
            "country_size_height",
            size_pixel[countries_size["USA"]].height
        )
        formData.append("file", resImgUri)
        formData.append("frontEndLog", JSON.stringify(frontEndLog))
        formData.append("topCandL", JSON.stringify(topCandL))
        formData.append("detectionModel", frontEndLog.detectionModel)

        if (parentSessionId) {
            formData.append(parent_session_id, parentSessionId)
        }

        axios
            .post(`${firebase_functions_url}/process`, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            })
            .then((res) => {
                drawTheResponce(res.data, detections, cutLines)
            })
            .catch((error) => {
                console.error(`err `, error)
                showTypeError(Errors.Error)
            })
    }

    static getCutLines(
        box,
        detectionModel,
        sessionId,
        minResolution,
        maxResolution,
        lightArr,
        sum,
        fileName,
        base64img,
        parentSessionId
    ) {
        
//        return this.CalculateCutOffLines(box, detectionModel, sessionId, minResolution, maxResolution)

        return new Promise((resolve) => {
            //console.log(`requested cut lines`);

            //console.log("facebox: ", JSON.stringify(box));
            const formData = new FormData()
            formData.append("facebox", JSON.stringify(box))
            formData.append("lightArr", JSON.stringify(lightArr))
            formData.append(session_id, sessionId)
            formData.append("detectionModel", detectionModel)
            formData.append("minResolution", minResolution)
            formData.append("maxResolution", maxResolution)
            formData.append("sum", sum)
            formData.append("fileName", fileName)

            if (parentSessionId) {
                formData.append(parent_session_id, parentSessionId)
            }
    
            let data = base64img.split(',');
            data = data[1];
            formData.append("src", data)

            //const res = await axios.post(`${firebase_functions_url}/getcutlines`, formData, {
            axios
                .post(`${firebase_functions_url}/getcutlines`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "json",
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    const errName =
                        error.response != undefined &&
                            error.response.data != undefined &&
                            error.response.data.error != undefined
                            ? error.response.data.error.name
                            : null
                    const newError = Errors.findByName(errName)
                    const cutlines = {
                        isError: true,
                        error: newError,
                    }

                    resolve(cutlines)
                })
        })
    }

    static async readFile(data) {
        return new Promise((resolve, reject) => {
          let blob = new Blob([data], { type: "image/jpeg" })
  
          const reader = new FileReader();
          reader.onload = ({ target }) => {
  
            let base64Data = target.result.split(',');
            base64Data = base64Data[1];
            resolve(base64Data);
          }
          reader.onerror = error => reject(error);
          reader.readAsDataURL(blob);
        });
      }
  

    static async getErrorDetails(error, file, img, base64img) {
        let errDetails = {
            name: error.shortName
        }

        if (file.name) {
            errDetails.fileName = file.name;
        }

        if (base64img) {
            base64img = base64img.split(',');
            base64img = base64img[1];
            errDetails.src = base64img;
        }
        else if (file) {
            
            let base64Data = await this.readFile(file);

            errDetails.src = base64Data;
        }

        //console.log("base64Data", errDetails.src);

        if (img) {
            // let reader = new FileReader();
            // let base64Data = reader.readAsDataURL(img.src); 
            errDetails.wxh = `${img.width} x ${img.height}`;
        }

        //console.log("img", img);

        return errDetails;
    }

    static async reportError(sessionId, error, file, imgObj, base64img, parentSessionId) {
        const formData = new FormData()
        let errDetails = await this.getErrorDetails(error, file, imgObj, base64img)
        formData.append(session_id, sessionId)
        formData.append("error", JSON.stringify(errDetails))

        if (parentSessionId) {
            formData.append(parent_session_id, parentSessionId)
        }
        //console.log(`reportError: `, JSON.stringify(errDetails))

        axios.post(`${firebase_functions_url}/reportError`, formData, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "json",
        })
            .catch((error) => {
                console.error(`reportError `, error)
            })
    }

    static async reportSessionEvent(sessionId, eventType) {
        const formData = new FormData()
        formData.append(session_id, sessionId)
        formData.append("eventType", eventType)

        //console.log(`reportSessionEvent: `)

        axios.post(`${firebase_functions_url}/reportEvent`, formData, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "json",
        })
            .catch((error) => {
                console.error(`reportEvent `, error)
            })
    }
}