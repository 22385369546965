import Button from '../../Basic/Button';
import checkImg from '../../../assets/icons/check.svg';
import checkWhiteImg from '../../../assets/icons/check-white.svg';
import alertCircleImg from '../../../assets/icons/alert-circle.svg';
import alertCircleWhiteImg from '../../../assets/icons/alert-circle-white.svg';
import './style.scss';

const PricePanel = ({
    classNames,
    badge = "",
    value,
    preValue,
    type = "white",
    flag = 1,
    onClick
}) => {
    const check = type === "white" ? checkImg : checkWhiteImg;
    const alert = type === "white" ? alertCircleImg : alertCircleWhiteImg;
    return (
        <div className={`price-panel ${classNames} ${type === "white" ? "" : "black"}`} >
            {badge
                ? <div className={`price-panel-badge ${flag === 2 ? "right-badge" : ""}`}>
                    {badge}
                </div>
                : ""
            }

            <div className='price-panel-show'>
                <div className='price-panel-show-value'>
                    {value}
                </div>

                {preValue
                    ? <div className='price-panel-show-preValue'>
                        {preValue}
                    </div>
                    : ""
                }
            </div>

            <div className='price-panel-content'>
                <div className='price-panel-content-item'>
                    <img src={check} />
                    <div className='price-panel-content-item-text'>HD online version</div>
                    <img src={alert} />
                </div>
                <div className='price-panel-content-item'>
                    <img src={check} />
                    <div className='price-panel-content-item-text'>Sending by email</div>
                    <img src={alert} />
                </div>
                <div className='price-panel-content-item'>
                    <img src={check} />
                    <div className='price-panel-content-item-text'>100% quality</div>
                    <img src={alert} />
                </div>
            </div>

            {flag === 1
                ? <Button
                    title="Choose"
                    classNames='price-panel-button'
                    backgroundColor={type === "white" ? "default" : "minor"}
                    onClick={() => {
                        onClick()
                    }}
                />
                : ""
            }
        </div>
    )
}

export default PricePanel;