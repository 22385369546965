import './style.scss';
import StepLineImg1 from '../../../assets/homepage/step/line1.svg'
import StepLineImg2 from '../../../assets/homepage/step/line2.svg'

function StepLine({
    size,
    classNames
}) {
    let steps = [1, 2, 3, 4]

    return (
        <div className={`step-line ${classNames}`}>
            {
                steps.map((item, i) =>
                    (i < size)
                        ? <div className='step-line-img' key={i}>
                            <img src={StepLineImg1} />
                        </div>
                        :
                        <div className='step-line-img' key={i}>
                            <img src={StepLineImg2} />
                        </div>
                )
            }
        </div>
    )
}

export default StepLine;