import axios from "axios";

export const SET_STEP_DELETEFLAG = 'SET_STEP_DELETEFLAG';
export const SET_STEP_PROCESSEDPHOTO = 'SET_STEP_PROCESSEDPHOTO';
export const SET_STEP_PROCESSEDDATA = 'SET_STEP_PROCESSEDDATA';
export const EDIT_STEP_PHOTO = 'EDIT_STEP_PHOTO';
export const SET_STEP_COUNTRY = 'SET_STEP_COUNTRY';

export const setStepDeleteFlag = (value) => (dispatch) => (
    dispatch({
        type: SET_STEP_DELETEFLAG,
        payload: {
            value: value
        }
    })
)

export const setStepProcessedPhoto = (value) => (dispatch) => (
    dispatch({
        type: SET_STEP_PROCESSEDPHOTO,
        payload: {
            value: value
        }
    })
)

export const setStepProcessedData = (value) => (dispatch) => (
    dispatch({
        type: SET_STEP_PROCESSEDDATA,
        payload: {
            value: value
        }
    })
)

export const editStepPhoto = (value) => (dispatch) => (
    axios.post('https://us-central1-passportplus-web-58cd5.cloudfunctions.net/app/process', value, {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer',
    })
        .then(buffer => {
            const blob = new Blob([buffer.data], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
            dispatch({
                type: EDIT_STEP_PHOTO,
                payload: {
                    value: imageUrl
                }
            })
        })
)

export const setStepCountry = (value) => (dispatch) => (
    dispatch({
        type: SET_STEP_COUNTRY,
        payload: {
            value: value
        }
    })
)