import "./style.scss"

import howImg1 from "../../assets/homepage/howtomake2-1R.webp"
import howImg2 from "../../assets/homepage/howtomake2-2R.webp"
import howImg22 from "../../assets/homepage/howtomake2-22R.webp"
import howImg3 from "../../assets/homepage/howtomake2-3R.webp"

function HowToMakeV2(param) {
  return (
    <div className='howtomake2' id={`${param.id}`}>
      <div className='howtomake2-header'>
        <div className='howtomake2-header-title'>
          How to take good photos
        </div>
      </div>
      <div className="howtomake2-content">
        <div className="howtomake2-content-item">
          <div className="howtomake2-content-item-image">
            <div>
            20 inches
            <span className="howtomake2-content-item-image-edges">
              <span className="howtomake2-content-item-image-edges-middleLine">
              </span>
            </span>
            </div>
            <img src={howImg1} alt='Side shot' loading="lazy" />
          </div>
          <div className="howtomake2-content-item-title">Distance from camera</div>
          <div className="howtomake2-content-item-desc">
            Pass the camera to a friend for that flawless shot 
            or make sure your shoulders step into the spotlight; 
            they deserve some camera love too! Happy snapping!
          </div>
        </div>
        <div className="howtomake2-content-item">
          <div className="howtomake2-content-item-image">
            <img src={howImg2} alt='Correct pose example' loading="lazy" />
            <img src={howImg3} alt='Incorrect pose example' loading="lazy" />
          </div>
          <div className="howtomake2-content-item-title">Posture Pointer: Face Forward!</div>
          <div className="howtomake2-content-item-desc">
            Position your face square with the camera, keeping it at eye level for that just-right angle. Maintain a calm and neutral expression - think poised and relaxed. Capture your natural, awesome self!
          </div>
        </div>
        <div className="howtomake2-content-item">
          <div className="howtomake2-content-item-image">
          <img src={howImg2} alt='Correct pose example' loading="lazy" />
          <img src={howImg22} alt='Incorrect pose example' loading="lazy" />
          </div>
          <div className="howtomake2-content-item-title">Lighting Lowdown: Shine Bright!</div>
          <div className="howtomake2-content-item-desc">
            Position yourself so you’re greeting the light, perhaps from an open window. No stress about the backdrop – it’s getting the snip, so let’s focus on illuminating your fabulous self!
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToMakeV2
