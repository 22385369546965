import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, AddressElement } from '@stripe/react-stripe-js';
import 'react-paypal-checkout-button/dist/index.css'
import MainContainer from '../../components/Basic/MainContainer';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Button from '../../components/Basic/Button';
import progress1Img from '../../assets/homepage/step/progress1.png';
import progress2Img from '../../assets/homepage/step/progress2.png';
import Dropdown from '../../components/Basic/Dropdown';
import CheckoutForm from '../../components/CheckoutForm';
import Paypal from '../../components/Paypal';
import InvoiceImg from '../../assets/icons/invoice.svg';
import tempImg from '../../assets/homepage/step/she.jpg';
import plusImg from '../../assets/homepage/step/plus.jpg';
import minusImg from '../../assets/homepage/step/minus.jpg';
import documentImg from '../../assets/homepage/document.jpg';
import imgMasterPay from '../../assets/homepage/payment/master.svg'
import imgVisaPay from '../../assets/homepage/payment/visaWhite.svg'
import imgGPay from '../../assets/homepage/payment/gPay.svg'
import imgAPay from '../../assets/homepage/payment/aPay.svg'
import ReactGA from "react-ga4";


import ARImg from '../../assets/icons/AR.svg';
import BOImg from '../../assets/icons/BO.svg';
import CLImg from '../../assets/icons/CL.svg';
// import DEImg from '../../assets/icons/DE.svg';
import ECImg from '../../assets/icons/EC.svg';
import GEImg from '../../assets/icons/GE.svg';
import INImg from '../../assets/icons/IN.svg';
import IEImg from '../../assets/icons/IE.svg';
import ITImg from '../../assets/icons/IT.svg';
import JPImg from '../../assets/icons/JP.svg';
import MXImg from '../../assets/icons/MX.svg';
import NLImg from '../../assets/icons/NL.svg';
import NZImg from '../../assets/icons/NZ.svg';
import NOImg from '../../assets/icons/NO.svg';
import PEImg from '../../assets/icons/PE.svg';
import PLImg from '../../assets/icons/PL.svg';
// import PYImg from '../../assets/icons/PY.svg';
import RUImg from '../../assets/icons/RU.svg';
import USAImg from '../../assets/icons/USA.svg';
import {
    countries,
    countries_size,
    size,
    size_pixel,
    stripe_publish_key,
    firebase_functions_url,
    firebase_functions_url_test,
    doc_type,
    session_id,
    order_digital,
    order_Printed,
    pay_status,
    price,
    documentList
} from '../../global/config';
import { DocTypes } from "../../global/docTypes.js"
import './style.scss'

const stripePromise = loadStripe(stripe_publish_key);

const Payment = ({
    isPay1
}) => {
    const flags = [
        ARImg,
        BOImg,
        null,
        CLImg,
        null,
        ECImg,
        GEImg,
        null,
        INImg,
        IEImg,
        ITImg,
        JPImg,
        MXImg,
        NLImg,
        NZImg,
        NOImg,
        null,
        PEImg,
        PLImg,
        RUImg,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,        
        USAImg,
        null,
    ];

    const subContent = [];
    for (let i = 0; i < countries.length; i++) {
        subContent[i] = size[countries_size[countries[i]]];
    }

    const processedPhoto = localStorage.getItem('photo');
    const processedData = localStorage.getItem('data');
    const basePrice = localStorage.getItem('count') || 0;
    
    const { prices } = useSelector((state) => state.price);

    const docType = localStorage.getItem(doc_type) || "USPassportPhoto";
    const sessionId = localStorage.getItem(session_id);

    const [isLoading, setLoading] = useState(false);
    const [isPay, setPay] = useState(false);
    const [isAlertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [orderId, setOrderId] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [deliveryType, setDeliveryType] = useState("");
    const [country, setCountry] = useState(countries[28]);
    const [doc, setDoc] = useState(size[countries_size[countries[28]]]);
    const [quantity, setQuantity] = useState(2);
    const [pricePrinted, setPricePrinted] = useState(basePrice);
    const [priceTotal, setPriceTotal] = useState(basePrice);
    const [totalPrice, setPrice] = useState(0);
    const [title, setTitle] = useState("Choose Delivery");
    const [subtitle, setSubtitle] = useState("Select delivery type & quantity for the photo");

    const bodyRef = useRef(null);
    const navigate = useNavigate(null);

    useEffect(() => {
        setPricePrinted(calculatePrintedPrice(prices.printed, quantity));
        //console.log("use effect updateTotalPrice :", priceTotal);

        if (isPay) {
            //bodyRef.current.style.flexDirection = "column";
            bodyRef.current.style.alignItems = "center";
            setTitle("Complete your order");
            setSubtitle("Please double-check your details.");
        } else {
            //bodyRef.current.style.flexDirection = "row";
            bodyRef.current.style.alignItems = "center";
            setTitle("Choose Delivery");
            setSubtitle("Select delivery type & quantity for the photo");
        }
    }, []);

    const appearance = {
        theme: 'flat',
        rules: {
            '.Input': {
                borderRadius: '4px',
            }
        }
    };

    const layout = {
        type: 'tabs',
        defaultCollapsed: false
    };

    const options = {
        clientSecret,
        appearance,
        //layout
    }

    const handleAlert = (msg) => {
        setAlertMsg(msg);
        setAlertShow(true);
        setTimeout(() => {
            setAlertShow(false);
        }, 5000)
    }

    const onChange = (type, value) => {
        if (type === 'country') {
            setCountry(value)
            setDoc(size[countries_size[value]])
        }
        if (type === 'doc') {
            setDoc(value)
        }
    }

    const updateTotalPrice = (deliveryType) => {
        if (deliveryType == order_digital)
        {
            setPriceTotal(prices.digital);
        }
        else if (deliveryType == order_Printed) 
        {
            var printedPrice = calculatePrintedPrice(prices.printed, quantity);
            setPriceTotal(printedPrice);
        }

        //console.log("updateTotalPrice :", priceTotal);
    }

    const calculatePrintedPrice = (basePrice, count) => {
        //console.log(`calculatePrintedPrice basePrice : ${a}`);
        return Math.round((Number(basePrice) + (Number(count) - 2) * prices.additional) * 100) / 100;
    }

    const onOptionDigitalSelectedChange = (value) => {
        setDeliveryType(value);
        setPriceTotal(prices.digital);
    }

    const onOptionPrintedSelectedChange = (value) => {
        setDeliveryType(value);

        var printedPrice = calculatePrintedPrice(prices.printed, quantity);

        setPricePrinted(printedPrice);
        setPriceTotal(printedPrice);
    }

    const plusPhotos = () => {
        if (quantity < 7)
        {
            updatePrice(quantity + 2);
        }
    }

    const minusPhotos = () => {
        if (quantity > 3)
        {
            updatePrice(quantity - 2);
        }              
    }

    const updatePrice = (value) => {
        setQuantity(value); 

        var printedPrice = calculatePrintedPrice(prices.printed, value);

        setPricePrinted(printedPrice);
        
        if (deliveryType == order_Printed)
        {
            setPriceTotal(printedPrice);
        }
    }

    const changeQuantity = (flag) => {
        if (flag) {
            setQuantity(quantity + 1);
        } else {
            if (quantity !== 1) {
                setQuantity(quantity - 1);
            }
        }
    }

    const finishPayment = (payInfo) => {
        payInfo.sessionId = sessionId;

        localStorage.setItem(pay_status, "paid");

        //console.log('updatePaymentId req : ', req);

        fetch(`${firebase_functions_url}/order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payInfo)
        })

        let options1 = {
            currency: "USD",
            value: priceTotal,
            transaction_id: payInfo.paymentId,
            items: [{item_name: orderDescription()}]
        }

        ReactGA.event("purchase", options1);
    }

    const orderDescription = () => {
        return `${deliveryType == order_Printed ? quantity + ' copies of ' : ''}${DocTypes.findByType(docType).textDescription}, ${deliveryType}`;
    }

    const createOrder = (req) => new Promise((resolve, reject) => {
        req.sessionId = sessionId;
        req.total = Math.round(priceTotal * 100);
        req.docType = docType;
        req.deliveryType = deliveryType;
        req.qty = quantity;
        req.operationType = "create";
        let descr = orderDescription();
        req.orderDescription = descr;

        //console.log('createOrder req : ', req);

        fetch(`${firebase_functions_url}/order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(req)
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.statusCode) {
                handleAlert(data.param + " is invalid.");
            } else {
                //console.log('handleNext data : ', data);
                setPaymentId(data.payIntentId);
                
                if (req.payType == 'stripe')
                {
                    setClientSecret(data.clientSecret);
                }

                if (data.orderId)
                {
                    setOrderId(data.orderId);
                    localStorage.setItem('orderId', data.orderId);
                    
                    resolve(data.orderId);
                }
            }
        })
        .catch(error => 
        {
            console.error(`err `, error);
            //need tp handle error message
            reject(error);
        });   

        //console.log(`fetch orderId : ${orderId}`, orderId);
        let options = {
            currency: "USD",
            value: priceTotal,
            items: [{item_name: descr}]
        }

        ReactGA.event("add_to_cart", options);
    });

    const orderReqquest = (req) => new Promise((resolve, reject) => {
        //console.log('stripeReqquest req : ', req);
        fetch(`${firebase_functions_url}/order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(req)
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.statusCode) {
                handleAlert(data.param + " is invalid.");
            } else {
                //console.log('handleNext data : ', data);
                setPaymentId(data.payIntentId);
                setClientSecret(data.clientSecret);
                
                if (data.orderId)
                {
                    setOrderId(data.orderId);
                    localStorage.setItem('orderId', data.orderId);

                    resolve(data.orderId);
                }
            }
        })
        .catch(error => 
        {
            console.error(`err `, error);
            handleAlert("error, try again")
            reject(error);
        });   

        //console.log(`fetch orderId : ${orderId}`, orderId);
    });

    const stripePayClick = async () => {
        if (!isLoading)
        {
            setLoading(true);
            var photoOrderId = await createOrder({
                payType: 'stripe'
            });
            setLoading(false);
        }
    }

    const handleNext = () => {
        if (deliveryType != '')
        {
            //console.log("type :", deliveryType);
            //console.log("TotalPrice :", priceTotal);
            //console.log("Price printed :", pricePrinted);

            updateTotalPrice(deliveryType);

            if(clientSecret != "")
            {
                setClientSecret("");
            }

            bodyRef.current.style.flexDirection = "column";
            bodyRef.current.style.alignItems = "center";
            setTitle("Complete your order");
            setSubtitle("Please double-check your details.");
            localStorage.setItem('quantity', quantity);
            localStorage.setItem('country', country);
            setPay(true);
            //navigate("/payment-pay");
        }
    }

    return (
        <MainContainer>
            {/* <Header /> */}
            <div className='payment'>
                <div className={`payment-alert ${isAlertShow && 'active'}`}>
                    {alertMsg}
                </div>

                {/* <div className='payment-progress'>
                    <img src={!isPay ? progress1Img : progress2Img} />
                </div> */}
                <div className='payment-header'>
                    <div className='payment-header-title'>
                        {title}
                    </div>
                    <div className='payment-header-subtitle'>
                        {subtitle}
                    </div>
                </div>
                <div className='payment-body' ref={bodyRef}>
                    {!isPay ?
                        <>
                            <div className='payment-body-detail'>
                                <div className='payment-body-detail-photos'>
                                    <div className={`payment-body-detail-photo ${(!deliveryType || deliveryType == order_digital ) && 'showPhoto'}`}>
                                    <div className='payment-body-detail-photo-right'>
                                            <img src={processedPhoto} />
                                        </div>
                                    </div>
                                    <div className={`payment-body-detail-photo ${deliveryType == order_Printed  && 'showPhoto'}`}>
                                        <div className='payment-body-detail-photo-left'>
                                            <img src={processedPhoto} />
                                        </div>
                                        <div className='payment-body-detail-photo-right'>
                                            <img src={processedPhoto} />
                                        </div>
                                    </div>
                                    <div className={`payment-body-detail-photo ${deliveryType == order_Printed && quantity > 2 && 'showPhoto'}`}>
                                    <div className='payment-body-detail-photo-left'>
                                            <img src={processedPhoto} />
                                        </div>
                                        <div className='payment-body-detail-photo-right'>
                                            <img src={processedPhoto} />
                                        </div>
                                    </div>
                                    <div className={`payment-body-detail-photo ${deliveryType == order_Printed && quantity > 4 && 'showPhoto'}`}>
                                    <div className='payment-body-detail-photo-left'>
                                            <img src={processedPhoto} />
                                        </div>
                                        <div className='payment-body-detail-photo-right'>
                                            <img src={processedPhoto} />
                                        </div>
                                    </div>
                                    <div className={`payment-body-detail-photo ${deliveryType == order_Printed && quantity > 6 && 'showPhoto'}`}>
                                    <div className='payment-body-detail-photo-left'>
                                            <img src={processedPhoto} />
                                        </div>
                                        <div className='payment-body-detail-photo-right'>
                                            <img src={processedPhoto} />
                                        </div>
                                    </div>
                                </div>
                                <div className='payment-body-detail-options'>
                                    <div className='payment-body-detail-options-title'>
                                        {DocTypes.findByType(docType).textDescription}
                                    </div>
                                    <div className='payment-body-detail-options-item'>
                                            <label>
                                                <input 
                                                    type="radio" 
                                                    name="DeliveryType" 
                                                    value={order_digital} 
                                                    onChange={ (e) => { onOptionDigitalSelectedChange(e.target.value); }}
                                                    checked={deliveryType == order_digital}
                                                    >
                                                </input>
                                                {/* <i class="a-icon"></i> */}
                                                <span className='payment-body-detail-options-item-label'>
                                                    <span className='payment-body-detail-options-item-label-type'>
                                                        Digital
                                                    </span>
                                                    <br/>
                                                    <span className='payment-body-detail-options-item-label-price'>${prices.digital}</span>
                                                </span>
                                            </label>
                                    </div>
                                    <div className='payment-body-detail-options-item'>
                                            <label>
                                                <input 
                                                    type="radio" 
                                                    name="DeliveryType" 
                                                    value={order_Printed}
                                                    onChange={ (e) => { onOptionPrintedSelectedChange(e.target.value); }}
                                                    checked={deliveryType == order_Printed}
                                                    >
                                                </input>
                                                {/* <i class="a-icon"></i> */}
                                                <span className='payment-body-detail-options-item-label'>
                                                    <span className='payment-body-detail-options-item-label-type'>
                                                        Printed + delivered by mail
                                                    </span>
                                                    <br/>
                                                    <span className='payment-body-detail-options-item-label-middle'>
                                                        
                                                        <div onClick={minusPhotos} className='payment-body-detail-options-item-label-middle-button'>
                                                            -
                                                        </div>
                                                        <div className='payment-body-detail-options-item-label-middle-quantity'>
                                                            {quantity}
                                                        </div>
                                                        <div onClick={plusPhotos} className='payment-body-detail-options-item-label-middle-button'>
                                                            +
                                                        </div>
                                                    </span>
                                                    <span className='payment-body-detail-options-item-label-price'>${pricePrinted == 0 ? prices.printed : pricePrinted}</span>
                                                </span>
                                            </label>
                                    </div>
                                </div>
                            </div>
                                <Button
                                    title="Proceed to Payment"
                                    classNames={`payment-body-right-button ${deliveryType != '' && 'active'}`}
                                    onClick={handleNext}
                                />
                        </>
                        : <>
                            <div className='payment-body-detail'>
                                <img src={processedPhoto} />
                                <div className='payment-body-detail-options'>
                                    <div className='payment-body-detail-options-item'>
                                            <label>
                                                <span className='payment-body-detail-options-item-label'>
                                                    <span className='payment-body-detail-options-item-label-type'>
                                                    {DocTypes.findByType(docType).textDescription}
                                                    </span>
                                                    <br/>
                                                    <span className='payment-body-detail-options-item-label-middle readOnly'>{deliveryType == order_Printed ? quantity + ' x ' : ''}{deliveryType}</span>
                                                    <span className='payment-body-detail-options-item-label-price'>${priceTotal}</span>
                                                </span>
                                            </label>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-paywrap">
                                <div className="payment-paywrap-title">
                                    Express checkout with:
                                </div>
                                <div>
                                    <Paypal
                                        price={priceTotal}
                                        description={orderDescription()}
                                        orderType = {deliveryType}
                                        handleAlert={(msg) => {
                                            handleAlert(msg)
                                        }}
                                        createPhotoOrder={async (req) => {
                                            return await createOrder(req)
                                        }}
                                        updatePaymentId={(req) => {
                                            finishPayment(req);
                                        }}
                                    />
                                </div>
                                <div className="payment-paywrap-divider">
                                    -- OR --
                                </div>
                                {clientSecret ?
                                    <div className="payment-stripewrap">
                                        <Elements options={options} stripe={stripePromise}>
                                            {deliveryType == order_Printed && <AddressElement options={{mode: 'shipping'}} />}
                                            <CheckoutForm
                                                count={priceTotal}
                                                clientSecret={clientSecret}
                                                handleAlert={(msg) => {
                                                    handleAlert(msg)
                                                }}
                                                updatePaymentId={(req) => {
                                                    finishPayment(req);
                                                }}
                                            />
                                        </Elements>
                                    </div>
                                :
                                <div className="payment-paywrap-cardButton" onClick={stripePayClick}>
                                    <img src={imgAPay} title="Apple Pay" className={`${isLoading && 'stripeLoading'}`}/> 
                                    <img src={imgGPay} title="Google Pay" className={`${isLoading && 'stripeLoading'}`}/> 
                                    <img src={imgVisaPay} title="Visa" className={`${isLoading && 'stripeLoading'}`}/> 
                                    <img src={imgMasterPay} title="Master Card" className={`${isLoading && 'stripeLoading'}`}/> 
                                </div>
                                }
                            </div>
                        </>
                    }

                    </div>
            </div>
            {/* <Footer /> */}
        </MainContainer>
    )
}

export default Payment;
