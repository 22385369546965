import { useState, useEffect } from 'react'
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Reference from '../../components/Basic/Reference';
import PopularDocuments from '../../components/PopularDocuments';
import reference1Img from '../../assets/homepage/passport/1.jpg';
import reference2Img from '../../assets/homepage/passport/3.jpg';
import reference3Img from '../../assets/homepage/referenece/1.png';
import reference4Img from '../../assets/homepage/referenece/2.png';
import reference5Img from '../../assets/homepage/referenece/3.png';
import reference6Img from '../../assets/homepage/referenece/4.png';
import reference7Img from '../../assets/homepage/referenece/5.png';
import reference8Img from '../../assets/homepage/referenece/1.png';

import {
    reference1_title,
    reference1_content,
    reference2_title,
    reference2_content,
    reference3_title,
    reference3_content,
    reference4_title,
    reference4_content,
    reference5_title,
    reference5_content,
    reference6_title,
    reference6_content,
    reference7_title,
    reference7_content,
    reference8_title,
    reference8_content,
} from '../../global/config';
import './style.scss';

function ReferencePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <>
            {/* <Header /> */}
            <div className='reference-page'>
                <div className='reference-page-body'>
                    <div className='reference-page-body-up'>
                        <div className='reference-page-body-up-title'>
                            Get a passport for reference
                        </div>
                        <div className='reference-page-body-up-content'>
                            Here you can find the documents to familiarize yourself with the requirements. Popular documents in the world
                        </div>
                    </div>
                    <div className='reference-page-body-down'>
                        <Reference
                            img={reference1Img}
                            title={reference1_title}
                            content={reference1_content}
                            type='LTR'
                        />
                        <Reference
                            img={reference2Img}
                            title={reference2_title}
                            content={reference2_content}
                            type='RTL'
                        />
                        <Reference
                            img={reference3Img}
                            title={reference3_title}
                            content={reference3_content}
                            type='LTR'
                        />
                        <Reference
                            img={reference4Img}
                            title={reference4_title}
                            content={reference4_content}
                            type='RTL'
                        />
                        <Reference
                            img={reference5Img}
                            title={reference5_title}
                            content={reference5_content}
                            type='LTR'
                        />
                        <Reference
                            img={reference6Img}
                            title={reference6_title}
                            content={reference6_content}
                            type='RTL'
                        />
                        <Reference
                            img={reference7Img}
                            title={reference7_title}
                            content={reference7_content}
                            type='LTR'
                        />
                        <Reference
                            img={reference8Img}
                            title={reference8_title}
                            content={reference8_content}
                            type='RTL'
                        />
                    </div>
                </div>
                <div className='reference-page-footer'>
                    <PopularDocuments />
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default ReferencePage;