import { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
//import Smartlook from 'react-native-smartlook-analytics'
import * as faceapi from "face-api.js"
import { v4 as uuidv4 } from "uuid"
import { Buffer } from "buffer"
import { backendUtils } from '../../global/backendUtils.js';
import Resizer from "react-image-file-resizer"
import MainContainer from '../../components/Basic/MainContainer';
import CornerVideo from '../../components/Basic/CornerVideo';
import Hero from "../../components/Hero"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HowItWorks from "../../components/HowItWorks"
import Drag from "../../components/Drag"
import HowToMake from "../../components/HowToMake"
import HowToMakeV2 from "../../components/HowToMakeV2"
import GetPassport from "../../components/GetPassport"
import PopularDocuments from "../../components/PopularDocuments"
import Reviews from "../../components/Reviews"
import QandAsection from "../../components/QandAsection"
//import Download from '../../components/Download';
import Info from "../../components/Info"
import ReadOurBlog from "../../components/ReadOurBlog"
import Button from "../../components/Basic/Button"
import MainDialog from "../../components/Basic/MainDialog"
import closeImg from "../../assets/icons/close.svg"
import placeholderImg from "../../assets/homepage/step/placeholder.png"
import checkImg from "../../assets/homepage/step/check1.svg"
import sparkleImg from "../../assets/homepage/step/sparkle.svg"
import cameraImg from "../../assets/homepage/step/camera.svg"
import vector1Img from "../../assets/homepage/step/vector1.svg"
import vector2Img from "../../assets/homepage/step/vector2.svg"
import xImg from "../../assets/homepage/step/x.png"
import {
  setStepProcessedPhoto,
  setStepProcessedData,
} from "../../redux/actions/stepAction"
import {
  firebase_functions_url_test,
  firebase_functions_url,
  countries_size,
  size_pixel,
  watermark_text,
  quotes,
  session_id,
  parent_session_id,
  pay_status
} from "../../global/config.js"
import { Errors } from "../../global/errors.js"
import { CheckVisibility } from '../../global/Utils.js';
import { ImgProcessing } from '../../global/imgProcessing.js';

import "./style.scss"

function Dashboard() {
  const [dragActive, setDragActive] = useState(false)
  const [isAlertShow, setAlertShow] = useState(false)
  const [alertMsg, setAlertMsg] = useState(false)
  const [isDialogShow, setDialogShow] = useState(false)
  const [imgNaturalWidth, setImgNaturalWidth] = useState(null)
  const [imgNaturalHeight, setImgNaturalHeight] = useState(null)
  const [isProcessed, setIsProcessed] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [adjsutImg, changeAdjustImg] = useState(checkImg)
  const [recogImg, changeRecogImg] = useState(checkImg)
  const [title, changeTitle] = useState("")
  const [subtitle1, changeSubtitle1] = useState("")
  const [subtitle2, changeSubtitle2] = useState("")
  const [quote, changeQuote] = useState("")
  const [isDraggable, changeIsDraggable] = useState(true)
  const [isCriteria, changeIsCriteria] = useState(true)
  const [errorImgSrc, setErrorImg] = useState("")
  const [errorLink, setErrorLink] = useState("")
  const [isConfirmed, setConfirmed] = useState(false)
  const [isRequiredError, setRequiredError] = useState(false)
  // const [isVideoVisible, setVideoVisible] = useState(false)


  const [isUploadBtnVisible1, setUploadBtnVisibility1] = useState(true)
  const [isUploadBtnVisible2, setUploadBtnVisibility2] = useState(true)

  var errorUrl = `/error#${errorLink}`
  var quoteIntervalId = 0

  const dlgContentRef = useRef(null)
  const imageBorderRef = useRef(null)
  const checkRef = useRef(null)
  //const errorImg = useRef();
  //const errorImgSrc = useRef(null);

  const canvasRef = useRef(null)
  const canvasFaceRef = useRef(null)
  const placeholderRef = useRef(null)
  const analyzeResultRef = useRef(null)
  const dashboardRef = useRef(null)
  const fileUploadRef = useRef(null)
  const dashboardBttmRef = useRef(null)
  const minResolution = 600
  const maxResolution = 1100

  const { processedPhoto, processedData, country } = useSelector(
    (state) => state.step
  )
  //  const { prices } = useSelector((state) => state.price)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)

    // if (window.location.pathname == '/test') {
    //   setVideoVisible(true)
    // }

    //console.log("Loading...");

    setInterval(showQuotes, 4000)

    const handleScroll = (e) => {
      setUploadBtnVisibility2(CheckVisibility(dashboardBttmRef, 100))
    }
  }, [])

  const handleAlert = (msg) => {
    setAlertMsg(msg)
    setAlertShow(true)
    setTimeout(() => {
      setAlertShow(false)
    }, 3000)
  }

  const handleFileUpload = (e) => {
    fileUploadRef.current.click()
    setIsFailed(false)
    setDialogShow(false)
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsFailed(false)
    setDialogShow(false)

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave" && e.pageX === 0 && e.pageY === 0) {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // alert()

    setDragActive(false)

    const files = e.dataTransfer.files
    if (files) {
      if (files.length > 1) {
        handleAlert("Please drop only one photo")
      } else {
        const f = files[0]
        processPhoto(f, true)
      }
    }
  }

  const processPhoto = async (f, flag) => {
    if (
      f.type.split("/")[0] === "image" &&
      (f.type.split("/")[1] === "jpeg" || f.type.split("/")[1] === "png")
    ) {
      if (f.size < 10485760) {
        if (flag) {
          handleDlg()
        }

        const ctx = canvasRef.current.getContext("2d")
        const img = new Image()
        img.src = URL.createObjectURL(f)
        img.onload = () => {
          const minPixels = 600
          //const minPixels = 200;
          if (img.width >= minPixels && img.height >= minPixels) {
            const aspectRatio = img.width / img.height
            canvasRef.current.height = canvasRef.current.width
            if (aspectRatio > 1) {
              const newWidth = canvasRef.current.width
              const newHeight = newWidth / aspectRatio
              ctx.drawImage(
                img,
                0,
                parseInt((newWidth - newHeight) / 2),
                newWidth,
                newHeight
              )
            } else {
              const newHeight = canvasRef.current.width
              const newWidth = newHeight * aspectRatio
              ctx.drawImage(
                img,
                parseInt((newHeight - newWidth) / 2),
                0,
                newWidth,
                newHeight
              )
            }

            changeTitle("We’re making progress! 😎")
            changeSubtitle1("")
            changeSubtitle2("")
            setIsProcessed(false)
            showQuotes()

            checkRef.current.style.display = "flex"

            changeIsDraggable(false)

            //resizeUploadedPicture(f, img.width, img.height);
            processImg(f, img.src, img, img.width, img.height)
          } else {
            showTypeError(Errors.LowResolution);
            backendUtils.reportError(getSessionId(), Errors.LowResolution, f, img, null, localStorage.getItem(parent_session_id));
          }
        }
      } else {
        handleAlert("Please choose photo less than 10MB")
      }
    } else {
      handleDlg();
      showTypeError(Errors.UnsupportedFileFormat);
      backendUtils.reportError(getSessionId(), Errors.UnsupportedFileFormat, f, null, null, localStorage.getItem(parent_session_id));
    }
  }

  function stopQuotes() {
    // clearInterval(quoteIntervalId)
    // showQuotes(" ")
  }

  function showTypeError(error) {
    stopQuotes()
    changeTitle(error.text)
    changeSubtitle1(error.quickFix)
    // changeSubtitle2(error.text)
    setIsFailed(true)

    setErrorImg(error.media)
    setErrorLink(error.link)

    changeIsDraggable(true)
  }
  const doNothing = (e) => {
    if (e != undefined) {
      e.stopPropagation()
    }
  }

  const handleDlg = () => {
    setDialogShow(!isDialogShow)
    if (isDialogShow) {
      changeIsDraggable(true)
    }
  }

  async function processImg(
    file,
    naturalUri,
    origImg,
    imgNaturalWidth,
    imgNaturalHeight
  ) {
    //console.log("file", file);

    const startDate = new Date()
    var frontEndLog = getFrontEndLog(
      file,
      startDate,
      imgNaturalWidth,
      imgNaturalHeight
    )

    const blobOrigImg = new Blob([file], { type: "image/jpeg" })

    var resizedUri = await ImgProcessing.resizeFile(
      blobOrigImg,
      imgNaturalWidth,
      imgNaturalHeight,
      maxResolution
    )

    fileUploadRef.current.value = null

    frontEndLog = updateResizeTimeFrontEndLog(frontEndLog, startDate)
    await loadModels1()

    //quoteIntervalId = setInterval(showQuotes, 4000)

    let resizedImg = new Image()
    resizedImg.src = resizedUri

    const detections = await detectFace(resizedImg)

    frontEndLog.detectionModel = detections.detectionModel

    frontEndLog = updateFaceRecognitionFrontEndLog(frontEndLog)

    if (!detections.face) {
      backendUtils.reportError(getSessionId(), Errors.NoFaceDetected, file, origImg, resizedUri, localStorage.getItem(parent_session_id));
      showTypeError(Errors.NoFaceDetected)
    } else {
      const box = ImgProcessing.getMainProperties(
        detections,
        resizedImg,
        imgNaturalWidth,
        imgNaturalHeight
      )

      var lightArr = ImgProcessing.getLightArr(resizedImg, box)

      if (!lightArr) {
        backendUtils.reportError(getSessionId(), Errors.Error, null, null, null, localStorage.getItem(parent_session_id));
        return showTypeError(Errors.Error)
      }



      // testLightCheck(resizedImg, box, cutLines, frontEndLog)
      // return;



      var cutLines = await backendUtils.getCutLines(
        box,
        detections.detectionModel,
        frontEndLog.session_id,
        minResolution,
        maxResolution,
        lightArr.lightValueArr,
        lightArr.sum,
        file.name,
        resizedUri,
        localStorage.getItem(parent_session_id)
      )

      if (cutLines && !cutLines.isError) {
        resizedUri = await ImgProcessing.resizeAndCrop(
          cutLines,
          resizedUri,
          origImg,
          blobOrigImg,
          imgNaturalWidth,
          imgNaturalHeight
        )

        //console.log("Checking: ", cutLines.width)
        localStorage.setItem("imgWidth", cutLines.width)
        localStorage.setItem("imgHeight", cutLines.height)

        // getResultAndDrawTest(resizedUri, box, cutLines, frontEndLog, detections)

        backendUtils.removeBackground(
          resizedUri,
          detections.face,
          cutLines,
          box,
          frontEndLog,
          drawTheResponce,
          showTypeError,
          lightArr.top,
          localStorage.getItem(parent_session_id)
        )
      } else {
        console.error(`err `, cutLines.error)
        showTypeError(cutLines.error)
      }
    }
  }

  const getSessionId = () => {
    let sessionId = localStorage.getItem(session_id);
    let isPaid = localStorage.getItem(pay_status) == "paid";
    //console.log(`isPaid: ${isPaid}`);

    if (isPaid) {
      localStorage.removeItem(pay_status);

      let parentSessionId = localStorage.getItem(parent_session_id);

      if (!parentSessionId && sessionId) {
        localStorage.setItem(parent_session_id, sessionId)
      }
    }

    if (!sessionId || isPaid) {
      sessionId = uuidv4();
      localStorage.setItem(session_id, sessionId)
    }

    // console.log(`getSessionId: ${sessionId}`);
    return sessionId;
  }

  function getFrontEndLog(file, startDate, imgNaturalWidth, imgNaturalHeight) {
    const sessionId = getSessionId();

    return {
      startTime: new Intl.DateTimeFormat("en-US", {
        timeZone: "America/New_York",
        dateStyle: "short",
        timeStyle: "long",
      })
        .format(startDate)
        .toLocaleString(),
      session_id: sessionId,
      file_name: file.name,
      dimension: `width: ${imgNaturalWidth}, height: ${imgNaturalHeight}`,
      size: `${parseInt((file.size / 1024 / 1024) * 100) / 100} MB`,
      aResizingTime: "0",
      aFaceDetectionTime: "0",
      status: "Frontend report",
      detectionModel: "",
      resizeEndTime: "",
      userAgent: navigator.userAgent,
    }
  }

  function updateResizeTimeFrontEndLog(frontEndLog, startDate) {
    frontEndLog.resizeEndTime = new Date()
    frontEndLog.aResizingTime = `${frontEndLog.resizeEndTime - startDate.getTime()
      } ms`
    return frontEndLog
  }

  function updateFaceRecognitionFrontEndLog(frontEndLog) {
    const faceDetectionEndTime = new Date()
    const resizeEndTime = new Date(frontEndLog.resizeEndTime)

    frontEndLog.aFaceDetectionTime = `${faceDetectionEndTime.getTime() - resizeEndTime.getTime()
      } ms`

    return frontEndLog
  }

  const showQuotes = async (text) => {
    //console.log("quote text ", text);
    if (!text) {
      var i = Math.floor(Math.random() * quotes.length)
      if (!isProcessed && !isFailed) {
        changeQuote(quotes[i])
      } else {
        changeQuote("")
      }
    } else {
      changeQuote(text)
    }
  }

  const loadModels1 = async () => {
    let promise = await Promise.all([
      faceapi.nets.tinyFaceDetector.load("/models"),
      faceapi.nets.ssdMobilenetv1.load("/models"),
      faceapi.nets.faceLandmark68TinyNet.load("/models"),
    ])
  }

  const loadModels = async (
    frontEndLog,
    uri,
    blobOrigImage,
    imgNaturalWidth,
    imgNaturalHeight
  ) => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.load("/models"),
      faceapi.nets.ssdMobilenetv1.load("/models"),
      //faceapi.nets.faceLandmark68Net.load("/models"),
      //faceapi.nets.faceRecognitionNet.load("/models"),
      //faceapi.nets.faceExpressionNet.load("/models")
    ])
    // .then(() => handleImageClick(frontEndLog, uri, blobOrigImage, imgNaturalWidth, imgNaturalHeight))
    // .catch((e) => console.error(e));
  }

  function testLightCheck(uri, detections, cutLines, frontEndLog) {
    // testing by displaying unchanged picture with a rectangle around recofnized face
    console.log(`img dimensions : ${frontEndLog.dimension}`)
    // console.log(
    //   `img detected box top: ${Math.round(cutLines.faceTop)}, bottom: ${
    //     cutLines.faceBottom
    //   }, left: ${cutLines.faceLeft}, right: ${cutLines.faceRight}`
    // )

    let shrink = 0.07;
    var faceLeft = detections.ffLeft
    var faceWidth = detections.ffRight - detections.ffLeft
    //var faceHeight = Math.floor((detections.bottom - detections.top) * 0.85)
    var faceHeight = Math.floor(detections.ffBottom - detections.ffTop)
    faceLeft += Math.floor(faceWidth * shrink)
    var faceRight = Math.floor(detections.ffRight - faceWidth * shrink)
    //var faceRight = detections.ffRight
    faceWidth = faceRight - faceLeft

    console.log(
      `img detected box top: ${Math.round(detections.top)}, bottom: ${detections.bottom
      }, left: ${detections.left}, right: ${detections.right}`
    )

    const ctx = canvasRef.current.getContext("2d")
    canvasRef.current.height = faceHeight
    canvasRef.current.width = faceWidth

    ctx.drawImage(
      uri,
      faceLeft,
      detections.ffTop,
      faceWidth,
      faceHeight,
      0,
      0,
      faceWidth,
      faceHeight
    )

    var faceData = ctx.getImageData(0, 0, faceWidth, faceHeight)

    // let img = new Image()
    // img.src = uri
    // img.onload = () => {
    //   console.log(`img size : w ${img.width}, h ${img.height}`)

    //   const aspectRatio = img.width / img.height
    //   const newWidth = canvasRef.current.width
    //   const newHeight = canvasRef.current.width / aspectRatio
    //   canvasRef.current.height = newHeight
    //   ctx.drawImage(img, 0, 0, newWidth, newHeight)

    //   const enlarge = newWidth / img.width
    //   console.log(`enlarge : w ${enlarge}`)

    //   const top = Math.round((detections.top) * enlarge)
    //   const bottom = Math.round((detections.bottom) * enlarge)
    //   const left = Math.round((detections.left) * enlarge)
    //   const right = Math.round((detections.right) * enlarge)

    //   // drawing green rectangle for recognized face
    //   ctx.beginPath()
    //   ctx.strokeStyle = "#00FF00"
    //   ctx.moveTo(left, top)
    //   ctx.lineTo(right, top)

    //   ctx.moveTo(left, bottom)
    //   ctx.lineTo(right, bottom)
    //   ctx.moveTo(left, top)
    //   ctx.lineTo(left, bottom)
    //   ctx.moveTo(right, top)
    //   ctx.lineTo(right, bottom)
    //   ctx.stroke()

    dispatch(setStepProcessedPhoto(canvasRef.current.toDataURL()))
    localStorage.setItem("photo", canvasRef.current.toDataURL())
    dispatch(setStepProcessedData(uri))
    localStorage.setItem("data", uri)
    changeTitle("Compatibility test passed! 👍")
    //changeSubtitle1("We recommend the photo be at least 1500 pixels.");
    //changeSubtitle2("We support JPG/PNG files.");

    setIsProcessed(true)

    // if (window.screen.width > 500) {
    //   dlgContentRef.current.style.flexDirection = "row"
    //   dlgContentRef.current.style.gap = "3.64vw"
    //   dlgContentRef.current.style.alignItems = "start"
    // }

    // dlgContentRef.current.style.position = "initial"
    checkRef.current.style.display = "none"
    changeIsDraggable(true)
    // }
  }


  function getResultAndDrawTest(uri, box, cutLines, frontEndLog, detections) {
    // testing by displaying unchanged picture with a rectangle around recofnized face
    console.log(`img dimensions : ${frontEndLog.dimension}`)
    console.log(
      `img detected box top: ${Math.round(cutLines.faceTop)}, bottom: ${cutLines.faceBottom
      }, left: ${cutLines.faceLeft}, right: ${cutLines.faceRight}`
    )

    const ctx = canvasRef.current.getContext("2d")
    let img = new Image()
    img.src = uri
    img.onload = () => {
      console.log(`img size : w ${img.width}, h ${img.height}`)

      const aspectRatio = img.width / img.height
      const newWidth = canvasRef.current.width
      const newHeight = canvasRef.current.width / aspectRatio
      canvasRef.current.height = newHeight
      ctx.drawImage(img, 0, 0, newWidth, newHeight)

      const enlarge = newWidth / img.width

      if (detections && detections.face && detections.face.landmarks && detections.face.landmarks.positions) {
        let lmPositions = detections.face.landmarks.positions;
        ctx.strokeStyle = "#0000FF"

        lmPositions.forEach(function (pos) {
          ctx.fillRect(Math.round((pos.x - cutLines.left) * enlarge), Math.round((pos.y - cutLines.top) * enlarge), 2, 2);
        })
      }


      let fLeft = Math.round((box.ffLeft - cutLines.left) * enlarge);
      let fRight = Math.round((box.ffRight - cutLines.left) * enlarge);
      let fTop = Math.round((box.ffTop - cutLines.top) * enlarge);
      let fBottom = Math.round((box.ffBottom - cutLines.top) * enlarge);

      ctx.beginPath()
      ctx.strokeStyle = "#00F0FF"
      ctx.moveTo(fLeft, fTop)
      ctx.lineTo(fRight, fTop)
      ctx.moveTo(fLeft, fBottom)
      ctx.lineTo(fRight, fBottom)
      ctx.moveTo(fLeft, fTop)
      ctx.lineTo(fLeft, fBottom)
      ctx.moveTo(fRight, fTop)
      ctx.lineTo(fRight, fBottom)
      ctx.stroke()


      //console.log(`newWidth : ${newWidth}, newHeight : ${newHeight}`);

      //testBoxCalculation(box);

      // drawing cut lines to crop
      // ctx.beginPath();
      // ctx.strokeStyle = '#0F00F0';
      // ctx.moveTo(0, cutLines.top * enlarge);
      // ctx.lineTo(200, cutLines.top * enlarge);
      // ctx.moveTo(0, cutLines.bottom * enlarge);
      // ctx.lineTo(200, cutLines.bottom * enlarge);
      // ctx.moveTo(cutLines.left * enlarge, 0);
      // ctx.lineTo(cutLines.left * enlarge, 200);
      // ctx.moveTo(cutLines.right * enlarge, 0);
      // ctx.lineTo(cutLines.right * enlarge, 200);
      // ctx.stroke();

      //eyes compliance field
      // ctx.beginPath();
      // ctx.strokeStyle = '#FF0000';
      // ctx.moveTo(0, (cutLines.height * 0.31 + cutLines.top) * enlarge);
      // ctx.lineTo(200, (cutLines.height * 0.31 + cutLines.top) * enlarge);
      // ctx.moveTo(0, (cutLines.height * 0.44 + cutLines.top) * enlarge);
      // ctx.lineTo(200, (cutLines.height * 0.44 + cutLines.top) * enlarge);
      // ctx.stroke();
      ctx.beginPath()
      ctx.strokeStyle = "#FF0000"
      ctx.moveTo(0, img.height * 0.31 * enlarge)
      ctx.lineTo(200, img.height * 0.31 * enlarge)
      ctx.moveTo(0, img.height * 0.44 * enlarge)
      ctx.lineTo(200, img.height * 0.44 * enlarge)
      ctx.stroke()

      // required head size
      // ctx.beginPath();
      // ctx.strokeStyle = '#FFFF00';
      // ctx.moveTo(0, (cutLines.height * 0.1 + cutLines.top) * enlarge);
      // ctx.lineTo(200, (cutLines.height * 0.1 + cutLines.top) * enlarge);
      // ctx.moveTo(0, (cutLines.height * 0.79 + cutLines.top) * enlarge);
      // ctx.lineTo(200, (cutLines.height * 0.79 + cutLines.top) * enlarge);
      // ctx.stroke();
      ctx.beginPath()
      ctx.strokeStyle = "#FFFF00"
      ctx.moveTo(0, img.height * 0.1 * enlarge)
      ctx.lineTo(200, img.height * 0.1 * enlarge)
      ctx.moveTo(0, img.height * 0.79 * enlarge)
      ctx.lineTo(200, img.height * 0.79 * enlarge)
      ctx.stroke()

      const top = Math.round((cutLines.faceTop - cutLines.top) * enlarge)
      const bottom = Math.round((cutLines.faceBottom - cutLines.top) * enlarge)
      const left = Math.round((cutLines.faceLeft - cutLines.left) * enlarge)
      const right = Math.round((cutLines.faceRight - cutLines.left) * enlarge)

      // drawing green rectangle for recognized face
      ctx.beginPath()
      ctx.strokeStyle = "#00FF00"
      ctx.moveTo(left, top)
      ctx.lineTo(right, top)
      ctx.moveTo(left, bottom)
      ctx.lineTo(right, bottom)
      ctx.moveTo(left, top)
      ctx.lineTo(left, bottom)
      ctx.moveTo(right, top)
      ctx.lineTo(right, bottom)
      ctx.stroke()

      dispatch(setStepProcessedPhoto(canvasRef.current.toDataURL()))
      localStorage.setItem("photo", canvasRef.current.toDataURL())
      dispatch(setStepProcessedData(uri))
      localStorage.setItem("data", uri)
      changeTitle("Compatibility test passed! 👍")

      setIsProcessed(true)

      checkRef.current.style.display = "none"
      changeIsDraggable(true)
    }
  }

  function drawTheResponce(dataImgResponce, detections, cutLines) {
    stopQuotes()
    //clearInterval(quoteIntervalId);
    let base64Image = Buffer.from(dataImgResponce).toString("base64")
    const processedPhoto = `data:image/jpeg;base64,${base64Image}`
    const ctx = canvasRef.current.getContext("2d")
    let img = new Image()
    img.src = processedPhoto
    img.onload = () => {
      const aspectRatio = img.width / img.height
      const newWidth = canvasRef.current.width
      const newHeight = canvasRef.current.width / aspectRatio
      const enlarge = newWidth / img.width
      canvasRef.current.height = newHeight
      ctx.drawImage(img, 0, 0, newWidth, newHeight)

      // required head size
      // ctx.beginPath()
      // ctx.strokeStyle = "#FFFF00"
      // ctx.moveTo(0, img.height * 0.1 * enlarge)
      // ctx.lineTo(200, img.height * 0.1 * enlarge)
      // ctx.moveTo(0, img.height * 0.79 * enlarge)
      // ctx.lineTo(200, img.height * 0.79 * enlarge)
      // ctx.stroke()

      //eyes compliance field
      // ctx.beginPath()
      // ctx.strokeStyle = "#FF0000"
      // ctx.moveTo(0, img.height * 0.31 * enlarge)
      // ctx.lineTo(200, img.height * 0.31 * enlarge)
      // ctx.moveTo(0, img.height * 0.44 * enlarge)
      // ctx.lineTo(200, img.height * 0.44 * enlarge)
      // ctx.stroke()

      // const top = Math.round((detections.box.top - cutLines.top) * enlarge)
      // const bottom = Math.round(
      //   (detections.box.bottom - cutLines.top) * enlarge
      // )
      // const left = Math.round((detections.box.left - cutLines.left) * enlarge)
      // const right = Math.round((detections.box.right - cutLines.left) * enlarge)

      // drawing green rectangle for recognized face
      // ctx.beginPath()
      // ctx.strokeStyle = "#00FF00"
      // ctx.moveTo(left, top)
      // ctx.lineTo(right, top)
      // ctx.moveTo(left, bottom)
      // ctx.lineTo(right, bottom)
      // ctx.moveTo(left, top)
      // ctx.lineTo(left, bottom)
      // ctx.moveTo(right, top)
      // ctx.lineTo(right, bottom)
      // ctx.stroke()

      ctx.font = "30px Arial"
      ctx.fillStyle = "white"
      ctx.globalAlpha = 0.5
      ctx.rotate(Math.PI / 10)
      ctx.fillText(
        watermark_text,
        parseInt(newWidth / 2) - 100,
        parseInt(newHeight / 4)
      )
      ctx.fillText(
        watermark_text,
        parseInt(newWidth / 2) - 100,
        parseInt(newHeight / 4) * 2
      )
      ctx.fillText(
        watermark_text,
        parseInt(newWidth / 2) - 100,
        parseInt(newHeight / 4) * 3
      )

      dispatch(setStepProcessedPhoto(canvasRef.current.toDataURL()))
      localStorage.setItem("photo", canvasRef.current.toDataURL())
      dispatch(setStepProcessedData(processedPhoto))
      localStorage.setItem("data", processedPhoto)
      changeTitle("Compatibility test passed! 👍")
      changeSubtitle1("")
      changeSubtitle2("")
      setIsProcessed(true)

      checkRef.current.style.display = "none"
      changeIsDraggable(true)
    }
  }

  async function detectFace(img) {
    var detectionModel = "TinyFace"

    var detections = await faceapi.detectSingleFace(
      img,
      new faceapi.TinyFaceDetectorOptions()
    ).withFaceLandmarks(true);

    //if first detection failed try another one
    if (!detections) {
      detections = await faceapi.detectSingleFace(
        img,
        new faceapi.SsdMobilenetv1Options()
      ).withFaceLandmarks(true);

      detectionModel = "Ssd"
    }

    var result = {
      face: detections,
      detectionModel: detectionModel,
    }

    return result
  }

  function confirmCheckBox(val) {
    //console.log(`confirmCheckBox: ${val}`, val);
    setConfirmed(val);
  }

  function continueError(e) {
    console.log(`continueError: `, e);
    setRequiredError(true);
  }

  return (
    //<MainContainer fullLengthComponent={isVideoVisible ? <CornerVideo getId={getSessionId} /> : ""}>
    <MainContainer fullLengthComponent={<CornerVideo getId={getSessionId} />}>
    {/* <Header /> */}
      <div
        className={`dashboard`}
        onDragEnter={isDraggable ? handleDrag : null}
        onDragOver={isDraggable ? handleDrag : null}
        onDragLeave={isDraggable ? handleDrag : null}
        onDrop={isDraggable ? handleDrop : null}
      >
        <div className={`dashboard-alert ${isAlertShow && "active"}`}>
          {alertMsg}
        </div>
        <div className={`dashboard-dragbg ${dragActive ? "dragActive" : ""}`}>
          Drop photo anywhere
        </div>
        <div>
          {/* <div className="uploadButton">

          </div> */}
          <Hero
            handleFileUpload={handleFileUpload}
            setVisibility={setUploadBtnVisibility1}
          />
          <HowToMakeV2 id="howTo" />
          <HowItWorks id="howItWorks" />

          {/* <Info /> */}
          {/* <GetPassport /> */}

          <QandAsection id="intro" />

          {/* <div ref={dashboardBttmRef} className="dashboard-bottom">
            <div
              className={`dashboard-bottom-upload ${isUploadBtnVisible1 || isUploadBtnVisible2
                ? ""
                : "floatBottomUpload"
                }`}
            >
              <Drag handleFileUpload={handleFileUpload} />
            </div>
          </div> */}
          {/* <Download /> */}
          {/* <ReadOurBlog /> */}
        </div>

        {/* <div
          className={`${isDialogShow && "dashboard-disable-background"}`}
          onClick={handleDlg}
        >
          <div
            className={`dashboard-dialog ${isDialogShow && "active"}`}
            onClick={doNothing}
          >
            <img className="close-btn" src={closeImg} onClick={handleDlg} alt="Close" /> */}
        <MainDialog closeDlg={handleDlg} isVisible={isDialogShow}>
          <div className="MainContent">
            <div className="MainContent-title">
              <div>{title}</div>
            </div>
            <div className="MainContent-subtitle">
              <div>{subtitle1}</div>
              <div>{subtitle2}</div>
              {!isProcessed && !isFailed && (
                <div className="MainContent-subtitle-quotes">
                  {quote}
                </div>
              )}
            </div>

            {isFailed ? (
              //show video
              <div className="MainContent-error">
                <div className={`MainContent-error-link ${errorLink && 'showLink'}`}>
                  <a href={`/error#${errorLink}`}>
                    Need more help? See the troubleshooting guide.
                  </a>
                </div>
                <div>
                  <img src={errorImgSrc} />
                </div>
                <div className="MainContent-error-upload">
                  <Drag flag={false} handleFileUpload={handleFileUpload} />
                </div>
              </div>
            ) : (
              <div
                className="MainContent-content"
                ref={dlgContentRef}
              >
                <div className="MainContent-content-left">

                  <div className="MainContent-content-left-content">
                    <div
                      className="MainContent-content-left-content-disable-background"
                      ref={checkRef}
                    >
                      <div className="MainContent-content-left-content-disable-background-line">
                        <div className="MainContent-content-left-content-disable-background-line-1"></div>
                        <div className="MainContent-content-left-content-disable-background-line-2"></div>
                      </div>
                      Checking...
                    </div>
                    {/* <div>
                      {}
                    </div> */}
                    <canvas
                      ref={canvasRef}
                      className="MainContent-content-left-content-upload"
                    />
                  </div>

                  {/* {isProcessed && <div className='MainContent-content-left-edit'>
                                        <a onClick={() => {
                                            navigate("/edit");
                                        }}><img src={sparkleImg} /> <span>Edit this photo</span></a>
                                    </div>} */}
                </div>

                {isProcessed && (
                  <div className="MainContent-content-right">
                    {/* <div
                        className="MainContent-content-right-criteria"
                        onClick={() => {
                          changeIsCriteria(!isCriteria)
                        }}
                      >
                        <span>Detailed photo criteria</span>
                        <img src={isCriteria ? vector2Img : vector1Img} />
                      </div> */}
                    {isCriteria ? (
                      <div className="MainContent-content-right-item">
                        <label>
                          <div className={`MainContent-content-right-item-text ${isRequiredError && !isConfirmed && 'redBackGround'}`}>
                            <input
                              type="checkbox"
                              name="confirm"
                              onChange={(e) => { confirmCheckBox(e.target.checked); }}
                            />
                            <span className={`MainContent-content-right-item-asterix ${isRequiredError && !isConfirmed && 'visibleAsterix'}`}>* </span>
                            By checking this box, I confirm that:
                          </div>
                          <ul>
                            <li>I am not wearing glasses or a hat</li>
                            <li>I am not dressed in white</li>
                            <li>I am maintaining a neutral expression without smiling</li>
                          </ul>
                          <span className={`MainContent-content-right-item-asterix ${isRequiredError && !isConfirmed && 'visibleAsterix'}`}>* required</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="MainContent-content-right-pay">
                      {/* You will pay: <span>${count}</span> */}
                    </div>
                    <Button
                      title={"Continue"}
                      classNames={"MainContent-content-right-btn"}
                      onClick={() => navigate("/payment")}
                      isEnable={isConfirmed}
                      onError={(e) => continueError(e)}
                    />
                    <Button
                      title={"Retake Photo"}
                      icon={cameraImg}
                      classNames={
                        "MainContent-content-right-btn"
                      }
                      backgroundColor={"header_white"}
                      onClick={handleDlg}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </MainDialog>
        {/* </div>
        </div> */}
        <form className="dashboard-file-upload">
          <input
            type="file"
            onChange={(e) => {
              processPhoto(e.target.files[0], true)
            }}
            ref={fileUploadRef}
          />
        </form>
      </div>
      {/* <Footer /> */}
    </MainContainer>
  )
}

export default Dashboard
