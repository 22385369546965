import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../components/Basic/Spinner';
import Button from '../Basic/Button';
import Blog from '../Basic/Blog';
import { setCurrentBlog } from '../../redux/actions/blogsAction';
import './style.scss';

function ReadOurBlog() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { blogs, loading } = useSelector((state) => state.blogs)

    return (
        loading
            ? <Spinner />
            :
            <div className='rob'>
                <div className='rob-up'>
                    <div className='rob-up-title'>
                        Read our blog
                    </div>
                    <Button
                        title='Read more'
                        classNames={'rob-up-button'}
                        onClick={() => {
                            navigate('/blog')
                        }}
                    />
                </div>
                <div className='rob-down'>
                    <div className='rob-down-blog-group'>
                        {
                            blogs.map((item, i) => (
                                i < 4
                                    ? <Blog
                                        key={i}
                                        flag={(i + 1) % 4 ? "1" : "2"}
                                        img={item.image}
                                        date={item.date}
                                        title={item.title}
                                        content={item.preview}
                                        onClick={() => {
                                            navigate(`/ablog${item.path}`)
                                            dispatch(setCurrentBlog(i))
                                        }}
                                    />
                                    : ""
                            ))
                        }
                    </div>
                </div>
                <div className='rob-button'>
                    <Button
                        title='Read more'
                        classNames={'rob-button-class'}
                        onClick={() => {
                            navigate('/blog')
                        }}
                    />
                </div>
            </div>
    )

}

export default ReadOurBlog;