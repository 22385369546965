import './style.scss';
//import _mainVideo1 from '../../../assets/homepage/frontPageVideo/frontPageVideo_3.webm'
// import _mainVideo2 from '../../../assets/homepage/frontPageVideo/frontPageVideo_2.mp4'
// import _videoLowRez from '../../../assets/homepage/frontPageVideo/frontPageVideo_lowRez.webm'

import arrows from '../../../assets/homepage/frontPageVideo/arrows1.png'
import speakerOn from '../../../assets/homepage/frontPageVideo/speaker.png'
import speakerOff from '../../../assets/homepage/frontPageVideo/speakerOff.png'
import MainDialog from "../MainDialog"
import { useState, useRef } from 'react';
import { isMobile } from '../../../global/Utils.js';
import { backendUtils } from '../../../global/backendUtils.js';
import { front_video_opened  } from "../../../global/config.js"

function CornerVideo({
    getId
}) {
    const [isloading, setLoading] = useState(true);
    const [isFullScreen, setFullScreen] = useState(false);
    const [isMuted, setMuted] = useState(true);
    const [wasOpened, setOpened] = useState(false);
    const requestHasBeenSent = useRef(false);

    const closeDialog = () => {
        if (isMobile()) {
            setOpened(true)
        }

        setMuted(true)
        setFullScreen(false)
    }

    const reportEvent = () => {
        if (!requestHasBeenSent.current) {
            backendUtils.reportSessionEvent(getId(), front_video_opened)
            requestHasBeenSent.current = true
        }
    }
    
    const showFullScreen = () => {
        reportEvent()
        setMuted(false)
        setFullScreen(true)
    }

    const changeMuted = (event) => {
        setMuted(!isMuted)
        event.stopPropagation()
    }

    const loaded = () => {
        setLoading(false)
    }

    const isIphone = () => {
        const isIphone = /(iPhone)/i.test(navigator.userAgent)
        console.log("isPhone: ", isIphone)

        return isIphone
    }

    return (
        <div className={`CornerVideo ${isloading && 'VideoLoading'}`} >
            {isFullScreen ?
                <MainDialog closeDlg={closeDialog}>
                    <div className='VideFullscreen'>
                        {/* <video src={_mainVideo1} className='VideFullscreen-video' /> */}
                        <video muted={isMuted} autoPlay={true} loop playsInline={true} className='VideFullscreen-video'>
                            <source src="/frontPageVideo.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </MainDialog>
                :
                <div className={`CornerVideo-wrap ${wasOpened && 'reducedSize'}`} onClick={showFullScreen}>
                    <div className='CornerVideo-wrap-arrows' onClick={showFullScreen}>
                        <img src={arrows} className='CornerVideo-wrap-arrows-img' />
                    </div>
                    <div className='CornerVideo-wrap-speaker' onClick={changeMuted}>
                        <img src={speakerOff} className={`CornerVideo-wrap-speaker-imgOff ${isMuted ? 'showIcon' : 'dontShowIcon'}`} />
                        <img src={speakerOn} className={`CornerVideo-wrap-speaker-imgOn ${!isMuted ? 'showIcon' : 'dontShowIcon'}`} />
                    </div>
                    <div className='CornerVideo-wrap-body'>
                        <video
                            muted={isMuted}
                            autoPlay={true}
                            loop
                            loading="lazy"
                            playsInline={true}
                            className='CornerVideo-wrap-body-small'
                            onCanPlayThrough={loaded}
                        >
                            <source src="/frontPageVideo.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            }
        </div>
    )
}

export default CornerVideo;