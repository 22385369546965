import { useState } from 'react'
import { useDispatch } from 'react-redux';
import Button from '../Basic/Button';
import convenience1Img from '../../assets/homepage/howitworks-1.jpg'
import Dropdown from '../Basic/Dropdown';
import {
    countries,
    countries_size,
    size
} from '../../global/config';
import { setStepCountry } from '../../redux/actions/stepAction';
import './style.scss';

function StepContent1({
    goNext
}) {
    const countryConst = "Select a country";
    const docConst = "Choose a size";
    const [country, setCountry] = useState(countryConst);
    const [doc, setDoc] = useState(docConst);
    const [unit, setUnit] = useState("");
    const [isNext, setIsNext] = useState('');
    let temp1 = ["USA", "Canada", "Thailand"];
    let temp2 = ["Passport 2x2 in", "Passport 2x2 in", "Passport 2x2 in"];

    const dispatch = useDispatch();

    const onChange = (type, value) => {
        if (type === 'country') {
            setCountry(value)
            setDoc(size[countries_size[value]])
            if (value === "USA") setUnit("(inches)")
            else setUnit("(millimeters)")
            setIsNext(isNext + 'C')
        }
        if (type === 'doc') {
            setDoc(value)
            setIsNext(isNext + 'D')
        }
    }

    return (
        <div className="step-content1">
            <div className='step-content1-img'>
                <img src={convenience1Img} />
            </div>
            <div className='step-content1-card'>
                <div className='step-content1-card-title'>
                    Select Size and Country
                </div>
                <div className='step-content1-card-item-group'>
                    <div className='step-content1-card-item-group-item'>
                        <div className='step-content1-card-item-group-item-label'>
                            Country*
                        </div>
                        <Dropdown
                            content={countries}
                            value={country}
                            onChange={(value) => {
                                onChange('country', value)
                            }}
                            classNames="z-index-1"
                        />
                    </div>
                    <div className='step-content1-card-item-group-item'>
                        <div className='step-content1-card-item-group-item-label'>
                            Size {unit}
                        </div>
                        <div className='step-content1-card-item-group-item-content'>
                            {doc}
                        </div>
                    </div>
                </div>
                <Button
                    title="Next step"
                    classNames='step-content1-card-button'
                    isEnable={isNext.includes('C')}
                    onClick={() => {
                        goNext()
                        dispatch(setStepCountry(country))
                    }}
                />
            </div>
        </div>
    )
}

export default StepContent1;