import { ref, child, get, set, query, orderByChild, orderByKey, limitToLast, onValue, endBefore, startAfter, equalTo, update, remove } from "firebase/database";
import { database } from '../firebase';
import { useDispatch, useSelector } from 'react-redux';

const chunkSize = 3;
const sessionsChunkSize = 20;

export class DbHelper {

    static sortData = (posts) => {
        let sorted = {};
        for (const [link, blogRecord] of Object.entries(posts)) {
            sorted[blogRecord.id] = blogRecord;
            sorted[blogRecord.id].link = link;
        }

        console.log("updateBlogTop sorted", sorted);

        return sorted;
    }

    static sortSessions = (sessions, includeRawSessions = true) => {
        let sorted = [];

        let errorStats = {};
        let totalErrorCount = 0;
        let totalSuccessSessionsCount = 0

        // for (const [guid, session] of Object.entries(sessions)) {
        //     let timeStampId = Number(session.lastUpdated);
        //   sorted[timeStampId] = session;
        //   sorted[timeStampId].guid = guid;
        // }

        for (const [guid, session] of Object.entries(sessions)) {
            let timeStampId = Number(session.lastUpdated);
            session.sessionId = guid;

            if (includeRawSessions) {
                sorted.push(session);
            }

            if (session.isValidated) {
                totalSuccessSessionsCount++;
            }

            if (session.errors) {
                Object.keys(session.errors).forEach(function (key) {
                    totalErrorCount++;

                    //console.log(key, session.errors[key]);
                    let error = session.errors[key];
                    let errorName = error.name ? error.name : error;

                    if (errorStats[errorName]) {
                        errorStats[errorName]++;
                    }
                    else {
                        errorStats[errorName] = 1;
                    }

                });
            }
            //sorted[timeStampId] = session;
            //sorted[timeStampId].guid = guid;
        }

        let result = {
            stats: {
                errorStatsByType: errorStats,
                totalErrorCount: totalErrorCount,
                totalSuccessSessionsCount: totalSuccessSessionsCount,
                totalSessionCount: Object.keys(sessions).length
            }
        }

        if (includeRawSessions) {
            sorted.sort((a, b) => a.lastUpdated && b.lastUpdated && a.lastUpdated.localeCompare(b.lastUpdated));
            result.data = sorted;
        }

        //console.log("result fetching sessions", result);

        return result;
    }

    static getIndex = (posts) => {
        let index = {};
        for (const [link, blogRecord] of Object.entries(posts)) {
            index[blogRecord.id] = link;
        }

        //console.log("updateBlogTop index", index);

        return index;
    }

    static fetchImgData = async (sessionId, updateImgData, updateImgDataError) => {
        const dbRef = ref(database);
        await get(child(dbRef, `imgData/${sessionId}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    //console.log("Price fetching", snapshot.val());  
                    updateImgData(snapshot.val());
                }
                else {
                    updateImgData(null);
                }
            })
            .catch((error) => {
                updateImgDataError(error);
            });

    }

    static updatePerformanceData = async (data) => {
        const dbRef = ref(database, `performanceStats`);

        var val = await new Promise(resolve => {
            update(dbRef, data).then((snapshot) => {
                resolve();
            });
        });
    }

    static updateSesion = async (sessionId, data) => {
        const dbRefSession = ref(database, `sessions/${sessionId}`);
        update(dbRefSession, data);
    }

    static updateImgData = async (sessionId, data) => {
        const dbRefSession = ref(database, `imgData/${sessionId}`);
        update(dbRefSession, data);
    }

    static removeFromSesion = async (key) => {
        const dbRefSession = ref(database, `sessions/${key}`);
        remove(dbRefSession);
    }


    static fetchSessionsData = async (updateSessions, updateSessionsError) => {
        const dbRefSession = ref(database, 'sessions');
        const query1 = query(dbRefSession, orderByChild("lastUpdated"), limitToLast(sessionsChunkSize));

        get(query1).then((snapshot) => {
            if (snapshot.exists()) {
                //console.log("Sessions fetching val", snapshot.val());
                // let result = {
                //     index: this.getIndex(snapshot.val()),
                //     data: snapshot.val()
                // }

                updateSessions(this.sortSessions(snapshot.val(), true));
            }
            else {
                //console.error("Blogs fetching no value");
                updateSessionsError();
            }
        })
            .catch((error) => {
                updateSessionsError(error);
                //console.error("Blogs fetching failed", error);
            });

    }

    static fetchPerfStatsAfterTheDate = async (lastShown, updatePerfStats) => {
        const dbRefPFStats = ref(database, 'performanceStats');
        const queryR = query(dbRefPFStats, orderByKey(), startAfter(lastShown));

        var val = await new Promise(resolve => {
            get(queryR).then((snapshot) => {
                if (snapshot.exists()) {
                    //console.log("fetchAllSessionsBefore ", snapshot.val());
                    resolve(snapshot.val());
                }
                else {
                    resolve(null);
                }
            });
        });

        return val;
    }

    static fetchAllDataAfterTheDate = async (dbRef, lastShown) => {
        const queryR = query(dbRef, orderByChild("lastUpdated"), startAfter(lastShown, "lastUpdated"));

        var val = await new Promise(resolve => {
            get(queryR).then((snapshot) => {
                if (snapshot.exists()) {
                    resolve(snapshot.val());
                }
                else {
                    resolve(null);
                }
            });
        });

        return val;
    }

    static fetchAllOrdersAfterTheDate = async (lastShown, isPaid) => {
        let dbRefOrders = ref(database, isPaid ? 'ordersPaid' : 'orders');

        return await this.fetchAllDataAfterTheDate(dbRefOrders, lastShown);
    }

    static fetchAllSessionsAfterTheDate = async (lastShown) => {
        const dbRefSession = ref(database, 'sessions');

        let sessions = await this.fetchAllDataAfterTheDate(dbRefSession, lastShown);

        if (sessions) {
            return this.sortSessions(sessions, true)
        }
        else {
            return null;
        }
    }

    static fetchRemainingTokens = (updateTokens) => {
        const dbRef = ref(database);
        get(child(dbRef, `system/remainingCredits`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    //console.log("Price fetching", snapshot.val());  
                    updateTokens(snapshot.val());
                }
            })
            .catch((error) => {
                console.error("Tokens fetching failed", error);
            });
    }

    static fetchAllDataInAPeriod = async (dbRef, firstShown, lastShown) => {
        const queryR = query(dbRef, orderByChild("lastUpdated"), startAfter(firstShown, "lastUpdated"), endBefore(lastShown, "lastUpdated"));

        var val = await new Promise(resolve => {
            get(queryR).then((snapshot) => {
                if (snapshot.exists()) {
                    //console.log("fetchAllSessionsBefore ", snapshot.val());

                    resolve(snapshot.val());
                }
                else {
                    resolve(null);
                }
            });
        });

        return val;
    }

    static fetchAllOrdersInAPeriod = async (firstShown, lastShown, isPaid) => {
        const dbRefOrders = ref(database, isPaid ? 'ordersPaid' : 'orders');

        return await this.fetchAllDataInAPeriod(dbRefOrders, firstShown, lastShown);
    }


    static fetchAllSessionsInAPeriod = async (firstShown, lastShown, includeRawSessions) => {
        const dbRefSession = ref(database, 'sessions');

        let sessions = await this.fetchAllDataInAPeriod(dbRefSession, firstShown, lastShown);

        if (sessions) {
            return this.sortSessions(sessions, includeRawSessions)
        }
        else {
            return null;
        }
    }

    static fetchSessionsBefore = async (lastShown, updateSessions) => {
        const dbRefSession = ref(database, 'sessions');
        const queryR = query(dbRefSession, orderByChild("lastUpdated"), endBefore(lastShown, "lastUpdated"), limitToLast(sessionsChunkSize));

        get(queryR).then((snapshot) => {
            if (snapshot.exists()) {
                //console.log("fetchSessionsBefore ", snapshot.val());

                updateSessions(this.sortSessions(snapshot.val(), true));
            }
        })
            .catch((error) => {
                //console.error("Blogs fetching failed", error);
            });
    }


    static fetchPrices = async (updatePrices) => {
        const dbRef = ref(database);
        await get(child(dbRef, `prices`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    //console.log("Price fetching", snapshot.val());  
                    updatePrices(snapshot.val());
                }
            })
            .catch((error) => {
                console.error("Price fetching failed", error);
            });
    }

    static fetchBlogByName = async (link, updateSingleBlog, updateBlog, updateBlogError) => {
        if (link) {
            const dbRefBlogs = ref(database, 'blogs');

            await get(child(dbRefBlogs, link))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        console.log("fetchBlogByName ", snapshot.val());
                        let blog = {};
                        blog[link] = snapshot.val();

                        updateSingleBlog(blog);

                        this.fetchBlogsTop(updateBlog, updateBlogError);
                    }
                    else {
                        console.log("not found");
                        updateBlogError();
                    }
                })
                .catch((error) => {
                    updateBlogError();
                    console.error("blog fetching failed", error);
                });
        }
    }

    static fetchBlogsStartingAt = async (index, updateBlog) => {
        const dbRefBlogs = ref(database, 'blogs');
        const queryR = query(dbRefBlogs, orderByChild("id"), endBefore(index, "id"), limitToLast(chunkSize));

        get(queryR).then((snapshot) => {
            if (snapshot.exists()) {
                console.log("fetchBlogsStartingAt ", snapshot.val());
                let result = {
                    index: this.getIndex(snapshot.val()),
                    data: snapshot.val()
                }

                updateBlog(result);
            }
        })
            .catch((error) => {
                //console.error("Blogs fetching failed", error);
            });
    }

    static fetchBlogsTop = async (updateBlogTop, updateBlogError) => {
        const dbRefBlogs = ref(database, 'blogs');
        const query1 = query(dbRefBlogs, orderByChild("id"), limitToLast(chunkSize));

        get(query1).then((snapshot) => {
            if (snapshot.exists()) {
                console.log("Blog fetching val", this.getIndex(snapshot.val()));
                let result = {
                    index: this.getIndex(snapshot.val()),
                    data: snapshot.val()
                }

                updateBlogTop(result);
            }
            else {
                //console.error("Blogs fetching no value");
                updateBlogError();
            }
        })
            .catch((error) => {
                updateBlogError();
                //console.error("Blogs fetching failed", error);
            });
    }
}