import Resizer from "react-image-file-resizer"

export class ImgProcessing {

    static getMainProperties(detections, img, imgNaturalWidth, imgNaturalHeight) {
        let result = {
            top: detections.face.detection.box.top,
            left: detections.face.detection.box.left,
            bottom: detections.face.detection.box.bottom,
            right: detections.face.detection.box.right,
            width: img.width,
            height: img.height,
            origWidth: imgNaturalWidth,
            origHeight: imgNaturalHeight,
        }

        if (detections && detections.face && detections.face.landmarks && detections.face.landmarks.positions) {
            let lmPositions = detections.face.landmarks.positions;
            let left = img.width;
            let right = 0;
            let top = img.height;
            let bottom = 0;

            lmPositions.forEach(function (pos) {
                if (pos.x < left) {
                    left = pos.x;
                }

                if (pos.x > right) {
                    right = pos.x;
                }

                if (pos.y < top) {
                    top = pos.y;
                }

                if (pos.y > bottom) {
                    bottom = pos.y;
                }
            })

            result.ffLeft = left;
            result.ffRight = right;
            result.ffTop = top;
            result.ffBottom = bottom;
        }

        return result;
    }

    static getLightArr(wholeImg, detections) {
        //console.log(`getLightArr test`);
        //crop face and try to analyze brightness
        let startTime = new Date().getTime()
        // console.log(`start level calculation time: ${startTime}`);

        // var faceLeft = box.left
        // var faceWidth = box.right - box.left
        // var faceHeight = Math.floor((box.bottom - box.top) * 0.85)
        // faceLeft += Math.floor(faceWidth * 0.2)
        // var faceRight = Math.floor(box.right - faceWidth * 0.2)
        // faceWidth = faceRight - faceLeft

        // var faceLeft = detections.ffLeft
        // var faceWidth = detections.ffRight - detections.ffLeft
        // var faceHeight = Math.floor(detections.ffBottom - detections.ffTop)
        // var faceRight = detections.ffRight

        let shrink = 0.07;
        var faceLeft = detections.ffLeft
        var faceWidth = detections.ffRight - detections.ffLeft
        //var faceHeight = Math.floor((detections.bottom - detections.top) * 0.85)
        var faceHeight = Math.floor(detections.ffBottom - detections.ffTop)
        faceLeft += Math.floor(faceWidth * shrink)
        var faceRight = Math.floor(detections.ffRight - faceWidth * shrink)
        //var faceRight = detections.ffRight
        faceWidth = faceRight - faceLeft
    

        // console.log(
        //     `img detected box faceLeft: ${Math.round(faceLeft)}, faceRight: ${faceRight
        //     }, faceHeight: ${faceHeight}, faceWidth: ${faceWidth}`
        //   )
        var faceCanvas = this.getCroppedCanvasWithImg(wholeImg, {
            left: faceLeft,
            top: detections.ffTop,
            width: faceWidth,
            height: faceHeight,
        })

        // console.log(`getLightArr img w:${box.width} h:${box.height}`);

        var canvas = this.getCanvasWithImg(wholeImg, {
            width: detections.width,
            height: detections.height
        })


        let ctx = faceCanvas.getContext("2d")
        //let ctx = faceCanvas.getContext("2d")

        try {
            // console.log(`getLevel test w: ${faceWidth} h:${faceHeight}`);

            var faceData = ctx.getImageData(0, 0, faceWidth, faceHeight)
            // var data = ctx.getImageData(0, 0, box.width, box.height)

            var level = this.getLevel(faceData)
            //this.getMaxColorAndLevel()

            var lightArr = this.getLightDistribution(faceData, level)

            ctx = canvas.getContext("2d")
            let data = ctx.getImageData(0, 0, detections.width, detections.height)
            lightArr.top = this.getMaxColorAndLevel(data)

            // console.log(`end level calculation time: ${(new Date().getTime()) - startTime}ms`);

            return lightArr
        } catch (e) {
            console.error(`error.`, e)
            return null
        }
    }

    static getCanvasWithImg(img, cutLines) {
        const canvas = document.createElement("canvas")
        canvas.width = cutLines.width
        canvas.height = cutLines.height
        const ctx = canvas.getContext("2d")

        ctx.drawImage(
            img,
            0,
            0
        )

        return canvas
    }

    static getCroppedCanvasWithImg(img, cutLines) {
        const canvas = document.createElement("canvas")
        canvas.width = cutLines.width
        canvas.height = cutLines.height
        const ctx = canvas.getContext("2d")
        let top = Math.round(Math.abs(cutLines.top));

        let srTop = cutLines.top;
        let srHeight = cutLines.height;
        let dsTop = 0;

        if (srTop < 0) {
            srHeight += srTop;
            dsTop -= srTop;
            srTop = 0;
        }

        ctx.drawImage(
            img,
            cutLines.left,
            srTop,
            cutLines.width,
            srHeight,
            0,
            dsTop,
            cutLines.width,
            srHeight
        )

        return canvas
    }

    static getLevel(data) {
        var lngt = data.data.length
        var pixelCountTrashHold = lngt / 4 / 4
        // console.log(`pixelCountTrashHold test ${pixelCountTrashHold} lngth ${lngt}`)
        var levels = new Array(255)
        levels.fill(0)

        for (let i = 0; i < lngt; i += 4) {
            var r = data.data[i]
            var g = data.data[i + 1]
            var b = data.data[i + 2]

            //var max = Math.max(r, g, b)
            var max = Math.floor((r + g + b) / 3)
            levels[max]++
        }

        var totalPixelsVisible = 0
        var level = 254
        for (; level >= 0 && totalPixelsVisible < pixelCountTrashHold; level--) {
            totalPixelsVisible += levels[level]
        }

        return level
    }

    static getMaxColorAndLevel(data) {
        //console.log(`getLevel test ${fLeft} ${fRight} ${fTop} ${fBottom}`);
        var lngt = data.data.length
        var topR = 0
        var topG = 0
        var topB = 0
        var topLevel = { sum: 0, i: 250 }
        var colorsByLevel = new Array(255)

        for (let i = 0; i < lngt; i += 4) {
            let r = data.data[i]
            let g = data.data[i + 1]
            let b = data.data[i + 2]

            // if (i > lngt - 100) {
            //     console.log(`Coordinates X: ${x} Y: ${y}`);
            // }

            if (r > topR) {
                topR = r
            }

            if (g > topG) {
                topG = g
            }

            if (b > topB) {
                topB = b
            }

            var max = Math.max(r, g, b)

            //var max = Math.round((r + g + b) / 3)
            if (max > 230 && max < 245) {
                if (colorsByLevel[max]) {
                    colorsByLevel[max] += r + g + b

                    if (colorsByLevel[max] > topLevel.sum) {
                        topLevel.sum = colorsByLevel[max]
                        topLevel.i = max
                    }
                }
                else {
                    colorsByLevel[max] = r + g + b
                }
            }
        }

        // console.log(`getLevel  topR: ${topR} topG: ${topG} topB: ${topB}`);
        // console.log(`getLevel  topLevel: ${topLevel.i}`);
        return {
            r: topR,
            g: topG,
            b: topB,
            level: topLevel.i
        }
    }

    static getSumFromColors(color) {
        return color && color.r && color.g && color.b ? color.r + color.g + color.b : 0
    }

    static checkMax(currVal, i, max) {
        if (currVal > max.val) {
            return { val: currVal, i: i }
        }

        return false
    }

    static finLocalMaxs(colors, levels) {
        console.log(`finLocalMaxs  `, colors);
        let listOfMaxs = {}
        let top3Max = [{ val: 0, i: 0 }, { val: 0, i: 0 }, { val: 0, i: 0 }]

        for (let i = 254; i > 230; i--) {
            let currentSum = this.getSumFromColors(colors[i])
            let prevSum = this.getSumFromColors(colors[i - 1])
            let nextSum = this.getSumFromColors(colors[i + 1])
            if (currentSum > prevSum && currentSum > nextSum) {
                listOfMaxs[i] = currentSum
            }

            if (currentSum > top3Max[0].val) {
                top3Max[0].val = currentSum
                top3Max[0].i = i
            }
            else if (currentSum > top3Max[1].val) {
                top3Max[1].val = currentSum
                top3Max[1].i = i
            }
            else if (currentSum > top3Max[2].val) {
                top3Max[2].val = currentSum
                top3Max[2].i = i
            }
        }

        //second run

        let secondListOfMaxs = {}
        let keys = Object.keys(listOfMaxs);
        for (let i = keys.length - 2; i > 0; i--) {
            if (listOfMaxs[keys[i]] > listOfMaxs[keys[i - 1]] && listOfMaxs[keys[i]] > listOfMaxs[keys[i + 1]]) {
                secondListOfMaxs[keys[i]] = listOfMaxs[keys[i]]
            }

        }

        console.log(`top 1 maximus  ${top3Max[0].i} color r:${Math.round(colors[top3Max[0].i].r / levels[top3Max[0].i])} g:${Math.round(colors[top3Max[0].i].g / levels[top3Max[0].i])} b:${Math.round(colors[top3Max[0].i].b / levels[top3Max[0].i])} `);
        console.log(`top 3 maximus  `, top3Max);

        console.log(`list of local maximus  `, listOfMaxs);
        console.log(`2nd list of local maximus  `, secondListOfMaxs);
    }

    static getLightDistribution(data, level) {
        //console.log(`getLightDistribution test`);
        //console.log(`getLightDistribution w: ${data.width}, h: ${data.height}`);
        var lngt = data.data.length
        var totalLightSum = 0
        var halfHeight = Math.trunc(lngt / 4 / data.width / 2)
        let maxChinCutOff = data.width / 4

        var LightValueArr = new Array(data.width)
        LightValueArr.fill(0)

        // draw a mask of colors
        for (let i = 0; i < lngt; i += 4) {
            var r = data.data[i]
            var g = data.data[i + 1]
            var b = data.data[i + 2]

            r = Math.max(0, r - level) * 3.9
            g = Math.max(0, g - level) * 3.9
            b = Math.max(0, b - level) * 3.9

            var max = Math.max(r, g, b)
            if (max > 0) {
                //data.data[i+1] += 100;

                var x = (i / 4) % data.width
                var y = Math.trunc((i / 4) / data.width)

                var currentLimit = maxChinCutOff * ((y - halfHeight) / halfHeight)
                //need to add boundery conditions
                if (y < halfHeight || (x > currentLimit && x < data.width - currentLimit)) {
                    var avrg = Math.round((r + g + b) / 3)

                    LightValueArr[x] += avrg
                    totalLightSum += avrg
                    //LightValueArr[x] += hsl.l;
                }
                else {
                    var a = 0;
                }
            }
        }

        //console.log(`getLightDistribution test`, LightValueArr);

        var result = {
            lightValueArr: LightValueArr,
            sum: totalLightSum,
        }

        return result
    }

    static resizeFile = (blob, imgNaturalWidth, imgNaturalHeight, reqSize) =>
        new Promise((resolve) => {
            const box = {
                width: imgNaturalWidth,
                height: imgNaturalHeight,
            }

            if (box.width > box.height) {
                if (box.width > reqSize) {
                    box.height = parseInt((reqSize / box.width) * box.height)
                    box.width = reqSize
                }
            } else {
                if (box.height > reqSize) {
                    box.width = parseInt((reqSize / box.height) * box.width)
                    box.height = reqSize
                }
            }

            Resizer.imageFileResizer(
                blob,
                box.width,
                box.height,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                "base64"
            )
        })

    static cropImg = (img, cutLines) =>
        new Promise((resolve) => {
            const canvas = this.getCroppedCanvasWithImg(img, cutLines)

            var uri = canvas.toDataURL("image/jpeg")

            resolve(uri)
        })

    static createImg = (src) =>
        new Promise((resolve) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.src = src
        })

    static async resizeAndCrop(
        cutLines,
        resizedUri,
        origImg,
        blobOrigImg,
        imgNaturalWidth,
        imgNaturalHeight
    ) {
        var resImg

        //return await cropImg(origImg, cutLines);
        if (cutLines && !cutLines.isError && cutLines.isResolutionChanged) {
            //console.debug("resolution changed", cutLines)
            //check ratio calculate if original resolution is ok and use it if not then resize
            if (cutLines.resultRatio == 1) {
                //console.debug("using original image");
                return await this.cropImg(origImg, cutLines)
            } else {
                //need to resize
                const maxSize = Math.max(
                    imgNaturalWidth * cutLines.resultRatio,
                    imgNaturalHeight * cutLines.resultRatio
                )

                console.debug("resizing the picture again, max size: ", maxSize)
                var newSrc = await this.resizeFile(
                    blobOrigImg,
                    imgNaturalWidth,
                    imgNaturalHeight,
                    maxSize
                )
                var newImg = await this.createImg(newSrc)
                return await this.cropImg(newImg, cutLines)
            }
        }

        var newImg = await this.createImg(resizedUri)
        return await ImgProcessing.cropImg(newImg, cutLines)
    }

}