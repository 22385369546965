export class Document
{
  constructor(type, textDescription, docName)
  {
    this.type = type;
    this.textDescription = textDescription;
    this.docName = docName;
  }
}

export class DocTypes
{
    static usPassport = new Document("USPassportPhoto", "US Passport photo 2x2 inches","Passport");
    static usGreenCard = new Document("GreenCardPhoto", "US Green Card photo 2x2 inches","Green Card");
    static docList = [this.usPassport, this.usGreenCard];

    static getDefault()
    {
        return this.usPassport;
    }

    static getDocList()
    {
        return this.docList;
    }

    static findByType(type)
    {
        if (type)
        {
            switch(type.toLowerCase())
            {
                case 'uspassportphoto' : return(this.usPassport);
                case 'greencardphoto' : return(this.usGreenCard);
            }
        }

        return(this.getDefault());
    }
}