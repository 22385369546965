import React from 'react';
import { Provider } from "react-redux";
import Header from './components/Header';
import Footer from './components/Footer';
import Routing from './routing';
import { store } from "./redux/store";
import './App.scss';
import ReactGA from "react-ga4";

ReactGA.initialize('G-N2PXHEJ227');

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        <Routing />
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
