import { forwardRef } from 'react';
import successImg from "../../../assets/icons/checkbox-circle-fill.svg";
import errorImg from "../../../assets/icons/error-warning-fill.svg";
import './style.scss';

const Input = forwardRef(({
    placeholder,
    background,
    icon,
    iconPos,
    iconType = "",
    onChange,
    onBlur,
    value,
    onClickClose,
    focus = false,
    type = "",
    name = "",
    controlType = "text",
}, ref) => {
    return (
        <>
            <div className={`input ${background} ${type === "success" ? "success-input" : (type === "error" ? "error-input" : "")}`}>
                {iconPos === "left" ? <img src={icon} className={iconType === "close" ? "hand" : ""} onClick={iconType === "close" ? () => { onClickClose() } : null} /> : null}
                {focus
                    ? <input
                        type={controlType}
                        placeholder={placeholder}
                        onChange={(e) => {
                            if (onChange)
                            {
                                onChange(e)
                            }
                        }}
                        onBlur={() => {
                            onBlur()
                        }}
                        value={value}
                        autoFocus
                        ref={ref}
                        name={name}
                    />
                    : <input
                        type={controlType}
                        placeholder={placeholder}
                        onChange={(e) => {
                            if (onChange)
                            {
                                onChange(e)
                            }
                        }}
                        onBlur={() => {
                            onBlur()
                        }}
                        value={value}
                        ref={ref}
                        name={name}
                    />}
                {iconPos === "right" ? <img src={icon} className={iconType === "close" ? "hand" : ""} onClick={iconType === "close" ? () => { onClickClose() } : null} /> : null}
            </div>
            {type
                ? <div className={`message ${type === "success" ? "success-message" : "error-message"}`}>
                    <img src={type === "success" ? successImg : errorImg} />
                    <div className='message-text'>{type === "success" ? "" : "error"}</div>
                </div>
                : ""}
        </>
    )
})

export default Input;