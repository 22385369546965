import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../components/Basic/Spinner';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Blog from '../../components/Basic/Blog';
import MainContainer from '../../components/Basic/MainContainer';
import facebookImg from '../../assets/icons/facebook.svg'
import twitterImg from '../../assets/icons/twitter.svg'
import linkedinImg from '../../assets/icons/linkedin.svg'
import { setCurrentBlog } from '../../redux/actions/blogsAction';
import { getBlogLink } from '../../global/Utils.js';
import './style.scss';

function ABlog({

}) {
    const { blogs, singleBlog, loading, singleLoading, isError } = useSelector((state) => state.blogs);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [blogLink, setblogLink] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);

        setblogLink(getBlogLink(window.location.pathname));
        //console.log(`blogLink: ${blogs.data[blogLink]}`);

    });

    function renderBlogRecords(styleClass, limit) {
        let items = [];

        var keys = Object.keys(blogs.index);
        //console.log(`keys:`, keys);

        let recordCount = 0;

        // reversing the list of blog records to display most recent first
        for (let i = keys.length - 1; i >= 0 && recordCount < limit; i--) {

            //console.log(`keys desc: ${keys[i]}`);
            let blogNum = keys[i];
            let blogLinkFound = blogs.index[blogNum];

            if (blogLinkFound.toString().toLowerCase() !== blogLink.toLowerCase()) {
                recordCount++;
                //console.log(`blogLinkFound: ${blogLinkFound}`);
                let blogRecord = blogs.data[blogLinkFound];

                items.push(<Blog
                    key={blogRecord.id}
                    //flag={(i + 1 - 4) % 7 ? "1" : "2"}
                    flag={"1"}
                    img={`data:image/jpg;base64,${blogRecord.img}`}
                    classNames={styleClass}
                    //                                date={item.date}
                    date={new Date(blogRecord.date).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}
                    title={blogRecord.title}
                    content={blogRecord.preview}
                    onClick={() => {
                        navigate('/blog/' + blogLinkFound)
                        //dispatch(setCurrentBlog(i))
                    }}
                />)
            }
        }

        console.log(`items:`, items);
        return items;
    }

    function getBlog() {
        //console.log("getBlog blogs.data", blogs.data);
        if (blogs.data && blogs.data[blogLink]) {
            return blogs.data[blogLink];
        }

        //console.log("getBlog singleBlog", singleBlog);
        // if link is in another storage, return it from the storage
        if (singleBlog && singleBlog[blogLink]) {
            return singleBlog[blogLink];
        }
    }

    return (
        <MainContainer>
            {/* <Header /> */}

            {/* <div className='ablog-share'>
                    <div>Share</div>
                    <img src={facebookImg} />
                    <img src={twitterImg} />
                    <img src={linkedinImg} />
                </div> */}
            {isError ?
                <div className='blog-page-error'>
                    Not found
                </div>
                :
                ((!loading || !singleLoading) && blogLink && getBlog() ?
                    <div className='ablog'>
                        <div className='ablog-left'>
                            <Blog
                                flag="1"
                                img={`data:image/jpg;base64,${getBlog().img}`}
                                date={new Date(getBlog().date).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}
                                title={getBlog().title}
                                classNames={"ablog-left-blog"}
                            />
                            {/* <div className='ablog-left-fastlink'>
                                    <div className='ablog-left-fastlink-title'>
                                        Fast links
                                    </div>
                                    <div className='ablog-left-fastlink-body'>
                                        <div>1. Aliqua id fugiat nostrud irure ex</div>
                                        <div>2. Nostrud irure ex</div>
                                        <div>3. Irure ex</div>
                                    </div>
                                </div> */}
                            <div className='ablog-left-body' dangerouslySetInnerHTML={{ __html: getBlog().content }}>
                            </div>
                        </div>
                        <div className='ablog-right'>
                            {/* <div className='ablog-right-fastlink'>
                        <div className='ablog-right-fastlink-title'>
                            Fast links
                        </div>
                        <div className='ablog-right-fastlink-body'>
                            <div>1. Aliqua id fugiat nostrud irure ex</div>
                            <div>2. Nostrud irure ex</div>
                            <div>3. Irure ex</div>
                        </div>
                    </div> */}
                            {/* <div className='ablog-right-share'>
                                    <div>Share</div>
                                    <div className='ablog-right-share-group'>
                                        <img src={facebookImg} />
                                        <img src={twitterImg} />
                                        <img src={linkedinImg} />
                                    </div>
                                </div> */}
                            <div className='ablog-right-body'>
                                <div className='ablog-right-body-title'>
                                    More articles
                                </div>
                                {!loading && blogLink ?
                                    renderBlogRecords("ablog-right-body-blog", 3)
                                    :
                                    <Spinner />
                                    // blogs.map((item, i) => (
                                    //     i >= current && i < current + 3 || i < current + 3 - blogs.length
                                    //         ? <Blog
                                    //             flag="1"
                                    //             img={item.image}
                                    //             date={item.date}
                                    //             title={item.title}
                                    //             classNames={"ablog-right-body-blog"}
                                    //             onClick={() => {
                                    //                 navigate('/ablog')
                                    //                 dispatch(setCurrentBlog(i))
                                    //             }}
                                    //         />
                                    //         : ""
                                    // ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner />)
            }



            {/* <Footer /> */}
        </MainContainer>
    )
}

export default ABlog;