import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Button from '../Basic/Button';
import Dropdown from '../Basic/Dropdown';
import arrow1Img from '../../assets/homepage/step/arrow1.png';
import arrow2Img from '../../assets/homepage/step/arrow2.png';
import {
    countries_size,
    size
} from '../../global/config';
import './style.scss';

function StepContent4({

}) {
    const { processedPhoto, processedData, country } = useSelector((state) => state.step)

    const navigate = useNavigate();
    const inchConst = "Select";
    const [inch, setInch] = useState(inchConst);
    const [isNext, setIsNext] = useState(false);
    let temp = ["1", "2", "3", "4"];

    const handleChange = (value) => {
        setInch(value)
        setIsNext(true)
    }

    return (
        <div className="step-content4">
            <div className="step-content4-left">
                <div className="step-content4-left-photos">
                    <div className="step-content4-left-photos-item">
                        <img src={processedPhoto} />
                        <img src={processedPhoto} />
                    </div>
                    <div className="step-content4-left-photos-item">
                        <img src={processedPhoto} />
                        <img src={processedPhoto} />
                    </div>
                </div>
                <img src={arrow1Img} className="step-content4-left-arrow" />
            </div>
            <div className="step-content4-mid">
                <div className="step-content4-mid-img">
                    <img src={processedPhoto} className='step-content4-mid-img-photo' />
                    <img src={arrow2Img} className='step-content4-mid-img-arrow' />
                </div>
            </div>
            <div className="step-content4-right">
                <div className="step-content4-right-up">
                    <div className="step-content4-right-up-title">
                        Document info
                    </div>
                    <div className="step-content4-right-up-content">
                        <div className="step-content4-right-up-content-item">
                            <div className="step-content4-right-up-content-item-title">
                                Size
                            </div>
                            <div className="step-content4-right-up-content-item-content">
                                {size[countries_size[country]]}
                            </div>
                        </div>
                        <div className="step-content4-right-up-content-item">
                            <div className="step-content4-right-up-content-item-title">
                                Country
                            </div>
                            <div className="step-content4-right-up-content-item-content">
                                {country}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step-content4-right-down">
                    <div className="step-content4-right-down-content">
                        <div className="step-content4-right-down-content-text">
                            Quantity*
                        </div>
                        <Dropdown
                            content={temp}
                            value={inch}
                            onChange={(value) => {
                                handleChange(value)
                            }}
                            smart={false}
                        />
                    </div>
                </div>
                <Button
                    title="Complete"
                    classNames='step-content4-right-button'
                    backgroundColor={isNext ? "brand" : "disable"}
                    isEnable={isNext}
                    onClick={() => {
                        // navigate("/price")
                        const a = document.createElement('a');
                        a.href = processedData;
                        a.download = "passport.png";
                        a.click();
                    }}
                />
            </div>
        </div>
    )
}

export default StepContent4;