import './style.scss';
import ErrorList from '../ErrorList';
import { useState } from 'react';
import { DbHelper } from '../../../global/dbHelper';
import Spinner from '../Spinner';
import AdminDashboardRow from '../AdminDashboardRow';


function AdminDashboard({
    statData
}) {
    const [isShowingMoreInfo, setShowingMoreInfo] = useState(false);
    const [isDataLoaded, setDataLoaded] = useState(false);
    //console.log("errorsList ", errList);




    function getDataView(data) {

        let items = [];

        items.push(<AdminDashboardRow
            key={1}
            isHeader={true}
        />);

        Object.keys(data).forEach(function (month) {
            let monthData = data[month];
            console.log("monthData ", monthData);
            items.push(<AdminDashboardRow
                key={month}
                month={month}
                data={monthData}
            />);
        });

        return items;
    }



    return (
        <div className='adminDashboard'>

            <div className='adminDashboard-data'>
                {getDataView(statData)}
            </div>
        </div>


        //         <div className='session-collapsed-paid'>
        //             {(session.isOkToDelete || session.isPaid) ? '$' : ''}
        //         </div>
        //         <div className={`session-collapsed-timestamp ${(session.finalData || session.isValidated) ? "passedChecks" : ""}`} onClick={() => { loadAndShowMoreInfo() }}>
        //             {
        //                 session.lastUpdated.substring(0, 4)
        //                 + '-'
        //                 + session.lastUpdated.substring(4, 6)
        //                 + '-'
        //                 + session.lastUpdated.substring(6, 8)
        //                 + ' '
        //                 + session.lastUpdated.substring(8, 10)
        //                 + ':'
        //                 + session.lastUpdated.substring(10, 12)
        //                 + ':'
        //                 + session.lastUpdated.substring(12, 14)
        //             }
        //         </div>
        //         <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[0]) && "notThisError"}`} >
        //             <div className='session-collapsed-error-tooltip'>
        //                 {errList[0]}
        //             </div>
        //         </div>
        //         <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[1]) && "notThisError"}`} >
        //             <div className='session-collapsed-error-tooltip'>
        //                 {errList[1]}
        //             </div>
        //         </div>
        //         <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[2]) && "notThisError"}`} >
        //             <div className='session-collapsed-error-tooltip'>
        //                 {errList[2]}
        //             </div>
        //         </div>
        //         <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[3]) && "notThisError"}`} >
        //             <div className='session-collapsed-error-tooltip'>
        //                 {errList[3]}
        //             </div>
        //         </div>
        //         <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[4]) && "notThisError"}`} >
        //             <div className='session-collapsed-error-tooltip'>
        //                 {errList[4]}
        //             </div>
        //         </div>
        //         <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[5]) && "notThisError"}`} >
        //             <div className='session-collapsed-error-tooltip'>
        //                 {errList[5]}
        //             </div>
        //         </div>
        //         {isLoading ?
        //             <Spinner smallSize="true" />
        //             :
        //             ""
        //         }
        //     </div>
        //     {isShowingMoreInfo ?
        //         <div className='session-bottomInfo' >
        //             <div className='session-bottomInfo-left'>
        //                 <div>
        //                     sessionId: {sessionId}
        //                 </div>
        //                 {session.parentSessionId ?
        //                     <div>
        //                         parentId: {session.parentSessionId}
        //                     </div>
        //                     :
        //                     ''}
        //                 <div className='session-bottomInfo-left-errDetails'>
        //                     {printedErrors}
        //                 </div>
        //             </div>
        //             <div className='session-bottomInfo-right'>
        //                 <div>
        //                     {initialData}
        //                 </div>
        //                 <div>
        //                     {finalData}
        //                 </div>
        //             </div>
        //         </div>
        //         :
        //         <></>
        //     }
        // </div>
    )
}

export default AdminDashboard;