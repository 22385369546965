import { combineReducers } from "redux";

import blogs from "./blogsReducer";
import step from "./stepReducer";
import price from "./priceReducer";
import docType from "./docTypeReducer";

export default combineReducers({
    blogs,
    step,
    price,
    docType
});