import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Drag from '../Drag';
import illustration1Img from '../../assets/homepage/hero/passportPhotoReduced.webp'
import checkMark from '../../assets/homepage/hero/y.svg'
import checkMarkInverted from '../../assets/homepage/hero/yInverted.svg'
import xMark from '../../assets/homepage/hero/x.svg'
import usPic from '../../assets/homepage/hero/usPic.webp'
import onlinePic from '../../assets/homepage/hero/onlineCompetition.webp'

import abbLogoImg from '../../assets/homepage/hero/abb.svg'
import amxLogoImg from '../../assets/homepage/hero/amx.svg'
import booLogoImg from '../../assets/homepage/hero/boo.svg'
import dtLogoImg from '../../assets/homepage/hero/dt.svg'
import expLogoImg from '../../assets/homepage/hero/exp.svg'
import onlyImg from '../../assets/homepage/hero/only.jpg'
import downloadImg from '../../assets/homepage/hero/downloadIcon.png'
import mailImg from '../../assets/homepage/hero/mailIcon.png'
import {
    homepage_hero_title,
    homepage_hero_desc,
} from '../../global/config'
import { CheckVisibility } from '../../global/Utils.js';
import './style.scss';

const Hero = ({
    handleFileUpload,
    setVisibility
}) => {
    const { prices } = useSelector((state) => state.price);
    const { docType } = useSelector((state) => state.docType);
    const dragRefMy = useRef(null);
    const [isMainButtonVisible, setMainButtonVisibility] = useState(true);
    const [isDigitalSelected, setDigitalSelected] = useState(false);
    const [isPrintedSelected, setPrintedSelected] = useState(false);
    const [isOurOfferSelected, setOurOfferSelected] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
            const handleScroll = (e) => {
                setVisibility(CheckVisibility(dragRefMy));
            };
            // const updateSize = () => {
            //     //console.log(`deviceType1 ${deviceType}`);
            //     dragRef.current.children[0].value = getText(isMobile);
            // }

            //console.log(`doc ${docType.textDescription}`);

            // window.addEventListener('resize', updateSize);
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
                // window.removeEventListener('resize', updateSize)
            };
        
        }, []);

    return (
        <div className='hero'>
            <div className='hero-main'>
                <div className='hero-main-up'>
                    <div className='hero-main-up-title'>
                        {homepage_hero_title.replace("{docName}", docType.docName)}
                    </div>
                    <div ref={dragRefMy} className='hero-main-dragContainer'>
                        <Drag handleFileUpload={handleFileUpload} />
                    </div>
                    {/* {prices
                        ? <div className='hero-main-up-img'>
                            <span>Only <span className='hero-main-up-img-decoration'>${prices.printed}</span></span>
                        </div>
                        : ""} */}
                    <div className='hero-main-up-prices'>
                        {prices.printed != 0
                            ? <div >
                                <div className='hero-main-up-prices-title'> 
                                    starting at
                                </div>
                                <div className='hero-main-up-prices-types'> 
                                <div className={`hero-main-up-prices-types-background ${(isDigitalSelected || isPrintedSelected || isOurOfferSelected) && "showBackGround"}`}
                                    onClick={() => {setDigitalSelected(false); setPrintedSelected(false); setOurOfferSelected(false);}}>

                                </div>
                                <div className='hero-main-up-prices-types-container' onClick={() => {setPrintedSelected(true)}}>
                                        <div className='hero-main-up-prices-types-container-printed showHelpIcon'>
                                            <img src={mailImg} title="Mailed Copy" /> 
                                            <span>Mailed Copy&nbsp;-</span>
                                            ${prices.printed}
                                        </div>
                                        <div className={`hero-helpIcon ${isPrintedSelected && "showToolTip"}`}
                                            >
                                            ?
                                            <div className='hero-helpIcon-toolTip toolTip'
                                                >
                                                <div className='hero-helpIcon-toolTip-text'
                                                >
                                                    <h3>take photo & upload:</h3>
                                                    Choose a mailed copy for professional passport photos delivered directly to your door.
                                                    Convenience, quality, delivered.
                                                    For only {prices.printed}!
                                                    Select the “Upload&nbsp;photo” from above to get started!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hero-main-up-prices-types-container' onClick={() => {setDigitalSelected(true)}}>
                                        <div className='hero-main-up-prices-types-container-digital showHelpIcon'> 
                                            <img src={downloadImg} title="Digital Copy" /> 
                                            <span>Digital Copy&nbsp;-</span>
                                            ${prices.digital}
                                        </div>
                                        <div className={`hero-helpIcon ${isDigitalSelected && "showToolTip"}`}
                                        >
                                        ?
                                        <div className='hero-helpIcon-toolTip toolTip digital'
                                            >
                                            <div className='hero-helpIcon-toolTip-text'
                                                >
                                                    <h3>take photo & upload:</h3>
                                                    Get your digital passport photos instantly! 
                                                    Download and print at home or at CVS for ultimate convenience. 
                                                    For only {prices.digital}!
                                                    Select the “Upload&nbsp;photo” from above to get started!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""}
                    </div>
                </div>
                <div className='hero-main-down'>
                    <img src={illustration1Img} alt='Example'/>
                </div>
            </div>
            <div className='hero-compare'>
                <div className='hero-compare-tiles'>
                    <div className='hero-compare-tiles-tile showHelpIcon' onClick={() => {setOurOfferSelected(true)}}>
                        <div className='hero-compare-tiles-tile-container first'>
                            <div className='hero-compare-tiles-tile-container-text'>

                                <div className='hero-compare-tiles-tile-container-text-name'>
                                    Our&nbsp;Offer
                                        <div className={`hero-helpIcon ${isOurOfferSelected && "showToolTip"}`}
                                            >
                                            ?
                                            <div className='hero-helpIcon-toolTip toolTip left'
                                                >
                                                <div className='hero-helpIcon-toolTip-text'
                                                >
                                                    <h3>take photo & upload:</h3>
                                                    Get your digital passport photos instantly! 
                                                    Download and print at home or at CVS for ultimate convenience. 
                                                    For only {prices.digital}!
                                                    Select the “Upload&nbsp;photo” from above to get started!
                                                </div>
                                            </div>
                                        </div>

                                </div>
                                <div className='hero-compare-tiles-tile-container-text-price'>
                                    ${prices.digital}
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-point'>
                                    Print same day at CVS <br/> (starting at $0.45)
                                    <img src={checkMarkInverted} alt='Check mark' loading="lazy" />
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-point'>
                                    AI Check
                                    <img src={checkMarkInverted} alt='Check mark' loading="lazy" />
                                </div>
                            </div>
                            <div className='hero-compare-tiles-tile-container-img'>
                                <img src={usPic} alt='Correct pose example' loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <div className='hero-compare-tiles-tile'>
                        <div className='hero-compare-tiles-tile-container'>
                            <div className='hero-compare-tiles-tile-container-text'>

                                <div className='hero-compare-tiles-tile-container-text-name'>
                                    Online Competitors
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-price'>
                                    $15.99
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-point'>
                                    5-7 biz days
                                    <img src={xMark} alt='Check mark' loading="lazy" />
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-point'>
                                    AI Check
                                    <img src={checkMark} alt='Check mark' loading="lazy" />
                                </div>
                            </div>
                            <div className='hero-compare-tiles-tile-container-img'>
                                <img src={onlinePic} alt='Correct pose example' loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <div className='hero-compare-tiles-tile'>
                        <div className='hero-compare-tiles-tile-container last'>
                            <div className='hero-compare-tiles-tile-container-text'>

                                <div className='hero-compare-tiles-tile-container-text-name'>
                                    In Store CVS/Walgreens
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-price'>
                                    $16.99
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-point'>
                                    Print same day
                                    <img src={checkMark} alt='Check mark' loading="lazy" />
                                </div>
                                <div className='hero-compare-tiles-tile-container-text-point'>
                                    AI Check
                                    <img src={xMark} alt='Check mark' loading="lazy" />
                                </div>
                            </div>
                            <div className='hero-compare-tiles-tile-container-img last'>
                                <img src={onlinePic} alt='Correct pose example' loading="lazy" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className='hero-social'>
                <div className='hero-social-numbers'>
                    <div className='hero-social-numbers-holder'>
                        <div className='hero-social-numbers-holder-item'>
                            <div className='hero-social-numbers-holder-item-number'>
                                +10,000
                            </div>
                            Passport Photos Made
                        </div>
                        <div className='hero-social-numbers-holder-item-point'>
                            <div className='hero-social-numbers-holder-item-number'>
                                4.7
                            </div>
                            Average User Review
                        </div>
                        <div className='hero-social-numbers-holder-item-point'>
                            <div className='hero-social-numbers-holder-item-number'>
                                100%
                            </div>
                            Satisfaction Guarantee
                        </div>
                    </div>
                </div>
                {/* <div className='hero-social-title'>
                    Trusted by the largest travel companies
                </div>
                <div className='hero-social-list'>
                    <div className='hero-social-list-item'>
                        <div>
                            Expedia
                        </div>
                        <img src={expLogoImg} title="Expedia" />
                    </div>
                    <div className='hero-social-list-item'>
                        <div>
                            Booking.com
                        </div>
                        <img src={booLogoImg} title="Booking.com" />
                    </div>
                    <div className='hero-social-list-item'>
                        <div>
                            Amex Travel
                        </div>
                        <img src={amxLogoImg} title="Amex Travel" />
                    </div>
                    <div className='hero-social-list-item'>
                        <div>
                            Airbnb
                        </div>
                        <img src={abbLogoImg} title="Airbnb" />
                    </div>
                    <div className='hero-social-list-item'>
                        <div>
                            dt.com
                        </div>
                        <img src={dtLogoImg} title="dt.com" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Hero;