import './style.scss';
import closeImg from "../../../assets/icons/close.svg"

function MainDialog({
    children,
    closeDlg,
    isVisible = true
}) {
    const doNothing = (e) => {
        if (e != undefined) {
            e.stopPropagation()
        }
    }

    //console.log("MainDialog", children);
    return (
        isVisible ?
            <div className='disabledBackground' onClick={closeDlg} >
                <div className='disabledBackground-dialog' onClick={doNothing} >
                    <img className="disabledBackground-dialog-closeBtn" src={closeImg} onClick={closeDlg} alt="Close" loading="lazy" />
                    {children}
                </div>
            </div>
            :
            <div>
                <div className='hiddenDialog'>
                    {children}
                </div>
            </div>
    )
}

export default MainDialog;