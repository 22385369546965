import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Basic/Button';

import './style.scss';

const Drag = ({
    flag = true,
    handleFileUpload
}) => {
    const navigate = useNavigate();
    const dragRef = useRef(null);
    //const [deviceType, setDeviceType] = useState("");
    const tapToText = "Tap to 👉";

    function getIsMobile()
    {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
          hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
          hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
          const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
          if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
          } else if ("orientation" in window) {
            hasTouchScreen = true; // deprecated, but good fallback
          } else {
            // Only as a last resort, fall back to user agent sniffing
            var UA = navigator.userAgent;
            hasTouchScreen =
              /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
              /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
          }
        }

        return hasTouchScreen;
    }

    function getText(isMobile)
    {
        if (window.screen.width <= 500 || isMobile)
        {
            return tapToText;
        }

        return "👆 Drag n drop your photo everywhere, or";
    }

    var isMobile = getIsMobile();

    return (
        <div className='drag' ref={dragRef}>
            <span className={isMobile ? 'drag-textM' : 'drag-text'}>
              {getText(isMobile)}
            </span>
                {/* <input className={isMobile ? 'drag-textM' : 'drag-text'} value={getText(isMobile)} readOnly={true}/> */}
            <Button
                title='Upload photo'
                classNames={'drag-button'}
                onClick={handleFileUpload}
            />
        </div>
    )
}

export default Drag;