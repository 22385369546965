import { useRef, useState } from 'react'
import arrowLeftImg from '../../assets/icons/arrow-left.svg'
import arrowRightImg from '../../assets/icons/arrow-right.svg'

import _1Img from '../../assets/homepage/wrapper/2.jpg'
import _2Img from '../../assets/homepage/wrapper/1.jpg'
import _3Img from '../../assets/homepage/wrapper/3.jpg'
import './style.scss';

function HowToMake() {
    // const [currentChild, setCurrentChild] = useState(1);
    const howtomakeRef = useRef(null);

    return (
        <div className='howtomake'>
            <div className='howtomake-header'>
                <div className='howtomake-header-title'>
                    How to take good photos
                </div>
            </div>
            <div className='howtomake-content' ref={howtomakeRef}>
                <div className='howtomake-content-item'>
                    <img className='howtomake-content-item-img' src={_1Img} />
                    <div className='howtomake-content-item-content'>
                        <div className='howtomake-content-item-content-up'>
                            <div className='howtomake-content-item-content-up-title'>
                                Pose
                            </div>
                            <div className='howtomake-content-item-content-up-desc'>
                                Stand in a well-lit area and pose for your perfect passport photo.
                            </div>
                        </div>
                        <div className='howtomake-content-item-content-control'>
                            <img src={arrowLeftImg}
                                onClick={() => {
                                    const lastChild = howtomakeRef.current.lastElementChild;
                                    howtomakeRef.current.insertBefore(lastChild, howtomakeRef.current.firstElementChild);
                                }}
                            />
                            <img src={arrowRightImg}
                                onClick={() => {
                                    const firstChild = howtomakeRef.current.firstElementChild;
                                    howtomakeRef.current.appendChild(firstChild);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='howtomake-content-item'>
                    <img className='howtomake-content-item-img' src={_2Img} />
                    <div className='howtomake-content-item-content'>
                        <div className='howtomake-content-item-content-up'>
                            <div className='howtomake-content-item-content-up-title'>
                                Background
                            </div>
                            <div className='howtomake-content-item-content-up-desc'>
                                Upload your photo and let our advanced technology remove the background for you, ensuring it meets passport standards.
                            </div>
                        </div>
                        <div className='howtomake-content-item-content-control'>
                            <img src={arrowLeftImg}
                                onClick={() => {
                                    const lastChild = howtomakeRef.current.lastElementChild;
                                    howtomakeRef.current.insertBefore(lastChild, howtomakeRef.current.firstElementChild);
                                }}
                            />
                            <img src={arrowRightImg}
                                onClick={() => {
                                    const firstChild = howtomakeRef.current.firstElementChild;
                                    howtomakeRef.current.appendChild(firstChild);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='howtomake-content-item'>
                    <img className='howtomake-content-item-img' src={_3Img} />
                    <div className='howtomake-content-item-content'>
                        <div className='howtomake-content-item-content-up'>
                            <div className='howtomake-content-item-content-up-title'>
                                Composition
                            </div>
                            <div className='howtomake-content-item-content-up-desc'>
                                If you can ask someone to take a picture of you. Make sure the head is in the middle of the shot with shoulders being visible. Don't stand too close or too far from the camera.
                            </div>
                        </div>
                        <div className='howtomake-content-item-content-control'>
                            <img src={arrowLeftImg}
                                onClick={() => {
                                    const lastChild = howtomakeRef.current.lastElementChild;
                                    howtomakeRef.current.insertBefore(lastChild, howtomakeRef.current.firstElementChild);
                                }}
                            />
                            <img src={arrowRightImg}
                                onClick={() => {
                                    const firstChild = howtomakeRef.current.firstElementChild;
                                    howtomakeRef.current.appendChild(firstChild);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowToMake;