import './style.scss';

function MainContainer({
    children,
    fullLengthComponent
}) {
    //console.log("MainContainer", children);
    return (
        <div className='FullWidth'>
            <div className='FullWidth-container'>
                {children}
            </div>
            {fullLengthComponent ? fullLengthComponent : ''}
        </div>
    )
}

export default MainContainer;