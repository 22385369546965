export const logo = '';// 'quickpassportphotos';
export const copyright = '© quickpassportphotos, 2023';
/* homepage */
export const homepage_hero_title_old = 'Easily make professional passport and visa photos online.';
export const homepage_hero_title = 'Perfect {docName} Photos In 30 Seconds!';
export const homepage_hero_desc = "Snap, Upload, and Relax: We'll Handle the Rest!";

/* blog */
export const blog_title1 = "10 Hilarious Cartoons That Depict Real-Life Problems of Programmers";
export const blog_title2 = "Lorem ipsum dolor sit amet";
export const blog_title3 = "Lorem ipsum dolor sit amet, consectetur adipiscing";
export const blog_title4 = "10 Hilarious Cartoons That Depict Real-Life Problems of Programmers";
export const blog_content1 = "Redefined the user acquisition and redesigned the onboarding experience, all within 3 working weeks.";
export const blog_content2 = "Redefined the user acquisition and redesigned the onboarding experience, all within 3 working weeks.";
export const blog_content3 = "Redefined the user acquisition and redesigned the onboarding experience, all within 3 working weeks.";
export const blog_content4 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco \ br Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ";
export const blog_date1 = "August 13, 2022";
export const blog_date2 = "August 13, 2022";
export const blog_date3 = "August 13, 2022";
export const blog_date4 = "August 13, 2022";
export const popdoc_requirements = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. ";
export const popdoc_requirements1_title = "U.S. passport pictures near me";
export const popdoc_requirements1_content = "U.S. passport pictures near me";
export const popdoc_requirements2_title = "URequirements for U.S. passport photos at nearest photo store";
export const popdoc_requirements2_content = "Requirements for U.S. passport photos at nearest photo store";
export const popdoc_requirements3_title = "Instant passport photo near me";
export const popdoc_requirements3_content = "Instant passport photo near me";
export const popdoc_requirements4_title = "Premium mail photo service";
export const popdoc_requirements4_content = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. ";
export const popdoc_requirements5_title = "Passport photo printing near me";
export const popdoc_requirements5_content = "Passport photo printing near me";
export const popdoc_faq = "here you can find the documents to familiarize yourself with the requirements. Popular documents in the world";
export const popdoc_faq1_title = "U.S. passport pictures near me";
export const popdoc_faq1_content = "U.S. passport pictures near me";
export const popdoc_faq2_title = "Requirements for U.S. passport photos at nearest photo store";
export const popdoc_faq2_content = "Requirements for U.S. passport photos at nearest photo store";
export const popdoc_faq3_title = "Instant passport photo near me";
export const popdoc_faq3_content = "Instant passport photo near me";
export const popdoc_faq4_title = "Premium mail photo service";
export const popdoc_faq4_content = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. ";
export const reference1_title = "Determine eligibility";
export const reference1_content = ["Check if you are eligible for a U.S. passport. You will need to prove your U.S. citizenship and identity"];
export const reference2_title = "Check if you are eligible for a U.S. passport";
export const reference2_content = ["Getting a passport or renewing an existing one requires several steps to be followed, including"];
export const reference3_title = "Gather required documentation";
export const reference3_content = ["Collect all necessary documents, including proof of U.S. citizenship, a photo ID, and a passport photo"];
export const reference4_title = "Complete the passport application";
export const reference4_content = ["Fill out form DS-11, the application for a U.S. passport. You can complete this form online or onpe par"];
export const reference5_title = "Pay the fee";
export const reference5_content = ["The fee for a U.S. passport varies depending on the type of passport you need and whether you need it expedited. You can pay the fee with a credit card, check, or money order.", "Submit your application: Submit your completed application, required documents, and fee to a U.S. Department of State Passport Acceptance Facility."];
export const reference6_title = "Wait for processing";
export const reference6_content = ["The processing time for a U.S. passport varies, but it can take several weeks. Expedited services are available for an additional fee if you need your passport sooner"];
export const reference7_title = "Receive your passport";
export const reference7_content = ["Once your application has been processed, you will receive your passport in the mail. It is important to sign your passport and to keep it in a safe place"];
export const reference8_title = "Note";
export const reference8_content = ["If you are renewing a passport, you may be eligible to renew by mail using form DS-82. ", "However, if your previous passport was lost, stolen, or damaged, or if your name has changed, you will need to apply in person"];
export const countries = [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Germany",
    "Guyana",
    "India",
    "Ireland",
    "Italy",
    "Japan",
    "Mexico",
    "Netherlands",
    "New Zealand",
    "Norway",
    "Paraguay",
    "Peru",
    "Poland",
    "Russia",
    "South Africa",
    "South Korea",
    "Spain",
    "Suriname",
    "Sweden",
    "Switzerland",
    "UK",
    "Uruguay",
    "USA",
    "Venezuela"
];

export const countries_size = {
    "Argentina": 3,
    "Bolivia": 2,
    "Brazil": 8,
    "Chile": 6,
    "Colombia": 0,
    "Ecuador": 2,
    "Germany": 2,
    "Guyana": 2,
    "India": 2,
    "Ireland": 2,
    "Italy": 2,
    "Japan": 0,
    "Mexico": 2,
    "Netherlands": 2,
    "New Zealand": 2,
    "Norway": 2,
    "Paraguay": 8,
    "Peru": 2,
    "Poland": 2,
    "Russia": 2,
    "South Africa": 2,
    "South Korea": 1,
    "Spain": 2,
    "Suriname": 5,
    "Sweden": 2,
    "Switzerland": 2,
    "UK": 2,
    "Uruguay": 4,
    "USA": 7,
    "Venezuela": 2
};
export const size = [
    "30mm x 40mm",
    "33mm x 48mm",
    "35mm x 45mm",
    "40mm x 40mm",
    "40mm x 50mm",
    "45mm x 35mm",
    "45mm x 45mm",
    "2in x 2in",
    "50mm x 70mm"
];
export const size_pixel = [
    {
        width: 113,
        height: 151
    },
    {
        width: 124,
        height: 181
    },
    {
        width: 132,
        height: 170
    },
    {
        width: 151,
        height: 151
    },
    {
        width: 151,
        height: 189
    },
    {
        width: 170,
        height: 132
    },
    {
        width: 170,
        height: 170
    },
    {
        width: 1000,
        height: 1000
    },
    {
        width: 189,
        height: 264
    },
];
export const size1 = [
    "1.18 x 1.57",
    "1.30 x 1.89",
    "1.37 x 1.77",
    "1.57 x 1.57",
    "1.57 x 2",
    "1.77 x 1.37",
    "1.77 x 1.77",
    "2 x 2",
    "2 x 2.75"
];
export const quotes = 
[
    "Processing your photo... hope you didn’t blink! 😉",
    "Ensuring that your tie matches your suit... 🤵‍♂️",
    "Looking for photobombers in your passport photo... 🕵️‍♀️",
    "Assessing your photo... making sure it’s not a pic of your cat! 🐈",
    "Analyzing image... hope you’re not making a duck face! 🦆",
    "Working hard to make sure your hair looks fabulous! 💁‍♂️",
    "Waiting for the photo... perfect time for a coffee break! ☕",
    "Cross-checking your passport photo with your glamour shots! 🌟",
    "Validating image... making sure you’re not a vampire - no reflections! 🧛‍♂️",
    "Examining your photo... you’re not wearing those 3D movie glasses, right? 😎",
    "Confirming your photo... just checking it’s not a Picasso! 🎨",
    "Scanning... ensuring it’s not a candid from your last beach trip! 🏖️",
    "Verifying your photo... no bunny ears, right? 🐰",
    "Inspecting your photo... fingers crossed you didn’t use a Snapchat filter! 🤞",
    "Processing... did you remember to take off your superhero mask? 🦸‍♀️",
    "Working our magic... meanwhile, why not plan your next adventure? 🌍",
    "Running checks... just confirming you’re not in your Halloween costume! 🎃"
];
export const price = 6.99;
export const disprice = 4.99;
export const disrate = "20%";
//export const stripe_publish_key = "pk_test_51NE3iMHptscIAWPgIgVfX2c1N8n9Vnm6gcK8BRvNs0alAoQraN3sUuKaDXDsjXP5NWQm5DePrGHf3iho5jEkpkQO00BdqG6lmM";
export const stripe_publish_key = "pk_live_51NE3iMHptscIAWPgwnH3MS77YZblhbM75uEZNwK1xbPEWrLxkiIcrDhFBkcZPg8A4bL7IR7CdRaSETDyuxPk1FJO00M5OCxWjj";
export const firebase_functions_url = "https://us-central1-passportplus-web-58cd5.cloudfunctions.net/app";
export const firebase_functions_url_test = "http://127.0.0.1:5001/passportplus-web-58cd5/us-central1/app";
export const order_success_url = "https://quickpassportphotos.com/order-success";
export const payment_url = "https://passportplus-web-58cd5.web.app/payment";
export const order_success_url_test = "https://127.0.0.1:3000/order-success";
export const watermark_text = "quickpassportphotos";
//export const paypal_client_id = "AfTQE5Bnk1muuQ6oQgA6NrwpIXjLCo57urD4Vcu74C61ttNyCoOkq2vRVqwch5oSIQXtPsqXmj5PALfA";
export const paypal_client_id = "AXnAivrjzJtsrxUcQ-x9Kxg_bsfTajiJB7J02TgAjfN414Jg99DsFunxZWAyT1BIZDMuZeGnuSJsZ0J5";
export const paypal_client_id_old = "AfTQE5Bnk1muuQ6oQgA6NrwpIXjLCo57urD4Vcu74C61ttNyCoOkq2vRVqwch5oSIQXtPsqXmj5PALfA";
export const doc_type = "DocType";
export const session_id = "sessionId";
export const parent_session_id = "parentSessionId";
export const front_video_opened = "frontVideoOpened";
export const pay_status = "payStatus";
export const order_digital = "Digital"
export const order_Printed = "Printed"
export const documentList = {
    USPassportPhoto: "US Passport photo 2x2 inches",
    GreenCardPhoto: "US Green Card photo 2x2 inches"
}