import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
    order_success_url,
    paypal_client_id,
    order_Printed
} from '../../global/config.js';

const Paypal = ({
    price,
    description,
    orderType,
    handleAlert,
    createPhotoOrder,
    updatePaymentId
}) => {
    const navigate = useNavigate();
    const buttonStyles = {
        layout: 'vertical', // vertical | horizontal
        color: 'gold', // gold | blue | silver | black
        shape: 'rect', // pill | rect
        label: 'paypal', // checkout | pay | credit
        tagline: false // true | false
    };

    const [orderId, setOrderId] = useState("");

    const createOrder = async (data, actions) => {
        var photoOrderId = await createPhotoOrder({
            payType: 'paypal'
        });
        
        //console.log(`fetch orderId : ${photoOrderId}`);
        setOrderId(photoOrderId);

        var orderDetails = {
            purchase_units: [
                {
                    invoice_id: photoOrderId,
                    description: description,
                    amount: {
                        currency_code: "USD",
                        value: price
                    },
                },
            ],
        }

        if (orderType != order_Printed)
        {
            orderDetails.application_context = {
                shipping_preference: "NO_SHIPPING"
            }
        }

        console.log(`createOrder `, orderDetails);
        return actions.order
            .create(orderDetails)
            .then((orderID) => {
                // setOrderID(orderID);
                //console.log(`createOrder orderId `, orderID);
                return orderID;
            });
    };

    const onApprove = (data, actions) => {
        // sendEmail();
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            //console.log(`onApprove `, details);
            //send a request to confirm the payment with paymentId
            let req = {
                paymentId : details.id,
                operationType: 'addPaypalPaymentId'
                //orderId : details.purchase_units[0].invoice_id
            }
            updatePaymentId(req);
            navigate("/order-success");
        });
    };
    
    //capture likely error
    const onError = (data, actions) => {
        handleAlert("An Error occured with your payment ");
    };

    return (
        <PayPalScriptProvider options={{
            clientId: paypal_client_id,
            currency: "USD",
            intent: "capture",
            "disable-funding": "credit,card"
            }}>
            <PayPalButtons
                style={buttonStyles}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
            />
        </PayPalScriptProvider>
    );
}

    export default Paypal;