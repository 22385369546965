import { useRef, useState } from "react"
import arrowLeftImg from "../../assets/icons/arrow-left.svg"
import arrowRightImg from "../../assets/icons/arrow-right.svg"
import avatar1Img from "../../assets/homepage/reviews/photo1.jpg"
import avatar2Img from "../../assets/homepage/reviews/photo2.jpg"
import avatar3Img from "../../assets/homepage/reviews/photo3.jpg"
import startImg from "../../assets/icons/star.svg"
import "./style.scss"

function QaSection(param) {
  const reviewRef = useRef(null)
  return (
    <div className="q-and-a" id={`${param.id}`}>
      <div className="q-and-a-group-title">Introduction</div>
      <div className="q-and-a-text">
        Welcome to QuickPassportPhotos.com - Your one-stop solution for
        obtaining high-quality passport, visa, and ID photos without stepping a
        foot outside! Forget the hassle of finding a nearby photo booth or
        studio; we bring the studio to your comfort zone. Unleash the
        convenience of getting government-compliant passport photos, delivered
        with speed and precision. No app downloads needed - we’re all about
        simplicity and efficiency!
      </div>
      <div className="q-and-a-group-title">
        Step-by-Step to Picture-Perfect Passport Photos
      </div>
      <div className="q-and-a-group-title">1. Setting the Scene:</div>
      <div className="q-and-a-text">
        Select a location with abundant natural light, ideally by a window to
        capture the most flattering illumination for your photo. Our advanced AI
        technology will take care of the background, ensuring it meets all
        official requirements.
      </div>
      <div className="q-and-a-group-title">2. Strike a Pose:</div>
      <div className="q-and-a-text">
        Position yourself one meter away from the background, face the camera
        directly with a neutral expression - eyes open, mouth closed. No
        smiling, but think happy thoughts!
      </div>
      <div className="q-and-a-group-title">3. Dress Code:</div>
      <div className="q-and-a-text">
        Wear your everyday clothes but avoid light colors to contrast against
        the white background. Glasses and extravagant jewelry are a no-go. Keep
        it simple and neat.
      </div>
      <div className="q-and-a-group-title">
        4. Say Cheese! Well, not Literally:
      </div>
      <div className="q-and-a-text">
        Have a friend or family member snap a few shots. Pick your favorite -
        the one where you look like you, but on a really good day.
      </div>
      <div className="q-and-a-group-title">5. Upload and Relax:</div>
      <div className="q-and-a-text">
        Head over to QuickPassportPhotos.com, upload your chosen photo, and let
        our magic begin! Our innovative technology ensures your photo meets all
        government requirements. Why Choose QuickPassportPhotos.com?
      </div>
      <div className="q-and-a-group-title">✓ Save Money:</div>
      <div className="q-and-a-text">
        Why pay more when you can get the best for less? We offer premium
        quality photos without the premium price tag.
      </div>
      <div className="q-and-a-group-title">✓ Quick and Convenient:</div>
      <div className="q-and-a-text">
        In a rush? No worries. QuickPassportPhotos.com delivers your compliant
        photos faster than you can pack your bags!
      </div>
      <div className="q-and-a-group-title">✓ Professional Quality:</div>
      <div className="q-and-a-text">
        Our advanced technology ensures every photo meets the stringent
        government standards. We’re all about quality and compliance.
      </div>
      <div className="q-and-a-group-title">✓ 24/7 Service:</div>
      <div className="q-and-a-text">
        Whether it’s the crack of dawn or the stroke of midnight, we’re here,
        ready to turn your photo into passport perfection.
      </div>
      <div className="q-and-a-group-title">✓ 100% Guarantee:</div>
      <div className="q-and-a-text">
        Confidence is key, and with us, you can be confident that your photos
        will be accepted, or your money back!
      </div>
      <div className="q-and-a-group-title">FAQ Corner:</div>
      <div className="q-and-a-group-title">Q: Glasses or no glasses?</div>{" "}
      <div className="q-and-a-text">
        A: The rule is simple - no glasses allowed.
      </div>
      <div className="q-and-a-group-title">Q: To smile or not to smile?</div>{" "}
      <div className="q-and-a-text">
        A: Keep a neutral expression. Think Mona Lisa!
      </div>
      <div className="q-and-a-group-title">Q: Can I wear my lucky hat?</div>{" "}
      <div className="q-and-a-text">
        A: Unless it’s for religious or medical purposes and you have a signed
        statement to prove it, hats are off the table.
      </div>
      <div className="q-and-a-group-title">Q: How about my epic beard?</div>
      <div className="q-and-a-text">
        A: Beards are welcome! If it’s a part of your usual look, flaunt it.
      </div>
      <div className="q-and-a-group-title">Conclusion:</div>
      <div className="q-and-a-text">
        QuickPassportPhotos.com is not just a service; it’s an experience – a
        seamless journey from your home to destinations around the world. We’re
        committed to making every step, especially the first one, as effortless
        as possible. Let’s embark on this journey together - your flawless
        passport photo is just a click away!
        <br></br>
        <br></br>
        Ready, Set, Snap!
        <br></br>
        <br></br>
        Dive into a world where convenience meets quality. Upload your photo
        today and step closer to your next adventure with
        QuickPassportPhotos.com - fast, reliable, and impeccable, every time!
      </div>
    </div>
  )
}

export default QaSection
