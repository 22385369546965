import "./style.scss"
import { useEffect, useState } from "react"
import howitworksImg1 from "../../assets/homepage/HowtoOne.webp"
import howitworksImg2 from "../../assets/homepage/HowtoTwo.webp"
import howitworksImg3 from "../../assets/homepage/HowtoThree.webp"
import howitworksImg4 from "../../assets/homepage/HowtoFour.webp"

function HowItWorks(param) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767) // Adjust the screen width threshold as needed

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767) // Adjust the screen width threshold as needed.
    }

    // Initial check for mobile/desktop view
    handleResize()

    // Listen for window resize events
    window.addEventListener("resize", handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <div className="howitworks" id={`${param.id}`}>
      <div className="howitworks-title">How it works</div>
      <div className="howitworks-content">
        <div className="howitworks-content-item">
          <div className="howitworks-content-item-title">1. Click & Upload</div>
          <div className="howitworks-content-item-image">
            <img src={isMobile ? howitworksImg1 : howitworksImg2} alt='Upload picture' loading="lazy" />
          </div>
          <div className="howitworks-content-item-desc">
            Effortlessly capture a crystal-clear passport photo using your
            smartphone or digital camera, and instantly upload it to our
            user-friendly website.
          </div>
        </div>
        <div className="howitworks-content-item">
          <div className="howitworks-content-item-title">
            2. AI-Enhanced Editing
          </div>
          <div className="howitworks-content-item-image">
            <img src={howitworksImg4} alt='AI-Enhanced Editing' loading="lazy" />
          </div>
          <div className="howitworks-content-item-desc">
            Experience the power of cutting-edge AI technology as it seamlessly
            removes backgrounds and refines your image to fulfill all passport
            specifications.
          </div>
        </div>
        <div className="howitworks-content-item">
          <div className="howitworks-content-item-title">
            3. Guaranteed Quality Check
          </div>
          <div className="howitworks-content-item-image">
            <img src={howitworksImg3} alt='Guaranteed Quality Check' loading="lazy" />
          </div>
          <div className="howitworks-content-item-desc">
            Our dedicated team of seasoned professionals rigorously scrutinizes
            every photo, making certain it surpasses all quality standards and
            complies with official regulations.
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
