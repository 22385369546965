exports.CheckVisibility = (ref, offset) =>
{
    if (!offset || offset == null || offset == undefined)
    {
        offset = 0;
    }
        
    var docViewTop = window.scrollY;
    var docViewBottom = docViewTop + window.screen.height;
    
    var elemTop = ref.current.offsetTop;
    var elemBottom = elemTop + ref.current.clientHeight;
    
    return ((elemBottom + offset <= docViewBottom) && (elemTop >= docViewTop));
}

exports.ScrollToContent = (hash) =>
{
    if (hash) {
        const targetId = hash.substring(1)

        if(targetId)
        {
            //console.log(`targetId: ${targetId}`);
            var targetElement = document.getElementById(targetId)
            console.log(`targetId: ${targetId}`, targetElement);

            if (targetElement)
            {
                const yOffset = -75 
                var y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset
                window.scrollTo({ top: y, behavior: "smooth" })
            }
        }
    }
}

exports.getBlogLink = (path) =>
{
    let parts = path.split('/');
    if (parts.length === 3 && parts[2].length > 0)
    {
        var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;

        let link = parts[2].toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, "&#34;");

        console.log(`blog link: ${link}`);

        return link;
    }

    return false;
}

exports.isMobile = () =>
{
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return hasTouchScreen;
}
