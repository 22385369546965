import './style.scss';
import { useState } from 'react';

function ErrorList({
    errList,
    imgData
}) {
    function getErrors() {
        let errors = [];

        Object.keys(errList).forEach(function (key) {
            let error = errList[key];

            if (error.name) {
                let displayError = getError(key, error.name, error.fileName, error.wxh, error.src)
                errors.push(displayError);
            }
            else {
                errors.push(<div key={key}>{key}: {error}</div>);
            }
        })

        return errors;
    }

    function getError(timeStamp, name, fileName, wxh, src) {
        return (
            <div key={timeStamp}>
                <div>
                    {timeStamp}: <span>{name}</span>
                </div>
                <div className='errorList-errorDetails'>
                    {fileName || wxh ?
                        <div>
                            {fileName ?
                                <div>File name: {fileName}</div>
                                :
                                ''
                            }
                            {wxh ?
                                <div>Dimentions: {wxh}</div>
                                :
                                ''
                            }
                        </div>
                        :
                        ''
                    }
                    {imgData && imgData[timeStamp] ?
                        <div><img src={`data:image/jpg;base64,${imgData[timeStamp]}`} /></div>
                        :
                        ''
                    }
                </div>
            </div>
        )

    }

    return (
        <div className='errorList'>
            <div>
                Error details:
            </div>
            {getErrors()}
        </div>
    )
}

export default ErrorList;