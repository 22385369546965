import './style.scss';

function Doc({
    classNames,
    img,
    title,
    onClick
}) {
    return (
        <div className={`doc ${classNames}`} onClick={onClick}>
            <img src={img} className='doc-img' />
            <div className='doc-title'>
                {title}
            </div>
        </div>
    )
}

export default Doc;