import './style.scss';
import {
    logo
} from '../../../global/config'
import logoImg from '../../../assets/homepage/logo.svg';
import logo1Img from '../../../assets/homepage/logo.svg';

function Logo({
    type,
    onClick
}) {
    return (
        <div className='logo' onClick={() => onClick()}>
            <img src={type === "white" ? logo1Img : logoImg} alt='quickpassportphotos.com'/>
            <div className={`logo-text ${type}`}>
                {logo}
            </div>
        </div>
    )
}

export default Logo;