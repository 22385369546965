export const SET_DOC_TYPE = 'SET_DOC_TYPE';

export const setDocType = (value) => (dispatch) => (
    //console.log(`SET_DOC_TYPE ${e.target.value}`)
    dispatch({
        type: SET_DOC_TYPE,
        payload: {
            value: value
        }
    })
)