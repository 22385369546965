import { useEffect } from "react"
import MainContainer from '../../components/Basic/MainContainer';
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import "./style.scss"

function Privacy({}) {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <MainContainer>
      {/* <Header /> */}
      <div className="privacy">
        <div className="privacy-title">Privacy Policy</div>
        <div className="privacy-group-title">1. Introduction</div>
        <div className="privacy-text">
          Welcome to QuickPassportPhotos.com. Your privacy is important to us.
          This Privacy Policy outlines the types of information we may collect,
          how we use and protect that information, and the choices you can make
          about how we handle your data. By using our website and/or services,
          you are accepting the practices described in this Privacy Policy.
        </div>
        <div className="privacy-group-title">2. Information We Collect</div>
        <div className="privacy-group">
          <div className="privacy-group-text">
            Given the nature of our service, the primary piece of personal data
            we collect is passport photos uploaded by users. However, we do not
            retain or store these photos once they are downloaded by the user.
          </div>
        </div>
        <div className="privacy-group-title">
          3. How We Use Your Information
        </div>
        <div className="privacy-text">
          We use the information we collect solely for the purpose of providing
          our service. Specifically, we process passport photos to ensure they
          meet the required standards and guidelines.
        </div>
        <div className="privacy-group-title">4. Data Retention</div>
        <div className="privacy-text">
          We do not store or retain your passport photos or any other personal
          data internally once they are downloaded. The photos are temporarily
          processed to ensure they fit the required specifications and are made
          available for download. Once downloaded, the photos are permanently
          deleted from our servers.
        </div>
        <div className="privacy-group-title">5. Refunds</div>
        <div className="privacy-text">
          Refunds for services rendered are available for up to 30 days from the
          date of transaction. If you are not satisfied with our service, please
          contact our customer support for assistance.
        </div>
        <div className="privacy-group-title">6. Acceptance of this Policy</div>
        <div className="privacy-text">
          By using our services, uploading your photos, and continuing forward,
          you acknowledge that you have read, understood, and accepted the terms
          of this Privacy Policy.
        </div>
        <div className="privacy-group-title">7. Security</div>
        <div className="privacy-text">
          We employ industry-standard security measures to ensure the safety of
          your information. While we strive to protect your personal data, no
          security measures are 100% effective, and we cannot guarantee its
          complete security.
        </div>
        <div className="privacy-group-title">8. Changes to This Policy</div>
        <div className="privacy-text">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify users of any significant changes and the date
          these changes were made at the beginning of this policy.
        </div>
        <div className="privacy-group-title">9. Contact Us</div>
        <div className="privacy-text">
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact
          our customer support via the contact details provided on our website.
        </div>
      </div>

      {/* <Footer /> */}
    </MainContainer>
  )
}

export default Privacy
