import {
    SET_PRICES,
} from '../actions/priceAction';

const INITIAL_STATE = {
    prices: {
        digital: 0,
        printed: 0,
        additional: 0
    }

};

export default function step(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PRICES:
            return {
                ...state,
                prices: action.payload.value
            }
        default:
            return state;
    }
}