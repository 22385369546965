import React, { useEffect } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ref, child, get, set, query, orderByChild, limitToLast, onValue } from "firebase/database";
import { database } from './firebase';
import Dashboard from './pages/dashboard';
import PopularDocuments from './pages/popularDocuments';
import PopularDoc from './pages/popularDoc';
import Price from './pages/price';
import Step from './pages/step';
import Edit from './pages/edit';
import Monitor from './pages/monitor';
import Order from './pages/order';
import Payment from './pages/payment';
import OrderSuccess from './pages/orderSuccess';
import ReferencePage from './pages/referencePage';
import BlogPage from './pages/blogPage';
import ABlog from './pages/aBlog';
import Cookies from './pages/cookies';
import Privacy from './pages/privacy';
import DetailedErrors from './pages/DetailedErrors';
import Instructions from './pages/instructions';
import { DbHelper } from './global/dbHelper';
import { getBlogsRequest, getBlogsSuccess, updateError, updateSingleBlog } from './redux/actions/blogsAction';
import { setPrices } from './redux/actions/priceAction';
import { getBlogLink } from './global/Utils.js';
import { 
  firebase_functions_url
 } from './global/config';

function Routing() {
  const dispatch = useDispatch();
  // const { blogs } = useSelector((state) => state.blogs)

  const createBlog = () => {
    // const dbRefWrite = ref(database, 'blogs/first');
    // set(dbRefWrite, {date: "12-15-2023", title: "Test title 1", preview: "Short description to show smaller version of the article"});
    // const dbRefWrite2 = ref(database, 'blogs/second');
    // set(dbRefWrite2, {date: "12-16-2023", title: "Test title 2", preview: "Short description to show smaller version of the article"});
    // const dbRefWrite3 = ref(database, 'blogs/third');
    // set(dbRefWrite3, {date: "12-16-2023", title: "Test title 2", preview: "Short description to show smaller version of the article"});
    // const dbRefWrite4 = ref(database, 'blogs/fourth');
    // set(dbRefWrite4, {date: "12-16-2023", title: "Test title 2", preview: "Short description to show smaller version of the article"});
    const dbRefWrite5 = ref(database, 'blogs/fifth');
    set(dbRefWrite5, {date: "12-19-2023", title: "Test title 5", preview: "Short description to show smaller version of the article"});
  }

  function updatePrices(prices)
  {
    //console.log("Prices ", prices); 
    dispatch(setPrices(prices));
  }

  function updateBlogError()
  {
    console.log("updateBlogError "); 
    dispatch(updateError(true));
    //update error status
  }

  function updateOneBlog(record)
  {
    console.log("updateSingleBlog ", record);
    dispatch(updateSingleBlog(record));
  }

  function updateBlogTop(records)
  {
      console.log("updateBlogTop ", records);

      dispatch(getBlogsSuccess(records));
      //setBlogRecords(items);
  }

  useEffect(() => {
    //createBlog();
    //fetchBlogs();
    DbHelper.fetchPrices(updatePrices);
    
    let path = window.location.pathname.toLowerCase();

    if (path === "/blog" || path === "/blog/")
    {
        //console.log("window location ", path); 
        DbHelper.fetchBlogsTop(updateBlogTop, updateBlogError);
    }

    if (path.startsWith("/blog/"))
    {
      let blogLink = getBlogLink(path);
      //console.log(`routing bloglink: ${blogLink}`); 

      if (blogLink) 
      {
        DbHelper.fetchBlogByName(blogLink, updateOneBlog, updateBlogTop, updateBlogError);
      }
    }
  })

  return (
    <Routes>
      <Route path='/home' element={<Dashboard />} />
      <Route path='/test' element={<Dashboard />} />
      <Route path='/greencard' element={<Dashboard />} />
      <Route path='/edit' element={<Edit />} />
      <Route path='/monitor' element={<Monitor />} />
      <Route path='/step' element={<Step />} />
      <Route path='/popdocs' element={<PopularDocuments />} />
      <Route path='/popdoc' element={<PopularDoc />} />
      <Route path='/price' element={<Price />} />
      <Route path='/order' element={<Order />} />
      <Route path='/payment' element={<Payment isPay={false} />} />
      <Route path='/payment-pay' element={<Payment isPay={true} />} />
      <Route path='/order-success' element={<OrderSuccess />} />
      <Route path='/reference' element={<ReferencePage />} />
      <Route path='/blog' element={<BlogPage />} />
      <Route path='/blog/*' element={<ABlog />} />
      <Route path='/cookies' element={<Cookies />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/error' element={<DetailedErrors />} />
      <Route path='/instructions' element={<Instructions />} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
}

export default Routing;
