import './style.scss';
import ErrorList from '../ErrorList';
import { useState } from 'react';
import { DbHelper } from '../../../global/dbHelper';
import Spinner from '../Spinner';
import axios from 'axios';
import { Buffer } from 'buffer';
import {
    firebase_functions_url,
    front_video_opened
} from '../../../global/config.js';

function Session({
    session,
    errList
}) {
    const [isShowingMoreInfo, setShowingMoreInfo] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [initialData, setInitialData] = useState('');
    const [finalData, setFinalData] = useState('');
    const [printedErrors, setPrintedErrors] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isDataLoaded, setDataLoaded] = useState(false);
    //console.log("errorsList ", errList);

    function loadAndShowMoreInfo() {
        setSessionId(session.sessionId);

        //DbHelper.removeFromSesion(session.sessionId);
        //if (session.initialData)
        //check if there are pictures already, if not get them loaded
        if (!isShowingMoreInfo) {
            if (!isDataLoaded) {
                setLoading(true);
                DbHelper.fetchImgData(session.sessionId, updateImgData, updateImgDataError);
            }
            else {
                setShowingMoreInfo(true);
            }
        }
        else {
            setShowingMoreInfo(false);
        }



    }

    function updateImgData(imgData) {
        setDataLoaded(true);

        if (imgData) {
            if (imgData.initialData) {
                setInitialData(<img src={`data:image/jpg;base64,${imgData.initialData}`} />);
            }

            if (imgData.finalData) {
                setFinalData(<img src={`data:image/jpg;base64,${imgData.finalData.src}`} />);
            }

        }
        else {
            //copy data to imgData table
            let imgDataUpdate = { lastUpdated: session.lastUpdated };
            if (session.initialData) {
                imgDataUpdate.initialData = session.initialData
                DbHelper.removeFromSesion(`${session.sessionId}/initialData`);
            }

            if (session.finalData) {
                imgDataUpdate.finalData = { src: session.finalData }
                DbHelper.updateSesion(session.sessionId, { isValidated: true });
                DbHelper.removeFromSesion(`${session.sessionId}/finalData`);
            }

            if (session.errors) {

                Object.keys(session.errors).forEach(function (key) {
                    let error = session.errors[key];
                    if (error.src) {
                        imgDataUpdate[key] = error.src;
                        DbHelper.removeFromSesion(`${session.sessionId}/errors/${key}/src`);
                    }
                })
            }

            console.log("imgDataUpdate ", imgDataUpdate);
            DbHelper.updateImgData(session.sessionId, imgDataUpdate);
        }

        if (session.errors) {
            setPrintedErrors(<ErrorList errList={session.errors} imgData={imgData} />);
        }

        setLoading(false);
        setShowingMoreInfo(true);

        //console.log("updateImgData ", imgData);

        //downloadPrint(imgData);
    }

    function updateImgDataError(error) {
        setLoading(false);
        console.log("updateImgDataError ", error);
    }

    function downloadPrint(imgData) {
        const formData = new FormData();
        formData.append('sessionId', session.sessionId);
        formData.append('imgWidth', imgData.finalData.width);
        formData.append('imgHeight', imgData.finalData.height);
        //formData.append('width', size_pixel[countries_size[country]].width);
        //formData.append('height', size_pixel[countries_size[country]].height);
        formData.append('file', imgData.finalData.src);

        axios.post(`${firebase_functions_url}/resize`, formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer',
        })
            .then(res => {
                console.log("downloadPrint ", res);
                let base64Image = Buffer.from(res.data).toString('base64');
                const photo = `data:image/jpeg;base64,${base64Image}`
                const a = document.createElement('a');
                a.href = photo;
                a.download = "print 4x6 at cvs or walgreens.jpeg";
                //for (let i = 0; i < quantity; i++) {
                a.click();
                //}
            });

    }

    function checkIfThereisErrorOfName(errName) {
        if (session.errors) {
            let noMatch = true;

            Object.keys(session.errors).forEach(function (key) {
                let error = session.errors[key];
                //console.log("error ", error);


                if (error.name) {
                    //console.log("found keys ", error.name + ' ' + errName);
                    if (error.name === errName) {
                        //console.log("match!! ", error);
                        noMatch = false;
                        return false;
                    }
                }
                else if (errName === error) {
                    noMatch = false;
                    return false;
                }

            })

            if (!noMatch) {
                return noMatch;
            }
        }
        //console.log("no match!!! ", errName);
        return true;
    }

    function wasVideoOpened() {
        //console.log("wasVideoOpened ", session.events && Object.values(session.events).includes(front_video_opened) );
        return session && session.events && Object.values(session.events).includes(front_video_opened)
        //return
    }

    return (
        <div className='session'>
            <div className='session-collapsed'>
                <div className='session-collapsed-icons'>
                    <div className='session-collapsed-icons-paid'>
                        {(session.isOkToDelete || session.isPaid) ? '$' : ''}
                    </div>
                    <div className='session-collapsed-icons-video'>
                        {wasVideoOpened() ? '🎥' : ''}
                    </div>
                </div>
                <div className={`session-collapsed-timestamp ${(session.finalData || session.isValidated) ? "passedChecks" : ""}`} onClick={() => { loadAndShowMoreInfo() }}>
                    {
                        session.lastUpdated.substring(0, 4)
                        + '-'
                        + session.lastUpdated.substring(4, 6)
                        + '-'
                        + session.lastUpdated.substring(6, 8)
                        + ' '
                        + session.lastUpdated.substring(8, 10)
                        + ':'
                        + session.lastUpdated.substring(10, 12)
                        + ':'
                        + session.lastUpdated.substring(12, 14)
                    }
                </div>
                <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[0]) && "notThisError"}`} >
                    <div className='session-collapsed-error-tooltip'>
                        {errList[0]}
                    </div>
                </div>
                <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[1]) && "notThisError"}`} >
                    <div className='session-collapsed-error-tooltip'>
                        {errList[1]}
                    </div>
                </div>
                <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[2]) && "notThisError"}`} >
                    <div className='session-collapsed-error-tooltip'>
                        {errList[2]}
                    </div>
                </div>
                <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[3]) && "notThisError"}`} >
                    <div className='session-collapsed-error-tooltip'>
                        {errList[3]}
                    </div>
                </div>
                <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[4]) && "notThisError"}`} >
                    <div className='session-collapsed-error-tooltip'>
                        {errList[4]}
                    </div>
                </div>
                <div className={`session-collapsed-error ${checkIfThereisErrorOfName(errList[5]) && "notThisError"}`} >
                    <div className='session-collapsed-error-tooltip'>
                        {errList[5]}
                    </div>
                </div>
                {isLoading ?
                    <Spinner smallSize="true" />
                    :
                    ""
                }
            </div>
            {isShowingMoreInfo ?
                <div className='session-bottomInfo' >
                    <div className='session-bottomInfo-left'>
                        <div>
                            sessionId: {sessionId}
                        </div>
                        {session.parentSessionId ?
                            <div>
                                parentId: {session.parentSessionId}
                            </div>
                            :
                            ''}
                        <div className='session-bottomInfo-left-errDetails'>
                            {printedErrors}
                        </div>
                    </div>
                    <div className='session-bottomInfo-right'>
                        <div>
                            {initialData}
                        </div>
                        <div onClick={() => { console.log(" finalData clicked"); }}>
                            {finalData}
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    )
}

export default Session;