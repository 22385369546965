import { useNavigate } from "react-router-dom"
import { useState, useEffect, useRef } from "react"
import axios from "axios"
import Logo from "../Basic/Logo"
import Input from "../Basic/Input"
import Button from "../../components/Basic/Button"
import MainDialog from "../../components/Basic/MainDialog"
import twitterImg from "../../assets/icons/twitter-white.svg"
import tiktok from "../../assets/icons/tiktok.svg"
import facebookImg from "../../assets/icons/facebook-white.svg"
import instagramImg from "../../assets/icons/instagram-white.svg"
import youtubeImg from "../../assets/icons/youtube-white.svg"
import linkImg from "../../assets/icons/link-white.svg"
import commentImg from "../../assets/icons/comment-white.svg"
import qrImg from "../../assets/homepage/qr.png"
import closeImg from "../../assets/icons/close.svg"
import { copyright, firebase_functions_url } from "../../global/config"
import "./style.scss"
import { ScrollToContent } from '../../global/Utils.js';

function Footer() {
  const navigate = useNavigate()
  const [route, setRoute] = useState("")
  const [isDialogShow, setDialogShow] = useState(false)
  const [email, setEmail] = useState("")
  const [feedback, setFeedback] = useState("")
  //  const dlgContentRef = useRef(null)
  const emailRef = useRef(null)
  const submitRef = useRef(null)
  const [isEmailOk, setEmailOk] = useState(false)
  const [isTextOk, setTextOk] = useState(false)
  const [inputType, setInputType] = useState()

  const sessionId = localStorage.getItem("sessionId")

  useEffect(() => {
    setRoute(window.location.pathname)
  })

  const handleDlg = () => {
    setDialogShow(!isDialogShow)
    setInputType("")
    setEmail("")
    setFeedback("")
    setTimeout(() => {
      emailRef.current && emailRef.current.focus()
      //dlgContentRef.current.style.display = "flex"
    }, 300)
  }

  const handleBlur = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setInputType("success")
    } else {
      setInputType("error")
    }

    console.log(`handleBlur :`, email)
  }

  const onHandleEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value) {
      setEmailOk(true)

      if (inputType == "error" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        setInputType("success")
      }

    } else {
      setEmailOk(false)
      setInputType("error")
    }
  }

  const onHandleFeedback = (e) => {
    setFeedback(e.target.value)
    if (e.target.value) setTextOk(true)
    else setTextOk(false)
  }

  const handleSubmit = () => {
    //send an email
    sendFeedback()
    setDialogShow(!isDialogShow)
  }

  const sendFeedback = async () => {
    console.log(`sending feedback :`, email)
    const formData = new FormData()
    formData.append("email", email)
    formData.append("text", feedback)
    formData.append("sessionId", sessionId)

    axios.post(`${firebase_functions_url}/sendFeedback`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "json",
    })
  }

  return (
    <div className="footer">
      <div className="footer-up">
        <div className="footer-up-logo">
          <Logo
            type="white"
            onClick={() => {
              navigate("/")
            }}
          />
        </div>
        <div className="footer-up-pages">
          <a
            className={`${route === "/" && "selected"}`}
            onClick={() => {
              navigate("/")
            }}
          >
            Home Page
          </a>
          <a
            onClick={() => {
              ScrollToContent("#howTo");
            }}
          >
            How to take good photos
          </a>
          <a
            onClick={() => {
              ScrollToContent("#howItWorks");
            }}
          >
            How it works
          </a>
          <a
            onClick={() => {
              ScrollToContent("#intro");
            }}
          >
            Introduction
          </a>
          {/* <a
            className={`${route === "/popdocs" && "selected"}`}
            onClick={() => {
              navigate("/popdocs")
            }}
          >
            Popular documents
          </a>
          <a
            className={`${route === "/reference" && "selected"}`}
            onClick={() => {
              navigate("/reference")
            }}
          >
            Passport for reference
          </a> */}
        </div>
        <div className="footer-up-contacts">
          <div className="footer-up-contacts-social">
            <div className="footer-up-contacts-social-title">Social</div>
            <div className="footer-up-contacts-social-icon-group">
              <a href="https://x.com/quickpassp57400?s=21">
                <img src={twitterImg} alt="X" loading="lazy" />
              </a>
              <a href="https://www.tiktok.com/@quickpassportphot?_t=8fsloKJB27P&_r=1">
                <img src={tiktok} alt="TikTok" loading="lazy" />
              </a>
              <img src={instagramImg} alt="Instagram" loading="lazy" />
              {/* <img src={facebookImg} /> */}
              {/* <img src={youtubeImg} /> */}
              {/* <img src={linkImg} /> */}
            </div>
          </div>

          <div className="footer-up-contacts-get-in-touch">
            <div className="footer-up-contacts-get-in-touch-title">
              Get in touch
            </div>
            <div className="footer-up-contacts-get-in-touch-contact-group">
              {/* <div className='footer-up-contacts-get-in-touch-contact-group-item'>
                                <img src={phoneCallImg} />
                                <div className='footer-up-contacts-get-in-touch-contact-group-item-text'>
                                    1800 183 424
                                </div>
                            </div> */}
              <div
                className="footer-up-contacts-get-in-touch-contact-group-item"
                onClick={handleDlg}
              >
                <img src={commentImg} alt='Send a message' loading="lazy" />
                <div className="footer-up-contacts-get-in-touch-contact-group-item-text">
                  Send a message
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-down">
        <div className="footer-down-policy-group">
          <a
            className={`${route === "/cookies" && "selected"}`}
            onClick={() => {
              navigate("/cookies")
            }}
          >
            Cookies Policy
          </a>
          <a
            className={`${route === "/privacy" && "selected"}`}
            onClick={() => {
              navigate("/privacy")
            }}
          >
            Privacy Policy
          </a>
        </div>
        <div className="footer-down-passport">{copyright}</div>
      </div>

      {isDialogShow &&
        <MainDialog closeDlg={handleDlg}>
          <div className="EmailContent">
            <div className="EmailContent-title">
              <div>Leave a message here</div>
            </div>

            <div className="EmailContent-content">
              <div className="EmailContent-content-item">
                <div className="EmailContent-content-item-label">
                  Email*
                </div>
                <Input
                  placeholder={"Your email"}
                  value={email}
                  ref={emailRef}
                  onChange={onHandleEmail}
                  onBlur={handleBlur}
                  background={"gray"}
                  type={inputType}
                />
              </div>
              <div className="EmailContent-content-item">
                <div className="EmailContent-content-item-label">
                  Feedback
                </div>
                <textarea
                  className="EmailContent-content-item-textarea"
                  placeholder="Your feedback"
                  value={feedback}
                  onChange={onHandleFeedback}
                />
              </div>
            </div>
            <div className="EmailContent-button">
              <Button
                title="Send"
                backgroundColor="brand"
                onClick={handleSubmit}
                isEnable={isEmailOk && isTextOk && inputType === "success"}
              />
            </div>
          </div>
        </MainDialog>
      }


    </div >
  )
}

export default Footer
