import { forwardRef } from 'react';
import './style.scss';

const Button = forwardRef(({
    icon,
    title = 'Button',
    classNames,
    backgroundColor = "default",
    isEnable = true,
    onClick,
    onError
}, ref) => {

    function clicked(e) {
        if (isEnable) {
            onClick(e);
        }
        else {
            if (onError) {
                onError(e);
            }
        }
    }
    return (
        <div className={`button ${classNames} ${isEnable ? backgroundColor : "disable"}`} onClick={(e) => clicked(e)} ref={ref}>
            {icon && <object type="image/svg+xml" data={icon} className='button-icon' />}
            <div className='button-title'>
                {title}
            </div>
        </div>
    )
})

export default Button;