import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import StepLine from '../../components/Basic/StepLine';
import StepLetter from '../../components/Basic/StepLetter';
import StepContent1 from '../../components/StepContent1';
import StepContent2 from '../../components/StepContent2';
import StepContent3 from '../../components/StepContent3';
import StepContent4 from '../../components/StepContent4';
import HowToMake from '../../components/HowToMake';

import './style.scss';

function Step() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const [stepNo, setStepNo] = useState(1);
    const handleNext = () => {
        setStepNo(stepNo + 1);
    }

    return (
        <>
            {/* <Header /> */}
            <div className='step'>
                <StepLine
                    size={stepNo}
                />
                <StepLetter />
                {stepNo == 1 &&
                    <StepContent1
                        goNext={handleNext}
                    />
                }
                {stepNo == 2 &&
                    <StepContent2
                        goNext={handleNext}
                    />
                }
                {stepNo == 3 &&
                    <StepContent3
                        goNext={handleNext}
                    />
                }
                {stepNo == 4 &&
                    <StepContent4
                    />
                }
                <HowToMake />
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Step;