import {
    GET_BLOGS_REQUEST,
    GET_BLOGS_SUCCESS,
    SET_CURRENT_BLOG,
    UPDATE_BLOGS,
    UPDATE_SINGLE_BLOG,
    UPDATE_ERROR
} from '../actions/blogsAction';

const INITIAL_STATE = {
    blogs: [],
    loading: true,
    singleLoading: true,
    singleBlog: {},
    current: 0,
    isError: false
};

export default function blogs(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BLOGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_BLOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                blogs: action.payload.blogs,
            }
        case UPDATE_BLOGS:
            return {
                ...state,
                loading: false,
                blogs: {
                    data: Object.assign(state.blogs.data, action.payload.blogs.data),
                    index: Object.assign(state.blogs.index, action.payload.blogs.index)
                }
            }
        case UPDATE_SINGLE_BLOG:
            return {
                ...state,
                singleLoading: false,
                singleBlog: action.payload.current,
            }
        case UPDATE_ERROR:
            return {
                ...state,
                isError: action.payload
            }
        case SET_CURRENT_BLOG:
            return {
                ...state,
                current: action.payload.current
            }
        default:
            return state;
    }
}