import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as faceapi from "face-api.js";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
import { ref as dbRef, child, set, get } from "firebase/database";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { database, storage } from '../../firebase';
import Resizer from 'react-image-file-resizer';
import Button from '../Basic/Button';
import takephotoImg from '../../assets/homepage/step/takephoto.jpg';
import analyzephotoImg from '../../assets/homepage/howitworks-3.jpg';
import placeholderImg from '../../assets/homepage/step/placeholder.png';
import deleteImg from '../../assets/homepage/step/delete.svg';
import checkImg from '../../assets/homepage/step/check.svg';
import xImg from '../../assets/homepage/step/x.png';
import {
    setStepProcessedPhoto,
    setStepProcessedData
} from '../../redux/actions/stepAction';
import {
    firebase_functions_url_test,
    firebase_functions_url,
    countries_size,
    size_pixel,
    watermark_text
} from '../../global/config.js'
import './style.scss';

function StepContent2({
    goNext
}) {
    const { country } = useSelector((state) => state.step)

    const [isCheck, setIsCheck] = useState(false);
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [img, changeImg] = useState(takephotoImg);
    const [adjsutImg, changeAdjustImg] = useState(checkImg);
    const [recogImg, changeRecogImg] = useState(checkImg);
    const [isAlertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [imgNaturalWidth, setImgNaturalWidth] = useState(null);
    const [imgNaturalHeight, setImgNaturalHeight] = useState(null);

    const fileUploadRef = useRef(null);
    const placeholderRef = useRef(null);
    const checkRef = useRef(null);
    const analyzeResultRef = useRef(null);
    const checkButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const againButtonRef = useRef(null);
    const imageRef = useRef(null);
    const imageBorderRef = useRef(null);
    const deleteImgRef = useRef(null);
    const canvasRef = useRef(null);

    const dispatch = useDispatch();

    const handleFileUpload = (e) => {
        fileUploadRef.current.click()
    }

    const loadModels = async (log1, log2, uri) => {
        Promise.all([
            // models getting from public/model directory
            faceapi.nets.tinyFaceDetector.load("/models"),
            faceapi.nets.ssdMobilenetv1.load('/models'),
            faceapi.nets.faceLandmark68Net.load("/models"),
            faceapi.nets.faceRecognitionNet.load("/models"),
            faceapi.nets.faceExpressionNet.load("/models")
        ])
            .then(() => handleImageClick(log1, log2, uri))
            .catch((e) => console.error(e));
    };

    const handleImageClick = async (log1, log2, uri) => {
        let img = new Image();
        img.src = uri;
        const detections = await faceapi.detectSingleFace(
            img,
            new faceapi.SsdMobilenetv1Options()
        );

        if (!detections) {
            // handleDeleteFile();
            changeAdjustImg(xImg);
            changeRecogImg(xImg);
            deleteImgRef.current.style.display = "block";
            checkRef.current.style.display = "none";
            analyzeResultRef.current.style.display = "flex";
            againButtonRef.current.classList.remove('hide');
            handleAlert("Please choose another photo. Too difficult to detect your face from your photo.")

        } else {
            const date3 = new Date();
            const date2 = new Date(log2.time);
            const log3 = {
                time: new Intl.DateTimeFormat("en-US", {
                    timeZone: "America/New_York",
                    dateStyle: 'short',
                    timeStyle: 'long',
                }).format(date3).toLocaleString(),
                session_id: log2.session_id,
                file_name: log2.file_name,
                duration: `${date3.getTime() - date2.getTime()} ms`,
                status: "Face detection successed",
            }

            const box = {
                top: detections.box.top,
                left: detections.box.left,
                bottom: detections.box.bottom,
                right: detections.box.right,
                width: imgNaturalWidth,
                height: imgNaturalHeight,
            };

            const formData = new FormData();
            formData.append('top', box.top);
            formData.append('left', box.left);
            formData.append('bottom', box.bottom);
            formData.append('right', box.right);
            formData.append('width', box.width);
            formData.append('height', box.height);
            formData.append('country_size_width', size_pixel[countries_size[country]].width);
            formData.append('country_size_height', size_pixel[countries_size[country]].height);
            formData.append('file', uri);
            formData.append('log1', JSON.stringify(log1));
            formData.append('log2', JSON.stringify(log2));
            formData.append('log3', JSON.stringify(log3));

            axios.post(`${firebase_functions_url_test}/process`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                // responseType: 'arraybuffer',
            })
                // .then(res => res.json())
                .then(res => {
                    changeImg(analyzephotoImg);
                    let base64Image = Buffer.from(res.data[0]).toString('base64');
                    const largePhoto = `data:image/png;base64,${base64Image}`
                    base64Image = Buffer.from(res.data[1]).toString('base64');
                    const smallPhoto = `data:image/png;base64,${base64Image}`
                    setFile(largePhoto)
                    const ctx = canvasRef.current.getContext('2d');
                    img = new Image();
                    img.src = largePhoto;
                    img.onload = () => {
                        const aspectRatio = img.width / img.height;
                        const newWidth = canvasRef.current.width;
                        const newHeight = canvasRef.current.width / aspectRatio;
                        canvasRef.current.height = newHeight;
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);
                        ctx.font = '30px Arial';
                        ctx.fillStyle = 'white';
                        ctx.globalAlpha = 0.5;
                        ctx.rotate(Math.PI / 10);
                        ctx.fillText(watermark_text, parseInt(newWidth / 2) - 100, parseInt(newHeight / 4));
                        ctx.fillText(watermark_text, parseInt(newWidth / 2) - 100, parseInt(newHeight / 4) * 2);
                        ctx.fillText(watermark_text, parseInt(newWidth / 2) - 100, parseInt(newHeight / 4) * 3);
                    };
                    dispatch(setStepProcessedPhoto(largePhoto));
                    dispatch(setStepProcessedData(smallPhoto));
                    deleteImgRef.current.style.display = "block";
                    checkRef.current.style.display = "none";
                    analyzeResultRef.current.style.display = "flex";
                    nextButtonRef.current.classList.remove('hide');
                })


            // const dimension = {
            //     width: imageRef.current.naturalWidth,
            //     height: imageRef.current.naturalHeight,
            // };
            // const sessionId = uuidv4();            
            // let length = 0;
            // const dbref = dbRef(database);
            // await get(child(dbref, `logs`))
            //     .then((snapshot) => {
            //         if (snapshot.exists()) {
            //             length = snapshot.size;
            //             set(dbRef(database, `logs/${length}`), {
            //                 time: startDate.toLocaleString(),
            //                 session_id: sessionId,
            //                 file_name: file1.name,
            //                 dimension: `width: ${dimension.width}, height: ${dimension.height}`,
            //                 size: `${parseInt(file1.size / 1024 / 1024 * 100) / 100} MB`,
            //             });
            //         }
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //     });

            // const storageref = storageRef(storage, `/uploads/temp`);
            // const uploadTask = uploadBytesResumable(storageref, file1);

            // uploadTask.on(
            //     "state_changed",
            //     (snapshot) => {
            //         const percent = Math.round(
            //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            //         );
            //     },
            //     (err) => console.log(err),
            //     () => {
            //         getDownloadURL(uploadTask.snapshot.ref).then((url) => {

            // axios.post(`${firebase_functions_url_test}/process`, formData, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     responseType: 'arraybuffer',
            // })
            //     .then(buffer => {
            //         // const endDate = new Date();
            //         // set(dbRef(database, `logs/${length + 1}`), {
            //         //     time: endDate.toLocaleString(),
            //         //     session_id: sessionId,
            //         //     file_name: file1.name,
            //         //     duration: `${endDate.getTime() - startDate.getTime()} ms`,
            //         // });

            //         changeImg(analyzephotoImg);
            //         const blob = new Blob([buffer.data], { type: 'image/png' });
            //         const imageUrl = URL.createObjectURL(blob);
            //         setFile(imageUrl)
            //         dispatch(setStepProcessedPhoto(imageUrl));
            //         deleteImgRef.current.style.display = "block";
            //         checkRef.current.style.display = "none";
            //         analyzeResultRef.current.style.display = "flex";
            //         nextButtonRef.current.classList.remove('hide');
            //     })
            //         });
            //     }
            // );
        }
    };

    const handleChange = (e) => {
        const f = e.target.files[0];
        if (f.type.split("/")[0] === "image"
            && (f.type.split("/")[1] === "jpeg"
                || f.type.split("/")[1] === "png")) {
            if (f.size < 10485760) {
                setFile(URL.createObjectURL(f));
                const ctx = canvasRef.current.getContext('2d');
                const img = new Image();
                img.src = URL.createObjectURL(f);
                img.onload = () => {
                    setImgNaturalWidth(img.width);
                    setImgNaturalHeight(img.height);
                    const aspectRatio = img.width / img.height;
                    const newWidth = canvasRef.current.width;
                    const newHeight = canvasRef.current.width / aspectRatio;
                    canvasRef.current.height = newHeight;
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                };
                setFile1(f);
                placeholderRef.current.style.display = "none";
                imageBorderRef.current.style.display = "block";
                setIsCheck(true);
            }
            else {
                handleAlert("Please choose photo less than 10MB")
                fileUploadRef.current.value = null;
            }
        }
        else {
            handleAlert("Please choose correct type photo file")
            fileUploadRef.current.value = null;
        }
    };

    const handleImageLoad = ({ target: img }) => {
        const realWidth = parseInt(document.documentElement.clientWidth * 0.3984);

        const w = img.offsetWidth;
        const h = img.offsetHeight;
        if (w > h) {
            imageBorderRef.current.style.borderTopWidth = `${parseInt((realWidth - (realWidth / w * h)) / 2)}px`;
            imageBorderRef.current.style.borderBottomWidth = `${parseInt((realWidth - (realWidth / w * h)) / 2)}px`;
        }
        if (w < h) {
            imageBorderRef.current.style.borderLeftWidth = `${parseInt((realWidth - (realWidth / h * w)) / 2)}px`;
            imageBorderRef.current.style.borderRightWidth = `${parseInt((realWidth - (realWidth / h * w)) / 2)}px`;
        }
        imageRef.current.style.display = "block";

    }

    const handleAlert = (msg) => {
        setAlertMsg(msg);
        setAlertShow(true);
        setTimeout(() => {
            setAlertShow(false);
        }, 5000)
    }

    const handleDeleteFile = () => {
        imageBorderRef.current.style.display = "none";
        placeholderRef.current.style.display = "block";
        setIsCheck(false);
        setFile(null);
        fileUploadRef.current.value = null;
        analyzeResultRef.current.style.display = "none";
        nextButtonRef.current.classList.add('hide');
        againButtonRef.current.classList.add('hide');
        checkRef.current.style.display = "none";
        changeAdjustImg(checkImg);
        changeRecogImg(checkImg);
    }

    const handleCheck = () => {
        // checkRef.current.style.display = "flex";
        // checkButtonRef.current.classList.add('hide');
        // deleteImgRef.current.style.display = "none";

        const sessionId = uuidv4();
        const date1 = new Date();

        const log1 = {
            time: new Intl.DateTimeFormat("en-US", {
                timeZone: "America/New_York",
                dateStyle: 'short',
                timeStyle: 'long',
            }).format(date1).toLocaleString(),
            session_id: sessionId,
            file_name: file1.name,
            dimension: `width: ${imgNaturalWidth}, height: ${imgNaturalHeight}`,
            size: `${parseInt(file1.size / 1024 / 1024 * 100) / 100} MB`,
            status: "Analyzing started",
        };

        const box = {
            width: imgNaturalWidth,
            height: imgNaturalHeight,
        };

        if (box.width > 2000) {
            box.height = parseInt(1000 / box.width * box.height);
            box.width = 1000;
        }

        if (box.height > 2000) {
            box.width = parseInt(1000 / box.height * box.width);
            box.height = 1000;
        }

        const blob = new Blob([file1], { type: "image/png" });
        Resizer.imageFileResizer(
            blob,
            box.width,
            box.height,
            "PNG",
            100,
            0,
            (uri) => {
                const date2 = new Date();

                const log2 = {
                    time: new Intl.DateTimeFormat("en-US", {
                        timeZone: "America/New_York",
                        dateStyle: 'short',
                        timeStyle: 'long',
                    }).format(date2).toLocaleString(),
                    session_id: log1.session_id,
                    file_name: log1.file_name,
                    duration: `${date2.getTime() - date1.getTime()} ms`,
                    status: "File resizing successed",
                }

                setFile(uri);
                loadModels(log1, log2, uri);
            },
            "base64"
        );

    }

    return (
        <div className="step-content2">
            <div className='step-content2-img'>
                <img src={img} />
            </div>
            <div className={`step-content2-alert ${isAlertShow && 'active'}`}>
                {alertMsg}
            </div>
            <div className='step-content2-card'>
                {isCheck ? <img src={deleteImg} className='step-content2-card-delete' onClick={handleDeleteFile} ref={deleteImgRef} /> : null}
                <div className='step-content2-card-content'>
                    <div className='step-content2-card-content-disable-background' ref={checkRef}>
                        Analyzing photo...
                        <div className='step-content2-card-content-disable-background-line'>
                            <div className='step-content2-card-content-disable-background-line-1'></div>
                            <div className='step-content2-card-content-disable-background-line-2'></div>
                        </div>
                    </div>
                    {true && <div ref={imageBorderRef} className='step-content2-card-content-upload'><canvas ref={canvasRef} className='step-content2-card-content-upload-file' /></div>}
                    {/* <img src={file} className='step-content2-card-content-upload-file1' onLoad={handleImageLoad} ref={imageRef} /> */}
                </div>
                <div className='step-content2-card-placeholder' ref={placeholderRef}>
                    <div className='step-content2-card-placeholder-content'>
                        <img src={placeholderImg} className='step-content2-card-placeholder-content-img' />
                        <Button
                            title="Upload photo"
                            classNames='step-content2-card-placeholder-content-button'
                            backgroundColor="minor"
                            onClick={() => {
                                handleFileUpload()
                            }}
                        />
                    </div>
                </div>
                <div className='step-content2-card-analyze-result' ref={analyzeResultRef}>
                    <div className='step-content2-card-analyze-result-title'>
                        Compatibility test passed
                    </div>
                    <div className='step-content2-card-analyze-result-content'>
                        <div className='step-content2-card-analyze-result-content-item'>
                            <img src={checkImg} className='step-content2-card-analyze-result-content-item-img' />
                            <div className='step-content2-card-analyze-result-content-item-text'>
                                Corrected the aspect ratio
                            </div>
                        </div>
                        <div className='step-content2-card-analyze-result-content-item'>
                            <img src={adjsutImg} className='step-content2-card-analyze-result-content-item-img' />
                            <div className='step-content2-card-analyze-result-content-item-text'>
                                Adjusted size of the face in the photo
                            </div>
                        </div>
                        <div className='step-content2-card-analyze-result-content-item'>
                            <img src={checkImg} className='step-content2-card-analyze-result-content-item-img' />
                            <div className='step-content2-card-analyze-result-content-item-text'>
                                Detected correct photo resolution
                            </div>
                        </div>
                        <div className='step-content2-card-analyze-result-content-item'>
                            <img src={recogImg} className='step-content2-card-analyze-result-content-item-img' />
                            <div className='step-content2-card-analyze-result-content-item-text'>
                                Recognized a face
                            </div>
                        </div>
                        <div className='step-content2-card-analyze-result-content-item'>
                            <img src={checkImg} className='step-content2-card-analyze-result-content-item-img' />
                            <div className='step-content2-card-analyze-result-content-item-text'>
                                Corrected orientation
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    title="Check photo"
                    classNames='step-content2-card-button'
                    isEnable={isCheck ? true : false}
                    onClick={() => {
                        handleCheck()
                    }}
                    ref={checkButtonRef}
                />
                <Button
                    title="Next step"
                    classNames='step-content2-card-button hide'
                    onClick={() => {
                        goNext()
                    }}
                    ref={nextButtonRef}
                />
                <Button
                    title="Upload again"
                    classNames='step-content2-card-button hide'
                    onClick={handleDeleteFile}
                    ref={againButtonRef}
                />
            </div>
            <form className='step-content2-file-upload'>
                <input type="file" onChange={handleChange} ref={fileUploadRef} />
            </form>
        </div>
    )
}

export default StepContent2;