
import { useEffect } from "react"
import { ScrollToContent } from '../../global/Utils.js';
import MainContainer from '../../components/Basic/MainContainer';
import errorOne from "../../assets/homepage/errormedia/badLight.gif"
import errorTwo from "../../assets/homepage/errormedia/tooClose.gif"
import errorThree from "../../assets/homepage/errormedia/tooFar.gif"
import errorFour from "../../assets/homepage/errormedia/lowResolution.gif"
import "./style.scss"

import modelOneA from '../../assets/homepage/errormedia/errorModels/GirlOneA.jpg'
import modelOneB from '../../assets/homepage/errormedia/errorModels/GirlOneB.jpg'
import modelTwoA from '../../assets/homepage/errormedia/errorModels/GirlTwoA.jpg'
import modelTwoB from '../../assets/homepage/errormedia/errorModels/GirlTwoB.jpg'

function DetailedErrors({ }) {

  useEffect(() => {
    ScrollToContent(window.location.hash);

    const fragmentIdentifier = window.location.hash.substr(1);
    const errorTitle = document.getElementById(fragmentIdentifier);
    const errorHeader = document.getElementById(`title-${fragmentIdentifier}`);

    if (errorTitle !== null) {
      errorTitle.style.backgroundColor = 'lightgoldenrodyellow';
      errorHeader.style.backgroundColor = 'lightgoldenrodyellow';
      errorTitle.style.borderRadius = '5px';
      errorHeader.style.borderRadius = '5px';
      errorHeader.style.padding = '5px';
      errorTitle.style.padding = '5px';


    } else {
      console.log(`Element with ID '${fragmentIdentifier}' does not exist.`);
    }
  }, []);


  return (
    <MainContainer>
      <div className="dErrors">
        <div className="dErrors-title">Error Page</div>


        <div className="dErrors-group-title" id="tooFar" >
          Camera is too far from face
        </div>
        <div className="dErrors-text">
          <div className="image-with-text">
            <div className="text-content" id="title-tooFar">
              Step 1: Identify the Error
              <br></br>
              If you receive the "too far from face" error message, this
              indicates that your phone is positioned too far away from your
              face to capture an acceptable passport photo.<br></br>
              <br></br>
              Step 2: Reposition Your Phone
              <br></br>
              Hold your phone at arm's length and gradually bring it closer to
              your face. Stop when you are approximately 20 inches away from the
              camera — this is the ideal distance for passport photos.
              <br></br>
              <br></br>
              Step 3: Take the Photo
              <br></br>
              Once your phone is at the correct distance, align your face within
              the frame as guided by the on-screen template (if available). Hold
              steady, and then snap the photo. Make sure your face is clearly
              visible and in focus before submitting the picture.
              <br></br>
              <br></br>
            </div>
            <img src={errorThree} alt="errorOne" className="dErrors-img" />
          </div>
        </div>



        <div className="dErrors-group-title" id="tooClose" >
          Camera is too close to face
        </div>
        <div className="dErrors-text">
          <div className="image-with-text">
            <div className="text-content" id="title-tooClose">

              Step 1: Identify the Error
              <br></br>
              If you receive the "too close to face" error message, this
              indicates that your phone is positioned too close to your face to
              capture an acceptable passport photo.<br></br>
              <br></br>
              Step 2: Reposition Your Phone
              <br></br>
              Hold your phone at arm's length and gradually bring it closer to
              your face. Stop when you are approximately 20 inches away from the
              camera — this is the ideal distance for passport photos.<br></br>
              <br></br>
              Step 3: Take the Photo
              <br></br>
              Once your phone is at the correct distance, align your face within
              the frame as guided by the on-screen template (if available). Hold
              steady, and then snap the photo. Make sure your face is clearly
              visible and in focus before submitting the picture.
              <br></br>
              <br></br>
            </div>
            <img src={errorTwo} alt="errorOne" className="dErrors-img" />
          </div>
        </div>



        <div className="dErrors-group-title" id="lowRes">
          Low resolution
        </div>
        <div className="dErrors-text">
          <div className="image-with-text">
            <div className="text-content" id="title-lowRes">

              Step 1: Use an original photo
              <br></br>
              Select a photo directly from your camera roll that has not been
              uploaded or edited on social media.Social media platforms often
              compress photos, which can reduce their resolution<br></br>
              <br></br>
              Step 2: Adjust Camera Settings
              <br></br>
              Before taking a new photo, ensure your camera is set to capture
              images at a resolution of at least 2200x2200 pixels. This setting
              is usually found under the 'Camera Settings' or 'Image Quality' in
              your device settings.<br></br>
              <br></br>
              Step 3: Retake and Submit Your Photo
              <br></br>
              Find a well-lit area and retake your photo. Use a stable hand or a
              tripod to avoid blurriness, and ensure your entire face is visible
              and fits within the designated passport photo dimensions.
              <br></br>
              <br></br>
            </div>
            <img src={errorFour} alt="errorOne" className="dErrors-img" />
          </div>
        </div>



        <div className="dErrors-group-title" id="lightIssue">
          Let's add some light
        </div>
        <div className="dErrors-text">
          <div className="image-with-block">
            <div className="text-content" id="title-lightIssue">
              Your photo has not passed our quality check due to insufficient
              lighting. A well-lit photo is crucial to meet the standards for
              passport photos, as shadows or low light can obscure facial
              features. Please follow these steps to correct the issue:
              <br></br>
              <br></br>
              Step 1: Choose a Well-Lit Location
              <br></br>
              Move to an area with plenty of light. Indoors, rooms with multiple
              light sources, like bathrooms with bright lights or near a window
              where natural light is ample, are ideal.<br></br>
              <br></br>
              Step 2: Avoid Direct Overhead Lighting<br></br>Direct overhead
              lighting can create harsh shadows. Aim for diffused lighting that
              evenly illuminates your face. Standing facing a soft, bright light
              source can help achieve this.<br></br>
              <br></br>
              Step 3: Retake Your Photo<br></br>Once you're in a well-lit space
              without direct light causing deep shadows on your face, retake
              your photo. Ensure that the lighting is even and your facial
              features are clearly visible with minimal shadowing.<br></br>
              <br></br>
            </div>
            <div className="model-section">
              <img src={modelOneA} alt="errorOne" className="model-image" />
              <img src={modelOneB} alt="errorOne" className="model-image" />
              <img src={modelTwoA} alt="errorOne" className="model-image" />
              <img src={modelTwoB} alt="errorOne" className="model-image" />
            </div>
          </div>

        </div>

      </div>
    </MainContainer>

  )

}

export default DetailedErrors