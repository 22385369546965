import React, { useRef, useState, useEffect } from 'react';
import Input from '../../Basic/Input';
import vector1Img from '../../../assets/homepage/step/vector1.svg';
import vector2Img from '../../../assets/homepage/step/vector2.svg';
import searchIconImg from '../../../assets/icons/search-icon.svg';
import closeImg from '../../../assets/icons/close-icon.svg';
import './style.scss';

function Dropdown({
    content,
    subContent = [],
    value,
    onChange,
    classNames,
    smart = true,
    icons = []
}) {
    const [isDownVector, changeVector] = useState(true);
    const [selected, changeSelected] = useState(28);
    const [searchKey, setSearchKey] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [isMenu, openMenu] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);


    useEffect(() => {
        function handleClickOutside(event) {
            if (!dropdownRef.current.contains(event.target)) {
                if (dropdownMenuRef.current) {
                    openMenu(false);
                    changeVector(true);
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        let result = [];

        content.filter(item => {
            if (item.toLowerCase().includes(searchKey.toLowerCase()))
                result.push(item);
        });
        result.length
            ? setSearchResult(result)
            : setSearchResult(["No match"])

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, searchKey]);

    const handleBodyClick = (e) => {
        openMenu(!isMenu);
        changeVector(!isDownVector);
        setSearchKey("");
    };

    const handleItemClick = (item, i) => {
        openMenu(!isMenu)
        changeVector(!isDownVector)
        changeSelected(i);

        return onChange(item)
    }

    return (
        <div className={`dropdown ${classNames}`} ref={dropdownRef}>
            <div className='dropdown-body' onClick={handleBodyClick}>
                <div className='dropdown-body-text'>
                    <div className='dropdown-body-icon'>
                        <img src={selected !== null ? icons[selected] : null} />
                    </div>
                    {value}
                </div>
                <div className='dropdown-body-vector'>
                    <img src={isDownVector ? vector1Img : vector2Img} />
                </div>
            </div>
            {isMenu && <div className="dropdown-menu" ref={dropdownMenuRef}>
                {smart
                    ? <Input
                        placeholder={"Search for ..."}
                        onChange={(e) => {
                            setSearchKey(e.target.value);
                        }}
                        onClickClose={() => {
                            setSearchKey("");
                        }}
                        value={searchKey}
                        icon={
                            searchKey
                                ? closeImg
                                : searchIconImg
                        }
                        iconPos={"right"}
                        iconType={searchKey ? "close" : ""}
                        focus={true}
                    />
                    : null
                }
                {
                    (searchKey ? searchResult : content).map((item, i) => {
                        return <div className='dropdown-menu-item' onClick={() => handleItemClick(item, i)} key={i}>
                            <img src={icons[i]} />
                            <div className='dropdown-menu-item-text'>
                                {item}<br /><span>{subContent[i]}</span>
                            </div>
                        </div>
                    })
                }
            </div>}
        </div>
    )
}

export default Dropdown;