import { useNavigate } from 'react-router-dom';
import Doc from '../../components/Basic/Doc';
import Button from '../../components/Basic/Button';
import documentImg from '../../assets/homepage/document.jpg'
import searchImg from '../../assets/icons/search-icon.svg';
import arrowImg from '../../assets/icons/arrow.svg';
import arrowUpRightImg from '../../assets/icons/arrow-up-right.svg';
import './style.scss';

function PopularDocuments() {
    const navigate = useNavigate();

    return (
        <div className='popular-documents'>
            <div className='popular-documents-header'>
                <div className='popular-documents-header-title'>
                    Popular documents
                </div>
                <div className='popular-documents-header-control'>
                    <input type='text' placeholder='Search for a document' />
                    <img src={searchImg} />
                </div>
            </div>
            <div className='popular-documents-content'>
                <Doc
                    img={documentImg}
                    title={"Australian Passport"}
                    onClick={() => {
                        navigate('/popdoc')
                    }}
                />
                <Doc
                    img={documentImg}
                    title={"Australian Passport"}
                    onClick={() => {
                        navigate('/popdoc')
                    }}
                />
                <Doc
                    img={documentImg}
                    title={"Australian Passport"}
                    onClick={() => {
                        navigate('/popdoc')
                    }}
                />
                {/* <div className='popular-documents-content-more'
                    onClick={() => {
                        navigate('/popdocs')
                    }}
                >
                    <div className='popular-documents-content-more-img'>
                        <img src={arrowImg} className='popular-documents-content-more-img-arrow' />
                    </div>
                    <div className='popular-documents-content-more-title'>
                        All documents
                    </div>
                </div> */}
            </div>
            <div className='popular-documents-footer'>
                <Button
                    title='All documents'
                    icon={arrowUpRightImg}
                    classNames={'drag-button'}
                    backgroundColor={"secondary_color"}
                    onClick={() => {
                        navigate('/popdocs')
                    }}
                />
            </div>
        </div>
    )
}

export default PopularDocuments;