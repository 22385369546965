import './style.scss';
import ErrorList from '../ErrorList';
import { useState } from 'react';
import { DbHelper } from '../../../global/dbHelper';
import Spinner from '../Spinner';

function AdminDashboardRow({
    data,
    month,
    isHeader
}) {

    return (
        isHeader ?
        <div className='AdminDashboardRow'>
            <span className="AdminDashboardRow-month">Date</span>
            <span className="AdminDashboardRow-totalSessionCount">Total Sessions</span>
            <span className="AdminDashboardRow-successSessions">Success Sessions</span>
            <span className="AdminDashboardRow-errorCount">Total Errors</span>
            <span className="AdminDashboardRow-totalOrders">Created Orders</span>
            <span className="AdminDashboardRow-totalPaidOrders">Paid Orders</span>
            <span className="AdminDashboardRow-totalPaidOrders">$</span>
        </div>
        :
        <div className='AdminDashboardRow'>
            <span className="AdminDashboardRow-month">{month}</span>
            <span className="AdminDashboardRow-totalSessionCount">{data.sessions && data.sessions.totalSessionCount ? data.sessions.totalSessionCount : '-'}</span>
            <span className="AdminDashboardRow-successSessions">{data.sessions && data.sessions.totalSuccessSessionsCount? data.sessions.totalSuccessSessionsCount : '-'}</span>
            <span className="AdminDashboardRow-errorCount">{data.sessions && data.sessions.totalErrorCount? data.sessions.totalErrorCount : '-'}</span>
            <span className="AdminDashboardRow-totalOrders">{data.orders && data.orders.totalCount ? data.orders.totalCount : '-'}</span>
            <span className="AdminDashboardRow-totalPaidOrders">{data.ordersPaid && data.ordersPaid.totalCount ? data.ordersPaid.totalCount : '-'}</span>
            <span className="AdminDashboardRow-totalPaid">{data.ordersPaid && data.ordersPaid.totalPaid ? (data.ordersPaid.totalPaid / 100) : '-'}</span>
        </div>
    )
}

export default AdminDashboardRow;