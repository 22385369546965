import { DbHelper } from './dbHelper';
const historyLimit = 6;

export class AdminData {

    //mainData;

    constructor() {

    }

    getToday() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return yyyy + mm;
    }

    getDateMonthsFromNow(monthsToSubstract) {
        var today = new Date();
        var yyyy = today.getFullYear();

        let calcMonth = today.getMonth() - monthsToSubstract;
        if (calcMonth < 0) {
            calcMonth = 12 + calcMonth;
            yyyy--;
        }

        var mm = String(calcMonth + 1).padStart(2, '0'); //January is 0!

        return yyyy + mm;
    }

    getRemainingTokens = (updateTokens) => {
        DbHelper.fetchRemainingTokens(updateTokens);
    }

    sortSessions = (sessions, includeRawSessions = true) => {
        let sorted = [];

        let errorStats = {};
        let totalErrorCount = 0;
        let totalSuccessSessionsCount = 0

        // for (const [guid, session] of Object.entries(sessions)) {
        //     let timeStampId = Number(session.lastUpdated);
        //   sorted[timeStampId] = session;
        //   sorted[timeStampId].guid = guid;
        // }

        for (const [guid, session] of Object.entries(sessions)) {
            let timeStampId = Number(session.lastUpdated);
            session.sessionId = guid;

            if (includeRawSessions) {
                sorted.push(session);
            }

            if (session.isValidated) {
                totalSuccessSessionsCount++;
            }

            if (session.errors) {
                Object.keys(session.errors).forEach(function (key) {
                    totalErrorCount++;

                    //console.log(key, session.errors[key]);
                    let error = session.errors[key];
                    let errorName = error.name ? error.name : error;

                    if (errorStats[errorName]) {
                        errorStats[errorName]++;
                    }
                    else {
                        errorStats[errorName] = 1;
                    }

                });
            }
            //sorted[timeStampId] = session;
            //sorted[timeStampId].guid = guid;
        }

        let result = {
            errorStatsByType: errorStats,
            totalErrorCount: totalErrorCount,
            totalSuccessSessionsCount: totalSuccessSessionsCount,
            totalSessionCount: Object.keys(sessions).length
        }

        if (includeRawSessions) {
            sorted.sort((a, b) => a.lastUpdated && b.lastUpdated && a.lastUpdated.localeCompare(b.lastUpdated));
            result.data = sorted;
        }

        //console.log("result fetching sessions", result);

        return result;
    }

    async loadInitialData() {
        this.mainStatisticData = await this.checkAndUpdateSessionDataStatsMinusMonth();

        let today = this.getToday();

        this.currentMonthSessionData = await DbHelper.fetchAllSessionsAfterTheDate(today);
        //console.log("currentMonthSessionData ", currentMonthSessionData);

        let currentMonthPaidOrdersData = await DbHelper.fetchAllOrdersAfterTheDate(today, true);
        //console.log("currentMonthPaidOrdersData ", this.getPaidOrdersStats(currentMonthPaidOrdersData));

        let currentMonthOrdersData = await DbHelper.fetchAllOrdersAfterTheDate(today, false);
        //console.log("currentMonthOrdersData ", this.getOrdersStats(currentMonthOrdersData));

        this.mainStatisticData[today] = {};
        this.mainStatisticData[today].orders = this.getOrdersStats(currentMonthOrdersData);
        this.mainStatisticData[today].ordersPaid = this.getPaidOrdersStats(currentMonthPaidOrdersData);
        this.mainStatisticData[today].sessions = this.currentMonthSessionData ? this.currentMonthSessionData.stats : {} ;

        console.log("mainStatisticData ", this.mainStatisticData);
        // let test = await DbHelper.fetchAllSessionsInAPeriod("202402", "202403", false);
        // console.log("test ", test);

        return this.mainStatisticData;
    }

    getOrdersStatsCommon(rawOrders, isPaid) {
        let stats = {};

        if (rawOrders) {
            let deliveryTypeStats = {};
            let docTypeStats = {};
            let totalPaid = 0;

            let listOfOrderIds = Object.keys(rawOrders);
            stats.totalCount = listOfOrderIds.length;

            listOfOrderIds.forEach(function (orderId) {
                let order = rawOrders[orderId];

                if (!deliveryTypeStats[order.deliveryType]) {
                    deliveryTypeStats[order.deliveryType] = 1;
                }
                else {
                    deliveryTypeStats[order.deliveryType]++;
                }

                if (!docTypeStats[order.docType]) {
                    docTypeStats[order.docType] = 1;
                }
                else {
                    docTypeStats[order.docType]++;
                }

                if (isPaid) {
                    totalPaid += order.total;
                }
            });

            stats.deliveryTypeStats = deliveryTypeStats;
            stats.docTypeStats = docTypeStats;

            if (isPaid) {
                stats.totalPaid = totalPaid;
            }

        }

        return stats;
    }

    getOrdersStats(rawOrders) {
        return this.getOrdersStatsCommon(rawOrders, false);
    }

    getPaidOrdersStats(rawOrders) {
        return this.getOrdersStatsCommon(rawOrders, true);
    }

    async checkAndUpdateSessionDataStatsMinusMonth() {
        let performanceData = await DbHelper.fetchPerfStatsAfterTheDate(this.getDateMonthsFromNow(historyLimit));
        //console.log("fetchPerfStatsAfterTheDate ", performanceData);

        let newPerformanceData = {};
        let endOfSessions = false;
        let endOfOrders = false;
        let endOfOrdersPaid = false;

        for (let i = 1; i <= historyLimit; i++) {
            let prevMonth = this.getDateMonthsFromNow(i);
            let nextMonth = this.getDateMonthsFromNow(i - 1);
            //console.log(`prevMonth: ${prevMonth} nextMonth: ${nextMonth}`);

            if (!endOfSessions && (!performanceData || !performanceData[prevMonth] || !performanceData[prevMonth].sessions)) {
                let newSessionData = await DbHelper.fetchAllSessionsInAPeriod(prevMonth, nextMonth, false);

                if (!newSessionData) {
                    endOfSessions = true;
                }
                else {
                    if (!newPerformanceData[prevMonth]) {
                        newPerformanceData[prevMonth] = {}
                    }

                    newPerformanceData[prevMonth].sessions = newSessionData.stats;
                }
            }

            if (!endOfOrders && (!performanceData || !performanceData[prevMonth] || !performanceData[prevMonth].orders)) {
                let newOrderData = await DbHelper.fetchAllOrdersInAPeriod(prevMonth, nextMonth, false);

                if (!newOrderData) {
                    endOfOrders = true;
                }
                else {
                    if (!newPerformanceData[prevMonth]) {
                        newPerformanceData[prevMonth] = {}
                    }

                    newPerformanceData[prevMonth].orders = this.getOrdersStats(newOrderData);
                }
            }

            if (!endOfOrdersPaid && (!performanceData || !performanceData[prevMonth] || !performanceData[prevMonth].ordersPaid)) {
                let newOrderData = await DbHelper.fetchAllOrdersInAPeriod(prevMonth, nextMonth, true);

                if (!newOrderData) {
                    endOfOrdersPaid = true;
                }
                else {
                    if (!newPerformanceData[prevMonth]) {
                        newPerformanceData[prevMonth] = {}
                    }

                    newPerformanceData[prevMonth].ordersPaid = this.getPaidOrdersStats(newOrderData);
                }
            }

            // implement same mecanizm for sales data
            // paid orders count
            // totla orders count
            // separate numbers for digital vs printed
            // separate numbers for type (passport vs greencard)

            // console.log(`getDateMothsFromNow i: ${i}`);
        }

        console.log(`newPerformanceData: `, newPerformanceData);

        if (Object.keys(newPerformanceData).length > 0) {
            //update performance data
            await DbHelper.updatePerformanceData(newPerformanceData);

            performanceData = await DbHelper.fetchPerfStatsAfterTheDate(this.getDateMonthsFromNow(historyLimit));
        }

        //console.log("fetchPerfStatsAfterTheDate ", performanceData);

        return performanceData;
    }

}