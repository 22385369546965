import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { Buffer } from 'buffer';
import MainContainer from '../../components/Basic/MainContainer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Basic/Button';
import checkLImg from '../../assets/icons/check-l.svg';
import qrImg from '../../assets/homepage/qr.png';
import flowersImg from '../../assets/homepage/flowers.png';
import successImg from '../../assets/homepage/success.png';
import closeIcon from '../../assets/icons/close-icon.svg';
import tickIcon from '../../assets/icons/tick.svg';
import downloadIcon from '../../assets/icons/download.svg';
import {
    countries_size,
    size_pixel,
    firebase_functions_url_test,
    firebase_functions_url,
    doc_type
} from '../../global/config.js';
import './style.scss'

function OrderSuccess({

}) {
    const navigate = useNavigate()
    const [isReady, setReady] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isForPrinting, setForPrinting] = useState(false);

    const processedData = localStorage.getItem('data');
    const country = localStorage.getItem('country');
    const quantity = localStorage.getItem('quantity');
    //const email = localStorage.getItem('email');
    const sessionId = localStorage.getItem('sessionId');
    const imgWidth = localStorage.getItem('imgWidth');
    const imgHeight = localStorage.getItem('imgHeight');
    const docType = localStorage.getItem(doc_type);
    const orderId = localStorage.getItem('orderId');

    

    useEffect(() => {
        window.scrollTo(0, 0);
        //console.log(`use effect :`);
        updateAndGetStatus(5, 1);
    });

    //send email
    // sendEmail(mailOptions);
    function updateAndGetStatus(nAttempt, timeout)
    {
        if (!processedData || processedData.length <= 100
            || !orderId || orderId.length <= 6
            //|| !email || email.length <= 5
            || !sessionId || sessionId.length <= 5)
        {
            setFailed(true);
            return;
        }

        if (nAttempt > 0)
        {
            // console.log(`sending Email Order success :`, email);
            const formData = new FormData();
            // formData.append('email', email);
            formData.append('orderId', orderId);
            // formData.append('country', country);
            formData.append('sessionId', sessionId);
            // formData.append('imgWidth', imgWidth);
            // formData.append('imgHeight', imgHeight);
            // formData.append('quantity', quantity);
            //formData.append('width', size_pixel[countries_size[country]].width);
            //formData.append('height', size_pixel[countries_size[country]].height);
            // formData.append('file', processedData);        
            // formData.append(doc_type, docType);        

            axios.post(`${firebase_functions_url}/checkStatus`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: "json"
            })
            .then((res) => {
                //console.log(`get confirmation  `, res);
                let isReady = res.data.isReady;
                let isForPrinting = res.data.isPrinted;
                setReady(isReady);

                if (!isReady)
                {
                    setTimeout(function(){
                        updateAndGetStatus(nAttempt - 1, timeout * 2);
                    }, timeout * 5000);
                }
                else{
                    setForPrinting(isForPrinting);
                }
                //console.log(`get confirmation is ready `, res.data);
            })
            .catch((error) => {
                //console.error(`get confirmation Error `, error);
                setTimeout(function(){
                    updateAndGetStatus(nAttempt - 1, timeout * 2);
                }, timeout * 5000);
            });
        }
        else
        {
            // set text to "try to reload later"
            setFailed(true);
        }
    }

    const download = () => {
        //console.log(`downloading Order success :`, email);
        const formData = new FormData();
        formData.append('country', country);
        formData.append('sessionId', sessionId);
        formData.append('imgWidth', imgWidth);
        formData.append('imgHeight', imgHeight);
        //formData.append('width', size_pixel[countries_size[country]].width);
        //formData.append('height', size_pixel[countries_size[country]].height);
        formData.append('file', processedData);

        axios.post(`${firebase_functions_url}/resize`, formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer',
        })
            .then(res => {
                let base64Image = Buffer.from(res.data).toString('base64');
                const photo = `data:image/jpeg;base64,${base64Image}`
                const a = document.createElement('a');
                a.href = photo;
                a.download = "print 4x6 at cvs or walgreens.jpeg";
                //for (let i = 0; i < quantity; i++) {
                    a.click();
                //}
                const a2 = document.createElement('a');
                a2.href = processedData;
                a2.download = "passport photo.jpeg";
                //for (let i = 0; i < quantity; i++) {
                    a2.click();

            });
    }

    return (
        <MainContainer>
            {/* <Header /> */}
            <div className='order-success'>
            {isReady ?
                <div className='order-success-panel'>
                    <div className='order-success-panel-alert'>
                        <div>
                            <img src={tickIcon} /><span>Your photos have been sent! Please check your email.</span>
                        </div>
                        <img src={closeIcon} />
                    </div>
                    <div className='order-success-panel-body'>
                        <div className='order-success-panel-body-left'>
                            <div className='order-success-panel-body-left-up'>
                                <div className='order-success-panel-body-left-up-title'>
                                    Payment succesfully completed! 🎉
                                </div>
                                {isForPrinting ?
                                    <div className='order-success-panel-body-left-up-content'>
                                        We're in the process of getting your photo order ready. We're making sure everything is picture-perfect before it heads your way.
                                    </div>
                                    :
                                    <></>
                                }
                                <div className='order-success-panel-body-left-up-content'>
                                    Print Photos same day for as Cheap as 44 Cents at CVS or Walgreens!  
                                    <a
                                        onClick={() => {
                                        navigate("/instructions")
                                        }}
                                        target="_blank"
                                    >
                                        Instructions
                                    </a>
                                </div>
                                <Button
                                    icon={downloadIcon}
                                    title='Download photos'
                                    onClick={() => {
                                        download()
                                    }}
                                />
                            </div>
                            {/* <div className='order-success-panel-body-left-down'>
                                <img src={qrImg} />
                                <div>
                                    <b>Scan QR Code</b> to get the app.
                                </div>
                            </div> */}
                        </div>

                       {/*  <img src={successImg} className='order-success-panel-body-img' /> */}
                    </div>

                    <img src={flowersImg} className='order-success-panel-img' />
                </div>
                :
                <div className='order-success-loading'>
                    <div className='order-success-loading-container'>
                        <div className={`${!isFailed &&"order-success-loading-container-animation"}`}>
                        </div>
                        <div className='order-success-loading-container-text'>
                            {isFailed ? 'Try to reload later' : 'Finalizing the order'}
                        </div>
                    </div>
                    
                </div>
            }
            </div>
            {/* <Footer /> */}
        </MainContainer>
    )
}

export default OrderSuccess;