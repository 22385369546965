import './style.scss';

function Spinner({
    smallSize
}) {
    return (
        <div className={`${smallSize ? "spinnerSmall" : "spinner"}`}>
            <div className={`${smallSize ? "spinnerSmall-loading" : "spinner-loading"}`}>
            </div>
        </div>
    )
}

export default Spinner;