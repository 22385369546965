import { useState } from 'react';
import checkImg from '../../../assets/homepage/step/check.svg';
import './style.scss';

function Reference({
    classNames,
    title,
    content = [],
    img,
    type = "LTR"
}) {
    return (
        <div className={`reference ${classNames} ${type}`}>
            {type === "LTR"
                ? <div className='reference-show'>
                    <img src={img} className='reference-show-img' />
                </div>
                : ""}
            <div className='reference-body'>
                <img src={checkImg} className='reference-body-img' />
                <div className='reference-body-title'>{title}</div>
                {content.map(item => (
                    <div className='reference-body-item'>{item}</div>
                ))}
            </div>
            {type === "LTR"
                ? ""
                : <div className='reference-show'>
                    <img src={img} className='reference-show-img' />
                </div>}
        </div>
    )
}

export default Reference;