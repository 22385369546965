import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Spinner from '../../components/Basic/Spinner';
import Blog from '../../components/Basic/Blog';
import MainContainer from '../../components/Basic/MainContainer';
import arrowLeftImg from '../../assets/icons/arrow-left.svg';
import arrowRightImg from '../../assets/icons/arrow-right.svg';
import { DbHelper } from '../../global/dbHelper';
import Button from '../../components/Basic/Button';
import { getBlogsRequest, getBlogsSuccess, setCurrentBlog, updateBlogs } from '../../redux/actions/blogsAction';
import './style.scss';

function BlogPage() {
    const navigate = useNavigate();
    const [pages, setPages] = useState([
        1, 2, 3, 4
    ]);
    //const [blogRecords, setBlogRecords] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [isMoreVisible, setMoreVisible] = useState(true);
    const { blogs, loading, isError } = useSelector((state) => state.blogs)
    const dispatch = useDispatch();

    function renderBlogRecords() {
        let items = [];

        var keys = Object.keys(blogs.index);
        //console.log(`keys:`, keys);

        // reversing the list of blog records to display most recent first
        for (let i = keys.length - 1; i >= 0; i--) {
            //console.log(`keys desc: ${keys[i]}`);
            let blogNum = keys[i];
            let blogLink = blogs.index[blogNum];
            //console.log(`blogLink: ${blogLink}`);
            let blogRecord = blogs.data[blogLink];

            items.push(<Blog
                key={blogRecord.id}
                //flag={(i + 1 - 4) % 7 ? "1" : "2"}
                flag={"1"}
                img={`data:image/jpg;base64,${blogRecord.img}`}
                //                                date={item.date}
                date={new Date(blogRecord.date).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}
                title={blogRecord.title}
                content={blogRecord.preview}
                onClick={() => {
                    navigate('/blog/' + blogLink)
                    //dispatch(setCurrentBlog(i))
                }}
            />)

        }

        console.log(`items:`, items);
        return items;
    }

    function getMorePosts() {
        console.log(`last item id:`, Object.keys(blogs.index)[0]);
        let lastIdLoaded = Number(Object.keys(blogs.index)[0]);
        DbHelper.fetchBlogsStartingAt(lastIdLoaded, updateBlog);
    }

    function updateBlog(records) {
        console.log("updateBlog new records ", records);
        let lastIdLoaded = Number(Object.keys(records.index)[0]);

        if (lastIdLoaded <= 1) {
            setMoreVisible(false);
        }

        dispatch(updateBlogs(records));
    }

    //DbHelper.fetchBlogsTop(updateBlogTop);

    useEffect(() => {
        //window.scrollTo(0, 0);

        //dispatch(getBlogsRequest());
        //console.log("Blog fetching val");
        //DbHelper.fetchBlogsTop(updateBlogTop);
        // DbHelper.fetchBlogsStartingAt(3);
    });

    return (
        <>
            {/* <Header /> */}
            <MainContainer>
                <div className='blog-page'>
                    <div className='blog-page-header'>
                        <div className='blog-page-header-title'>
                            Blog
                        </div>
                    </div>
                    {isError ?
                        <div className='blog-page-error'>
                            Something went wrong, try again later
                        </div>
                        :
                        (loading ?
                            <Spinner />
                            :
                            <div className='blog-page-content'>
                                <div className='blog-page-content-blog-group'>
                                    {renderBlogRecords()}
                                </div>
                            </div>
                        )
                    }
                    <div className={`blog-page-footer ${(!isMoreVisible || isError || loading) && "dontShow"}`} >
                        <Button
                            title='Show more'
                            onClick={() => {
                                getMorePosts()
                            }}
                        />
                    </div>
                </div>
            </MainContainer>
            {/* <Footer /> */}
        </>
    )
}

export default BlogPage;